import React, { useEffect } from 'react'
import Product from './sub_comp/Product'
import { useSelector, useDispatch } from 'react-redux';
import { bestSeller } from '../../actions';
import NoProductImgDark from '../../media/empty-card-dark.png'
import NoProductImgLight from '../../media/empty-cart-light.png'

const PopularMenu = () => {
    const state = useSelector(state => state);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(bestSeller(state.currentBranch));
    }, [state.currentBranch, dispatch])

    return (
        <div className='px-4 mb-10'>
            <div className='md:container md:mx-auto'><div className='flex justify-between items-center mb-6 heading-one'>
                <h1 className='text-dark-color text-2xl sm:text-3xl font-bold font-montserrat capitalize'>Best Seller</h1>
            </div>
                {(state.bestSelling.length > 0) ?
                    <div className='grid lg:grid-cols-5 md:grid-cols-3 sm:grid-cols-2 xs:grid-cols-1 gap-6 px-4'>
                        {state.bestSelling.map((product, index) => <Product key={index} product={product} coupon={{ usedCoupon: false, coupon: null }} />)}
                    </div> :
                    <h1 className='text-primary-dark-color text-xl mbtb:text-2xl flex items-center flex-wrap space-x-1 space-y-2 justify-center mbtb:justify-start px-0 mbtb:px-2'>
                        <div className='sm:text-3xl sm:mr-[40px]'>No Products Found !</div>
                        <img src={state.theme === "dark" ? NoProductImgDark : NoProductImgLight} className="w-[125px] sm:w-[200px]" alt="" />
                    </h1>
                }

            </div>
        </div>
    )
}

export default PopularMenu