import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Mail, MapPin, Phone } from 'react-feather';
import axios from 'axios';
import { useAlert } from 'react-alert';
const { REACT_APP_API_ENDPOINT } = process.env;

const Content = () => {
    const alert = useAlert();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [address, setAddress] = useState("");
    const [position, setPosition] = useState("");
    const [addInfo, setAddInfo] = useState("");
    const [resume, setResume] = useState(null);

    const state = useSelector(state => state);
    const branch_id = state.currentBranch.branch_id || state.currentBranch.branch_id;
    const branch_name = state.currentBranch.branch_name || state.currentBranch.branch_name;

    const resumeUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            setResume(file);
        } else {
            setResume(null);
        }
        //console.log(resume)
    }

    const handleSubmit = (e) => {
        const formData = new FormData();
        formData.append("branch_id", branch_id);
        formData.append("branch_name", branch_name);
        formData.append("name", name);
        formData.append("email", email);
        formData.append("phone", phone);
        formData.append("address", address);
        formData.append("position", position);
        formData.append("addInfo", addInfo);
        formData.append("file", resume);

        if(branch_id != "" && branch_name != "" && name != "" && email != "" && phone != "" && address !="" && position != ""){
            e.preventDefault();
            career(formData);
            e.target.reset();
        }
        else{
            alert.error("Plese Fill All Details")
        }

    }


    const career = async (formData) => {

        axios.post(`${REACT_APP_API_ENDPOINT}/career`, formData).then(response => {
            if (response.data.status === 'Success') {
                setEmail("");
                setName("");
                setAddInfo("");
                setPhone("");
                setAddress("");
                setPosition("");
                setResume(null)
                alert.success("Thank You for this message. We will contact you soon.");
            }
            else alert.error(response.data.msg);
        }).catch(err => alert.error("Internal Error !"));
    }
    return (
        <div className='md:container md:mx-auto px-6'>
            <div className='grid md:grid-cols-2 grid-cols-1 gap-4 mb-4  bg-primary-light-color border-radius-10  md:p-8 p-4 '>
                <div className='flex justify-start p-2 w-full'>
                    <div className='flex flex-col justify-start items-start animate__animated animate__fadeInLeft'>
                        <h2 className='text-dark-color text-3xl font-bold font-montserrat mb-3'>Get In Touch</h2>
                        {/* <div className='flex justify-center items-center gap-2 mb-3'>
                            <div className='p-3 bg-primary-color text-primary-light-color border-radius-5'>
                                <Phone size={20} />
                            </div>
                            <a href={`tel:${state.currentBranch?.contact}`} className='text-md font-bold font-montserrat text-primary-dark-color'>{state.currentBranch?.contact}</a>
                        </div> */}
                        <div className='flex justify-center items-center gap-2 mb-3'>
                            <div className='p-3 bg-primary-color text-primary-light-color border-radius-5'>
                                <Mail size={20} />
                            </div>
                            <a href={`mailto:${state.currentBranch?.email}`} className='text-md font-bold font-montserrat text-primary-dark-color'>{state.currentBranch?.email}</a>
                        </div>
                        <div className='flex justify-center items-center gap-2'>
                            <div className='p-3 bg-primary-color  text-primary-light-color border-radius-5'>
                                <MapPin size={20} />
                            </div>
                            <p className='text-md font-bold font-montserrat text-primary-dark-color leading-tight'>{state.currentBranch?.address}</p>
                        </div>
                    </div>
                </div>
                <div className='bg-footer text-black animate__animated animate__fadeIn border-radius-5'>
                    <form onSubmit={handleSubmit}>
                        <div className='flex flex-col gap-2 w-full p-4'>
                            <input type="text" className='bg-footer text-primary-dark-color p-2 border-2 border-radius-5 border-primary-dark-color focus:outline-none placeholder-primary-input' placeholder='Enter Your Name' value={name} onChange={e => setName(e.target.value)} required />
                            <input type="email" className='bg-footer text-primary-dark-color p-2 border-2 border-radius-5 border-primary-dark-color focus:outline-none placeholder-primary-input' placeholder='Enter Your Email' value={email} onChange={e => setEmail(e.target.value)} required />
                            <input type="number" className='bg-footer text-primary-dark-color p-2 border-2 border-radius-5 border-primary-dark-color focus:outline-none placeholder-primary-input' placeholder='Enter Your Contact Number' value={phone} onChange={e => setPhone(e.target.value)} required />
                            <textarea className='bg-footer text-primary-dark-color p-2 border-2 border-radius-5 border-primary-dark-color focus:outline-none placeholder-primary-input' rows="2" placeholder='Enter  Your Address' value={address} onChange={e => setAddress(e.target.value)} required ></textarea>
                            <select className='bg-footer text-primary-dark-color p-2 border-2 border-radius-5 border-primary-dark-color focus:outline-none placeholder-primary-input' rows="2" placeholder='Enter  Your Address' value={position} onChange={e => setPosition(e.target.value)} required >
                                <option>Choose desired position</option>
                                <option value="Barista (coffee maker)">Barista (coffee maker)</option>
                                <option value="Manager">Manager</option>
                                <option value="Accounting">Accounting</option>
                                <option value="Housekeeping">Housekeeping</option>
                                <option value="Storekeeping">Storekeeping</option>
                                <option value="Security">Security</option>
                                <option value="Marketing">Marketing</option>
                                <option value="Social Media">Social Media</option>
                            </select>
                            <textarea className='bg-footer text-primary-dark-color p-2 border-2 border-radius-5 border-primary-dark-color focus:outline-none placeholder-primary-input' rows="4" placeholder='Additional Info' value={addInfo} onChange={e => setAddInfo(e.target.value)} ></textarea>
                            <div className='flex gap-2 justify-center items-center mb-3'>
                                <label className='text-primary-dark-color'>Upload Resume : </label>
                                <input type="file" className='bg-footer text-primary-dark-color p-2 border-2 border-radius-5 border-primary-dark-color focus:outline-none placeholder-primary-input' placeholder='Upload Resume' accept=".pdf,.doc,.docx" onChange={resumeUpload} required />
                            </div>
                            <button type="submit" className='bg-primary-dark-color border-radius-5 text-light-color uppercase font-bold p-2 focus:outline-none w-[100%]'>Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Content