import React from 'react'
import "../assets/css/loader.css"

const Loader = () => {
	return (
		<div className="overlay">
			<div className="overlayDoor"></div>
			<div className="overlayContent">
				<div className="loader">
					<div className="inner"></div>
				</div>
			</div>
		</div>
	)
}

export default Loader