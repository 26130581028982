import axios from 'axios';
import PromoCode from '../components/common/PromoCode';
import { SET_THEME, SET_LOGIN, SET_CUSTOMER_DETAILS, LOGOUT, GET_BRANCHES, CURRENT_BRANCH, GET_CATEGORIES, GET_PRODUCTS, SET_QUERY, UPDATE_PRODUCTS, BEST_SELLER, SET_CART_ITEMS, PICKUP_TIME, GET_COUPONS, FETCH_ALL_ORDERS, MOST_ORDER_ITEMS, UNCLAIM_GIFTCARD, ADD_NEW_GIFTCARD, CLAIMED_GIFTCARD, SENT_GIFTCARD, GET_PROMO_CODE, SET_PROMO_CODE, CUSTOMER_ROLES, CURRENT_COUPON } from '../constants';
const { REACT_APP_API_ENDPOINT } = process.env;
// console.log(REACT_APP_API_ENDPOINT);

// console.log(url);
// Theme
export const setTheme = (theme) => {
    return function (dispatch, getState) {
        dispatch({
            type: SET_THEME,
            payload: theme
        })
    }
}

// Login
export const setLogin = (token) => {
    return function (dispatch, getState) {
        dispatch({
            type: SET_LOGIN,
            payload: token
        })
    }
}

// Customer Details
export const setCustomerDetails = (token) => {
    return async function (dispatch, getState) {
        if (token === null) {
            dispatch({
                type: SET_CUSTOMER_DETAILS,
                payload: null
            })
        } else {
            axios.get(`${REACT_APP_API_ENDPOINT}/customer_info`, {
                headers: {
                    token: token
                }
            })
                .then(res => {
                    dispatch({
                        type: SET_CUSTOMER_DETAILS,
                        payload: res.data.data
                    })
                })
        }
    }
}

// Logout
export const logout = () => {
    return function (dispatch, getState) {
        window.localStorage.setItem("heebee-cafe-login-token", null);
        window.localStorage.setItem("heebee-cafe-customer-details", null);
        dispatch({
            type: LOGOUT,
            payload: null
        })
    }
}

// Branches
export const getBranches = () => {
    return async function (dispatch, getState) {
        axios.get(`${REACT_APP_API_ENDPOINT}/get_branches`)
            .then(res => {
                // console.log(res);
                // console.log(res.data.data)

                let b = [];
                if (res.data.status === "success") {
                    res.data.data.forEach(f => {
                        b.push(f.branches);
                    })
                }
                dispatch({
                    type: GET_BRANCHES,
                    payload: b
                });
            });
    }
}

// Change current Branch
export const currentBranch = (branch) => {
    return async function (dispatch, getState) {
        let data;
        if (branch === undefined) data = null;
        else data = branch;
        dispatch({
            type: CURRENT_BRANCH,
            payload: data
        });
    }
}

// Get Categories
export const getCategories = (branch) => {
    return async function (dispatch, getState) {
        axios.get(`${REACT_APP_API_ENDPOINT}/get_categories?branch=${branch.branch_id}`)
            .then(res => {
                dispatch({
                    type: GET_CATEGORIES,
                    payload: res.data.data
                });
            });
    }
}

// Get products
export const getProducts = (branch) => {
    return async function (dispatch, getState) {
        axios.get(`${REACT_APP_API_ENDPOINT}/get_products/1?branch=${branch.branch_id}`)
            .then(res => {
                dispatch({
                    type: GET_PRODUCTS,
                    payload: res.data.data
                });
            });
    }
}

// Best Seller Products
export const bestSeller = (branch) => {
    return async function (dispatch, getState) {
        if (branch !== null)
            axios.get(`${REACT_APP_API_ENDPOINT}/best_seller?branch_id=${branch.branch_id}`)
                .then(res => {
                    let data;
                    if (res.data.status === 'failure' || res.data === undefined) {
                        data = [];
                    } else {
                        data = res.data.product_info;
                    }
                    dispatch({
                        type: BEST_SELLER,
                        payload: data
                    })
                });
    }
}

// Search a Product
export const searchProduct = (data) => {
    let url;
    if (data.type === "category") {
        if (data.category === "") {
            return async function (dispatch, getState) {
                dispatch({
                    type: SET_QUERY,
                    payload: {}
                });
                axios.get(`${REACT_APP_API_ENDPOINT}/get_products/1?branch=${data.branch.branch_id}`)
                    .then(res => {
                        dispatch({
                            type: GET_PRODUCTS,
                            payload: res.data.data
                        });
                    });
            }
        } else {
            url = `${REACT_APP_API_ENDPOINT}/get_products/1?branch=${data.branch.branch_id}&category=${data.category.category_id}`;
            return async function (dispatch, getState) {
                dispatch({
                    type: SET_QUERY,
                    payload: data
                });
                axios.get(url)
                    .then(res => {
                        dispatch({
                            type: GET_PRODUCTS,
                            payload: res.data.data
                        });
                    });
            }
        }
    } else if (data.type === "food-type") {
        url = `${REACT_APP_API_ENDPOINT}/get_products/1?branch=${data.branch.branch_id}&food_type=${data.foodType}`;
        return async function (dispatch, getState) {
            dispatch({
                type: SET_QUERY,
                payload: data
            });
            axios.get(url)
                .then(res => {
                    dispatch({
                        type: GET_PRODUCTS,
                        payload: res.data.data
                    });
                });
        }
    } else if (data.type === "search") {
        if (data.search === '') {
            return async function (dispatch, getState) {
                dispatch({
                    type: SET_QUERY,
                    payload: {}
                });
                axios.get(`${REACT_APP_API_ENDPOINT}/get_products/1?branch=${data.branch.branch_id}`)
                    .then(res => {
                        dispatch({
                            type: GET_PRODUCTS,
                            payload: res.data.data
                        });
                    });
            }
        } else {
            url = `${REACT_APP_API_ENDPOINT}/get_products/1?branch=${data.branch.branch_id}&search=${data.search}`;
            return async function (dispatch, getState) {
                dispatch({
                    type: SET_QUERY,
                    payload: data
                });
                axios.get(url)
                    .then(res => {
                        if (res.data.status === 'failure') {
                            dispatch({
                                type: GET_PRODUCTS,
                                payload: []
                            });
                        } else {
                            dispatch({
                                type: GET_PRODUCTS,
                                payload: res.data.data
                            });
                        }
                    });
            }
        }
    }
}

// Pagination - Update Products
export const updateProducts = (products) => {
    return async function (dispatch, getState) {
        const newData = [...getState().products, ...products];
        dispatch({
            type: UPDATE_PRODUCTS,
            payload: newData
        });
    }
}

// Set Cart Items
export const setCartItems = (cartItems) => {
    return function (dispatch, getState) {
        dispatch({
            type: SET_CART_ITEMS,
            payload: [...cartItems]
        })
    }
}

// Update Cart Item
export const updateCartItems = (index, count) => {
    return function (dispatch, getState) {
        let cart = [...getState().cartItems];
        let item = cart[index];
        if (item !== undefined) {
            let bp = item.amount / item.quantity;
            item.quantity = count;
            item.amount = count * bp;
            cart[index] = item;
            dispatch({
                type: SET_CART_ITEMS,
                payload: [...cart]
            })
        }
    }
}

// Delete Cart Item
export const deleteCartItem = (index) => {
    return function (dispatch, getState) {
        let cart = [...getState().cartItems];
        cart.splice(index, 1);
        for (let i = index; i < cart.length; i++) {
            cart[i].index--;
        }
        dispatch({
            type: SET_CART_ITEMS,
            payload: [...cart]
        })
    }
}

// Clear Cart Item
export const clearCart = () => {
    return function (dispatch, getState) {
        dispatch({
            type: SET_CART_ITEMS,
            payload: []
        })
    }
}

// Set PickUp Time
export const setPickUpTime = (time) => {
    return function (dispatch, getState) {
        dispatch({
            type: PICKUP_TIME,
            payload: time
        })
    }
}

// // Get All Valid Coupons
// export const getCoupons = (data, token) => {
//     return async function (dispatch, getState) {
//         axios.post(`${REACT_APP_API_ENDPOINT}/valid_coupons`, data, {
//             headers: {
//                 token: token
//             }
//         })
//             .then(res => {
//                 dispatch({
//                     type: GET_COUPONS,
//                     payload: res.data.all_coupons
//                 })
//             })
//     }
// }

// Get all orders
export const fetchAllOrders = (token, customer_no) => {
    return async function (dispatch, getState) {
        axios.get(`${REACT_APP_API_ENDPOINT}/fetch_all_orders/1?customer_no=${customer_no}`, {
            headers: {
                token: token
            }
        })
            .then(res => {
                if (res.data.status === 'success') {
                    dispatch({
                        type: FETCH_ALL_ORDERS,
                        payload: res.data.data
                    })
                } else {
                    dispatch({
                        type: FETCH_ALL_ORDERS,
                        payload: []
                    })
                }
            })
    }
}

// Pagination - More Orders
export const updateOrders = (orders) => {
    return async function (dispatch, getState) {
        const newData = [...getState().orders, ...orders];
        dispatch({
            type: FETCH_ALL_ORDERS,
            payload: newData
        });
    }
}

// most orders Products
export const mostOrderItems = (customer_id, branch_id) => {
    return async function (dispatch, getState) {
        if (branch_id !== null && customer_id !== null)
            axios.get(`${REACT_APP_API_ENDPOINT}/cust_most_order?customer_id=${customer_id}&branch_id=${branch_id}`)
                .then(res => {
                    let data;
                    if (res.data.status === 'failure' || res.data === undefined) {
                        data = [];
                    } else {
                        data = res.data.result;
                    }
                    dispatch({
                        type: MOST_ORDER_ITEMS,
                        payload: data
                    })
                });
    }
}


// unclaim giftcard
export const unclaimGiftcardItems = (token, customer_id) => {
    return async function (dispatch, getState) {
        if (customer_id !== null)
            axios.post(`${REACT_APP_API_ENDPOINT}/fetch_gift_card/1`, { customer_id }, {
                headers: {
                    token: token
                }
            })
                .then(res => {
                    let data;
                    if (res.data.status === 'failure' || res.data === undefined) {
                        data = [];
                    } else {
                        data = res.data.data;
                    }
                    dispatch({
                        type: UNCLAIM_GIFTCARD,
                        payload: data
                    })
                });
    }
}


// claimed giftcard
export const claimedGiftcardItems = (token, customer_id) => {
    return async function (dispatch, getState) {
        if (customer_id !== null)
            axios.post(`${REACT_APP_API_ENDPOINT}/fetch_claimed_gift_card/1`, { customer_id }, {
                headers: {
                    token: token
                }
            })
                .then(res => {
                    let data;
                    if (res.data.status === 'failure' || res.data === undefined) {
                        data = [];
                    } else {
                        data = res.data.data;
                    }
                    dispatch({
                        type: CLAIMED_GIFTCARD,
                        payload: data
                    })
                });
    }
}


// sent giftcard
export const sentGiftcardItems = (token, customer_id) => {
    return async function (dispatch, getState) {
        if (customer_id !== null)
            axios.post(`${REACT_APP_API_ENDPOINT}/fetch_sent_gift_card/1`, { customer_id }, {
                headers: {
                    token: token
                }
            })
                .then(res => {
                    let data;
                    if (res.data.status === 'failure' || res.data === undefined) {
                        data = [];
                    } else {
                        data = res.data.data;
                    }
                    dispatch({
                        type: SENT_GIFTCARD,
                        payload: data
                    })
                });
    }
}


// add new giftcard
export const addNewGiftcard = (giftcard) => {
    return function (dispatch, getState) {
        dispatch({
            type: ADD_NEW_GIFTCARD,
            payload: giftcard
        })
    }
}

// set new promo code
export const setPromoCode = (data) => {
    return function (dispatch, getState) {
        dispatch({
            type: SET_PROMO_CODE,
            payload: data
        })
    }
}


//get promo code
// unclaim giftcard
export const getPromoCode = (token) => {
    return async function (dispatch, getState) {
        axios.post(`${REACT_APP_API_ENDPOINT}/get_promo_code`, {}, {
            headers: {
                token: token
            }
        })
            .then(res => {
                let data;
                if (res.data.status === 'failure' || res.data === undefined) {
                    data = [];
                } else {
                    data = res.data.data;
                }
                dispatch({
                    type: GET_PROMO_CODE,
                    payload: data
                })
            });
    }
}

export const getCustomerRoles = () => {
    return async function (dispatch) {
        axios.get(`${REACT_APP_API_ENDPOINT}/get_customer_roles`)
            .then(res => {
                if (res.data.status === "success") {
                    dispatch({
                        type: CUSTOMER_ROLES,
                        payload: res.data.data
                    });
                } else {
                    dispatch({
                        type: CUSTOMER_ROLES,
                        payload: null
                    });
                }
            })
            .catch(err => {
                dispatch({
                    type: CUSTOMER_ROLES,
                    payload: null
                });
            });
    }
}
export const fetchCoupons = (data, token) => {
    return async function (dispatch) {
        axios.post(`${REACT_APP_API_ENDPOINT}/all_valid_coupons`, data, {
            headers: {
                token: token
            }
        })
            .then(res => {
                if (res.data.status === "Success") {
                    dispatch({
                        type: GET_COUPONS,
                        payload: res.data.all_coupon
                    });
                } else {
                    dispatch({
                        type: GET_COUPONS,
                        payload: null
                    });
                }
            })
            .catch(err => {
                dispatch({
                    type: GET_COUPONS,
                    payload: null
                });
            });
    }
}
export const currentCoupon = (coupon) => {
    return async function (dispatch) {
        dispatch({
            type: CURRENT_COUPON,
            payload: coupon
        });
    }
}