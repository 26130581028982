import React, { useEffect, useState } from "react";
import Footer from "../components/common/Footer";
import Header from "../components/common/Header";
import TopBar from "../components/common/TopBar";
import LeftBar from "../components/LeftBar";
import HeadTop from "../components/myaccount/Headtop";
import MyAccountRightSection from "../components/myaccount/MyAccountRightSection";
import { Breadcrumbs } from "@material-tailwind/react";
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router";

const MyAccountPage = () => {
  const state = useSelector(state => state);
  const navigate = useNavigate();
  useEffect(() => {
    if (state.token === null) navigate('/login');
  }, [state.token, navigate]);
  const [dist, setDist] = useState(undefined);
  /*useEffect(() => {
    let currStickyPos;
    if (
      document.querySelector(".sidediv")?.getBoundingClientRect()?.top === null
    ) {
      return;
    }
    currStickyPos =
      document.querySelector(".sidediv")?.getBoundingClientRect()?.top +
      window.pageYOffset;
    window.onscroll = function () {
       Check if the current Y offset
        is greater than the position of
        the element 
      if (window.pageYOffset > currStickyPos) {
        document.querySelector(".sidediv")?.classList.add("is-sticky");
      } else {
        document.querySelector(".sidediv")?.classList.remove("is-sticky");
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document.querySelector(".sidediv")?.getBoundingClientRect()?.top]);*/
  useEffect(() => {
    const sidebar = document.querySelector(".stick");
    const { top } = sidebar.getBoundingClientRect();
    setDist(top);
  }, []);

  useEffect(() => {
    if (!dist) return;
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dist]);
  const isSticky = (e) => {
    const sidebarEl = document.querySelector(".sidediv");
    const scrollTop = window.scrollY;
    if (scrollTop >= 510) {
      sidebarEl?.classList.add("is-sticky");
    } else {
      sidebarEl?.classList.remove("is-sticky");
    }
  };
  return (
    <>
      <TopBar />
      <Header />
      <HeadTop />
      <div className="breadcrumbfullsize  mt-[100px]">
        <Breadcrumbs className="bg-primary-light-color mb-4">
          <div className={`text-dark-color mx-1 mbtb:mx-3 ${state.theme === "dark" ? 'hover:text-grey-400' : 'hover:text-grey-700'} flex justify-center items-center`} onClick={() => navigate('/')}>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
              <path strokeLinecap="round" strokeLinejoin="round" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
            </svg>
            <div className="hidden mbtb:block">Home</div>
          </div>
          <div className={`${state.theme === "dark" ? 'text-grey-400' : 'text-grey-600'} mx-1 mbtb:mx-3 flex justify-center items-center`}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-5 w-5 mr-1">
              <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
            </svg>
            <div className="hidden mbtb:block">My Account</div>
          </div>
        </Breadcrumbs>
      </div>
      <div className="flex flex-col md:flex-row stick overflow-x-hidden ">
        <LeftBar className="" />
        <MyAccountRightSection />
      </div>
      <Footer />
    </>
  );
};

export default MyAccountPage;
