import React, { useState, useEffect } from 'react'
import { ClockIcon, PhoneIcon, MailIcon } from '@heroicons/react/outline'
import { currentBranch } from "../../actions";
import { useSelector, useDispatch } from 'react-redux';

const TopBar = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state);
  // const [firstRender, setFirstRender] = useState(true);
  const [selectedBranch, selectBranch] = useState("");
  const [myLocation, setMyLocation] = useState(null);
  const branches = useState(state.branches);

  let branch_details = [];
  for(let i = 0; i < state.branches.length; i++){
    for(let j = 0; j < state.branches[i].length; j++){
      branch_details.push(state.branches[i][j]);
    }
  }

  branch_details.reverse();

  useEffect(() => {
    // console.log("lll",selectedBranch);
    let i = 0;
    if (selectedBranch !== "") {
      branch_details.map((branch, index) => {
        if (branch.branch_name === selectedBranch) {
          // console.log(index);
          i = index;
        }
      })
      // console.log("state.branche",state.branches)
      dispatch(currentBranch(branch_details[i]));
    }
  }, [selectedBranch]);

  // const getLocation = new Promise((resolve, reject) => {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(position => {
  //       resolve([position.coords.latitude, position.coords.longitude]);
  //     });
  //   } else {
  //     resolve([]);
  //   }
  // });

  function getLocation() {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
          resolve([position.coords.latitude, position.coords.longitude]);
        });
      } else {
        resolve([]);
      }
    });
  }

  // function showPosition(position) {
  //   setMyLocation([position.coords.latitude, position.coords.longitude]);
  // }

  function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
    var R = 6371; // Radius of the earth in km
    var dLat = deg2rad(lat2 - lat1);  // deg2rad below
    var dLon = deg2rad(lon2 - lon1);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2)
      ;
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km
    return d;
  }

  function deg2rad(deg) {
    return deg * (Math.PI / 180)
  }

  if (myLocation === null) getLocation();
  useEffect(() => {
    if (state.currentBranch !== null) {
      selectBranch(state.currentBranch.branch_name);
    }
    else {
      if (state.branches.length > 0) {
        // let nearer_branch = null;
        // getLocation().then(res => {
        //   const myLocation = res;
        //   let min_distance = 10000;
        //   state.branches.map(br => {
        //     const location = [Number(br.latitude.split(" ")[0].slice(0, -1)), Number(br.longitude.split(" ")[0].slice(0, -1))];
        //     let distance = getDistanceFromLatLonInKm(myLocation[0], myLocation[1], location[0], location[1]);
        //     // console.log(distance);
        //     if (distance < min_distance) {
        //       min_distance = distance;
        //       nearer_branch = br;
        //     }
        //   })
        //   // console.log(nearer_branch);
        //   dispatch(currentBranch(nearer_branch));
        // });
        dispatch(currentBranch(state.branches[0]));
      }
    }
    // else {
    //   dispatch(currentBranch({
    //     "branch_id": "3e4dd110-94ba-4146-893f-6e545258b0e7",
    //     "branch_name": "Sarabha Nagar"
    //   }));
    // }
  }, [state.currentBranch, state.branches, myLocation]);

  return (
    <div className='w-full px-4 py-3 animate__animated animate__fadeInDown z-10 font-montserrat' style={{ backgroundColor: "var(--bgFooter)" }}>
      <div className='flex sm:flex-row flex-col  justify-center sm:justify-between items-center gap-1 md:container md:mx-auto'>
        {/* <div className='flex gap-1'>
                <h5 className='flex items-center  gap-0.5 font-bold text-sm uppercase' style={{color:"var(--primaryDarkColor)"}}><ClockIcon className="h-4 w-4"/>Open Now: </h5>
                <h5 className='text-sm font-bold uppercase' style={{color:"var(--primaryDarkColor)"}}>8AM - 10PM</h5>
            </div> */}
        <div>
          <select className='p-1.5 bg-primary-light-color outline-none shadow-sm  border-radius-5' value={selectedBranch} onChange={(e) => selectBranch(e.target.value)} >
            {
              branch_details.map(branch => <option value={branch.branch_name} key={branch.branch_id}>{branch.branch_name}</option>)
            }
          </select>
        </div>
        <div>
          <h5 className='flex items-center gap-0.5 text-sm font-bold uppercase' style={{ color: "var(--primaryDarkColor)" }}><MailIcon className="h-4 w-4" /> <a href={"mailto:" + state.currentBranch?.email}>{state.currentBranch?.email}</a></h5>
        </div>
      </div>
    </div>
  )
}

export default TopBar