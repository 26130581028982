import { useEffect, useState } from 'react';
import './App.css';
import 'animate.css';
import AppRoutes from './routes/AppRoutes';
import { useSelector, useDispatch } from 'react-redux';
import { currentCoupon, deleteCartItem, fetchAllOrders, fetchCoupons, getBranches, getCategories, getCoupons, getCustomerRoles, getProducts, setTheme } from "./actions";
import { setLogin, setCustomerDetails, currentBranch, setCartItems, logout } from './actions';
import { useAlert } from 'react-alert';

function App() {
  const dispatch = useDispatch();
  const state = useSelector(state => state);
  const alert = useAlert();

  const [firstRender, setFirstRender] = useState(true);

  // Setting defaults when app renders (executed only one time)
  if (firstRender === true) {
    setFirstRender(false);

    // Setting Theme from Local Storage or DARK by default
    let userTheme = window.localStorage.getItem("heebee-cafe-website-theme");
    if (userTheme === null) {
      window.localStorage.setItem("heebee-cafe-website-theme", 'dark');
      userTheme = 'dark';
    }
    document.querySelector('html').setAttribute("data-theme", userTheme);
    dispatch(setTheme(userTheme));

    // Setting Cart Items from Local Storage
    let cart = window.localStorage.getItem('heebee-cart-items');
    if (cart === null) {
      window.localStorage.setItem('heebee-cart-items', JSON.stringify([]));
    } else {
      cart = JSON.parse(window.localStorage.getItem('heebee-cart-items'));
      dispatch(setCartItems(cart));
    }

    // Fetch Branches on First Render
    dispatch(getBranches());
    dispatch(getCustomerRoles());
  }

  useEffect(() => {
    state.cartItems.forEach((i) => {
      if (i.coupon !== null) dispatch(currentCoupon(i.coupon));
    })
  }, [state.cartItems])

  useEffect(() => {
    let amount = 0;
    state.cartItems.forEach((i) => {
      if (i.coupon === null) amount += i.amount;
    });
    if (state.currentCoupon !== null && amount < state.currentCoupon.min_cart_value) {
      alert.error('Coupon Removed ! Cart Value is less than required');
      state.cartItems.forEach((i, index) => {
        if (i.coupon !== null) {
          dispatch(deleteCartItem(index));
        }
      })
      dispatch(currentCoupon(null));
    }
  }, [state.cartItems])



  // If user LOGIN or LOGOUT
  useEffect(() => {

    // If user is not loggedin
    if (state.token === null) {
      // Get token from local storage (Previous Login)
      let token = window.localStorage.getItem("heebee-cafe-login-token");
      if (token !== 'null') { // Token Exists
        dispatch(setLogin(token));
      }
    }
    dispatch(setCustomerDetails(state.token));
    window.localStorage.setItem("heebee-cafe-login-token", state.token); // Setting current token in local storage
  }, [state.token, dispatch]);



  // Handling Customer Details
  useEffect(() => {
    window.localStorage.setItem("heebee-cafe-customer-details", JSON.stringify(state.customerDetails));

    // User logged in somewhere else
    if (state.customerDetails === "Token does not match") {
      dispatch(logout());
    }
    else if (state.customerDetails !== null) {

      // Changing current branch to user's home branch
      // const branch = {
      //   branch_name: state.customerDetails.branch,
      //   branch_id: state.customerDetails.branch_id
      // }
      // dispatch(currentBranch(branch));

      // Fetching previous orders of user
      if (state.token !== null && state.customerDetails !== null)
        dispatch(fetchAllOrders(state.token, state.customerDetails.mobile_no))
    }

  }, [state.customerDetails, dispatch, state.token]);



  // Handling current Branch
  useEffect(() => {
    if (state.currentBranch !== null) {
      dispatch(getCategories(state.currentBranch))    // Categories of Current Branch
      dispatch(getProducts(state.currentBranch))    // Products in current Branch

      // Fetching all valid coupons as per user birthday and branch
      if (state.token !== null && state.customerDetails !== null) {
        let bd = false;
        if (state.customerDetails.date_of_birth === null) bd = false;
        else if ((Number(state.customerDetails.date_of_birth?.split('-')[1]) === Number((new Date()).getMonth() + 1)) && (Number(state.customerDetails.date_of_birth.split('-')[2]) === Number((new Date()).getDate()))) {
          bd = true;
        }
        dispatch(fetchCoupons({
          "branch_id": state.currentBranch.branch_id,
          "customer_no": state.customerDetails.mobile_no,
          "customer_id": state.customerDetails.customer_id,
          "bday": bd
        }, state.token))
      }
    }
  }, [state.currentBranch, state.token, state.customerDetails, dispatch]);


  return (
    <div id="theme-change" className='text-dark-color' >
      <AppRoutes />
    </div>
  );
}

export default App;