import React, { useState } from 'react'
// import CoffeeCup from '../../assets/images/banner-cup.png'
import TypeAnimation from 'react-type-animation';
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react';
const texts = ['Bee in Black', 'A black Coffee', 'A Hot Coffee', 'A Cold Coffee'];
const CoffeeCup = 'https://storage.googleapis.com/heebee_website_images/home_page/banner-cup.png'
const HeadTop = () => {

  const navigate = useNavigate();
  const [key, setKey] = useState(0);
  useEffect(() => {
    setTimeout(() => {
      if (key === 3) setKey(0);
      else setKey(key => key + 1);
    }, 3000)
  }, [key])
  return (
    <>
      <div className='head-top pt-[50px] z-0 mb-[60px] relative'>
        <div className='w-full head-top overflow-hidden absolute mt-[-60px] bg-gradient-class'>
          <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
            <pattern patternUnits="userSpaceOnUse" width="52" height="90" id="p" className='rotate-90'>
              <path fill="none" stroke="var(--honeyComb)" strokeWidth="3" d="m0,15 26-15 26,15v30l-26,15-26-15zM26,60v30" /></pattern>
            <rect width="100%" height="100%" fill="url(#p)" />
          </svg>
        </div>
        <div className='grid md:grid-cols-2 grid-cols-1 gap-2 md:container md:mx-auto mb-[500px]'>
          <div className='flex flex-col justify-center md:items-start items-center px-6 md:px-[50px] z-50'>
            <h5 className='font-bold text-sm mbtb:text-base uppercase animate__animated animate__fadeInLeft font-montserrat'>WHERE YOU BEAN ALL OUR LIFE?</h5>

            <h2 className='font-bold sm:text-5xl text-3xl capitalize mb-6 animate__animated animate__fadeInLeft font-montserrat text-center md:text-left'>Start your day with
              <div key={key} className='font-bold capitalize mb-6 animate__animated animate__fadeIn font-montserrat text-center md:text-left ' style={{ color: "var(--primaryDarkColor)" }}>{texts[key]}</div>
              {/* <span style={{ color: "var(--primaryDarkColor)" }}>
                <TypeAnimation
                  cursor={false}
                  sequence={['Bee in Black', 5000, 'A black Coffee', 10000, 'A Hot Coffee', 15000, 'A Cold Coffee', 20000]}
                  wrapper="h2"
                  repeat={Infinity}
                />
              </span> */}

            </h2>
            <button className='button-primary px-8 py-2 uppercase text-lg animate__animated animate__fadeInLeft font-roboto border-radius-5' onClick={() => { navigate('/menu') }}>
              order now
            </button>
          </div>
          <div className='flex justify-center animate__animated animate__fadeInDown overflow-hidden z-50'>
            <img src={CoffeeCup} alt="Coffee Cup" className='sm:w-[350px] w-[250px] coffee-cup-img  drop-shadow-xl' />
          </div>
          <svg id="wave" className='absolute bottom-[-5px] left-0' viewBox="0 0 1440 170" version="1.1" xmlns="http://www.w3.org/2000/svg"><defs><linearGradient id="sw-gradient-0" x1="0" x2="0" y1="1" y2="0"><stop stopColor="var(--primaryLightColor)" offset="0%"></stop></linearGradient></defs><path fill="url(#sw-gradient-0)" d="M0,85L720,136L1440,85L2160,119L2880,85L3600,102L4320,51L5040,119L5760,136L6480,0L7200,0L7920,34L8640,119L9360,136L10080,68L10800,85L11520,102L12240,17L12960,102L13680,68L14400,17L15120,85L15840,68L16560,119L17280,119L17280,170L16560,170L15840,170L15120,170L14400,170L13680,170L12960,170L12240,170L11520,170L10800,170L10080,170L9360,170L8640,170L7920,170L7200,170L6480,170L5760,170L5040,170L4320,170L3600,170L2880,170L2160,170L1440,170L720,170L0,170Z"></path></svg>
        </div>
      </div>
    </>
  )
}

export default HeadTop