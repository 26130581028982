import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useAlert } from 'react-alert';
import { useSelector } from 'react-redux';
import GiftcardImg0 from '../../assets/images/giftcard/0.png'
import ReactCardFlip from 'react-card-flip';
const { REACT_APP_API_ENDPOINT } = process.env;

const Giftcard = ({ item, giftcardButton }) => {
    const alert = useAlert();

    const state = useSelector(state => state);

    const claimGiftcardHandler = () => {

        let data = {
            gift_card_id: item.gift_card_id,
            customer_id: state.customerDetails.customer_id,
        };

        axios.post(`${REACT_APP_API_ENDPOINT}/claim_giftcard`, data, {
            headers: {
                token: state.token
            }
        })
            .then(res => {
                if (res.data.status === "success") {
                    alert.success("Giftcard Claim Successfully");
                    window.location.reload();

                }
                else
                    alert.error(res.data.msg)
            })
            .catch(() => {
                alert.error("error occured")
            });

    }

    const [isFlipped, setIsFlipped] = useState(false);
    const handleClick = () => {
        setIsFlipped(flipped => !flipped);
    }


    return (

        <ReactCardFlip isFlipped={isFlipped} flipDirection="vertical">
            <div className="m-2 bg-[#3b2220] rounded-xl relative" onClick={handleClick}>
                <img src={GiftcardImg0} alt="" className="rounded-xl border-4 border-primary-dark-color" />
                <div className="absolute top-8 left-6">
                    <h2 className="text-lg font-semibold  text-[#ffd490] mb-3">{item.title}</h2>
                    <h2 className="text-2xl  text-[#ffd490] mb-1">{item.amount && '₹'}{item.amount}</h2>
                    {
                        giftcardButton === 'unclaim' ?
                            (<button className="bg-primary-dark-color px-3 py-0.5 text-xs text-primary-light-color font-semibold rounded-[5px] mb-2" onClick={claimGiftcardHandler}>Claim</button>) :
                            giftcardButton === 'claimed' ?
                                (<button className="bg-primary-dark-color px-3 py-0.5 text-xs text-primary-light-color font-semibold rounded-[5px] opacity-50 mb-2" disabled>Claimed</button>) :
                                giftcardButton === 'sent' ?
                                    (<button className="bg-primary-dark-color px-3 py-0.5 text-xs text-primary-light-color font-semibold rounded-[5px] opacity-50 mb-2" disabled>Sent</button>) : ''
                    }
                    {(giftcardButton === 'unclaim' || giftcardButton === 'claimed') && <h2 className="text-xs text-[#ffd490] mb-1">Received from {item.created_by}</h2>}
                    {/* {giftcardButton === 'sent' && <h2 className="text-xs text-[#ffd490] mb-1">Sent to {item.created_by}</h2>} */}
                </div>
            </div>
            <div className="m-2 bg-[#3b2220] rounded-xl relative overflow-hidden" onClick={handleClick}>
                <img src={GiftcardImg0} alt="" className="rounded-xl border-4 border-primary-dark-color opacity-50" />
                <div className="absolute top-8 left-6">
                    <h2 className="text-lg font-semibold  text-[#ffd490] mb-3">{item.title}</h2>
                    <p className='text-sm font-bold text-[#ffd490]'>{item.description}</p>
                </div>
            </div>
        </ReactCardFlip>
    )
}

export default Giftcard