import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { Breadcrumbs } from "@material-tailwind/react";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from 'react-redux';
import CartItemsCard from "../cart/CartItemsCard";
import { Select, Option } from "@material-tailwind/react";
import { setPickUpTime } from "../../actions";

const DAY = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const CheckoutPageLeftSection = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const state = useSelector(state => state);

  const [todayDate, setTodayDate] = useState(null);
  // const [nextDate, setNextDate] = useState(null);
  const [dateSelected, selectDate] = useState(null);
  const [timeSelected, selectTime] = useState(null);
  const [minTime, selectMinTime] = useState(null);
  const [timeArr, setTimeArr] = useState([]);
  const [refreshKey, setRefreshKey] = useState(0);
  const updateDate = () => {
    setTodayDate(new Date());
    setTimeout(updateDate, 60000);
  }

  if (todayDate === null) updateDate();

  useEffect(() => {
    // let tomorrow = new Date();
    // tomorrow.setDate(tomorrow.getDate() + 1);
    // tomorrow = new Date(tomorrow.getFullYear(), tomorrow.getMonth(), tomorrow.getDate());
    // setNextDate(tomorrow);
    selectDate(todayDate);
  }, [todayDate])

  useEffect(() => {
    // if (dateSelected === "TOMORROW") selectMinTime([9, 0]);
    // else {
    let hour, min;
    min = (new Date()).getMinutes();
    min = (new Date((new Date()).getTime() + ((20 - (min % 10)) * 60000))).getMinutes();
    hour = (new Date((new Date()).getTime() + ((20 - (min % 10)) * 60000))).getHours();
    selectMinTime([hour, min]);
    // }
  }, [dateSelected])

  useEffect(() => {
    if (minTime !== null) {
      setTimeArr([]);
      while (minTime[0] <= 23) {
        if (minTime[0] === 23 && minTime[1] === 40) break;
        minTime[1] += 10;
        if (minTime[1] === 60) {
          minTime[0]++;
          minTime[1] = 0;
        }
        let ts1 = ((minTime[0] < 13) ? ('0' + minTime[0]).slice(-2) : ('0' + (minTime[0] - 12)).slice(-2)) + ":" + ('0' + minTime[1]).slice(-2) + ((minTime[0] < 12) ? " AM" : " PM");

        let xmin = minTime[1] + 20;
        let xhour = minTime[0];
        if (xmin >= 60) xhour++;
        xmin = (xmin <= 50) ? xmin : xmin - 60;
        let ts2 = ((xhour < 13) ? ('0' + xhour).slice(-2) : ('0' + (xhour - 12)).slice(-2)) + ":" + ('0' + xmin).slice(-2) + ((xhour < 12 || xhour === 24) ? " AM" : " PM");
        setTimeArr(timeArr => [...timeArr, (ts1 + " - " + ts2)]);
      }
      setRefreshKey(key => key + 1);
    }
  }, [minTime])

  useEffect(() => {
    let pickup, ds, ts;
    ds = dateSelected;
    ts = timeSelected
    if (dateSelected === null || timeSelected === null) pickup = null;
    else {
      // if (ds === "TODAY") ds = todayDate;
      // else ds = nextDate;
      ds = todayDate;
      let ampm = ts.split("-")[0].split(" ")[1];
      let time = ts.split("-")[0].split(" ")[0];
      let hour = time.split(":")[0];
      let min = time.split(":")[1];
      if (ampm === "PM" && hour < 12) time = `${Number(hour) + 12}:${min}`;
      const [hr, minutes] = time.split(':');
      ds.setHours(hr);
      ds.setMinutes(minutes);
      ds.setSeconds(0);
      // pickup = `${ds.getFullYear()}-${('0' + (ds.getMonth() + 1)).slice(-2)}-${('0' + (ds.getDate())).slice(-2)}*${time}`;
      pickup = ds.toString();
    }
    dispatch(setPickUpTime(pickup));
  }, [dateSelected, timeSelected, dispatch, todayDate])

  return (
    <div className="basis-3/5 px-2 md:mx-2 py-2 z-50">
      <div className="my-6">
        <Breadcrumbs className="bg-primary-light-color border-radius-5">
          <div className="text-dark-color mx-3 hover:text-grey-400 flex justify-center items-center" onClick={() => navigate('/')}>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
              <path strokeLinecap="round" strokeLinejoin="round" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
            </svg>
            <div className="hidden mbtb:block">Home</div>
          </div>
          <div className="text-dark-color mx-3 hover:text-grey-400 flex justify-center items-center" onClick={() => navigate('/menu')}>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
              <path strokeLinecap="round" strokeLinejoin="round" d="M19 20H5a2 2 0 01-2-2V6a2 2 0 012-2h10a2 2 0 012 2v1m2 13a2 2 0 01-2-2V7m2 13a2 2 0 002-2V9a2 2 0 00-2-2h-2m-4-3H9M7 16h6M7 8h6v4H7V8z" />
            </svg>
            <div className="hidden mbtb:block">Menu</div>
          </div>
          <div className="text-dark-color mx-3 hover:text-grey-400 flex justify-center items-center" onClick={() => navigate('/cart')}>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
              <path strokeLinecap="round" strokeLinejoin="round" d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" />
            </svg>
            <div className="hidden mbtb:block">Cart</div>
          </div>
        </Breadcrumbs>
      </div>
      <Accordion preExpanded={[1, 2]} allowZeroExpanded allowMultipleExpanded={true} className="w-full">
        <AccordionItem uuid={1}>
          <AccordionItemHeading>
            <AccordionItemButton className="py-2 flex justify-between bg-primary-dark-color text-light-color  p-2 w-full font-roboto tracking-wide font-bold text-2xl mb-2 border-radius-5">
              Pick-Up Time
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <div className="my-2 pb-2 px-2 flex flex-wrap gap-4 text-dark-color justify-center pickuptime:justify-start">
              {/* {todayDate !== null && nextDate !== null &&
                <div className="w-[80%] pickuptime:w-auto bg-transparent text-blue-grey-700 font-sans font-normal text-left outline-0 focus:outlined-0 disabled:bg-blue-grey-50 disabled:border-0 transition-all border text-sm px-3 py-3 rounded-[7px] border-blue-grey-200 ">
                  <Select
                    label="Select Pick-Up Date"
                    size="lg"
                    // onChange={(e) => {
                    //   selectDate(e);
                    //   selectTime(null);
                    // }}
                  >
                    <Option value="TODAY">{`${('0' + todayDate.getDate()).slice(-2)}-${('0' + (todayDate.getMonth() + 1)).slice(-2)}-${todayDate.getFullYear()} (${DAY[todayDate.getDay()]})`}</Option>
                    <Option value="TOMORROW">{`${('0' + nextDate.getDate()).slice(-2)}-${('0' + (nextDate.getMonth() + 1)).slice(-2)}-${nextDate.getFullYear()} (${DAY[nextDate.getDay()]})`}</Option>
                  </Select>
                </div>} */}
              {todayDate !== null &&
                <div className="w-[80%] pickuptime:w-auto">
                  <div className="relative w-full min-w-[200px] h-11">
                    <button type="button" className="peer w-full h-full bg-transparent text-blue-grey-700 font-sans font-normal text-left outline-0 focus:outlined-0 disabled:bg-blue-grey-50 disabled:border-0 transition-all border border-t-transparent text-sm px-3 py-3 rounded-[7px] border-blue-grey-200" aria-expanded="false" aria-haspopup="listbox" role="combobox">
                      <span className="absolute top-2/4 -translate-y-2/4 left-3 pt-0.5">{`${('0' + todayDate.getDate()).slice(-2)}-${('0' + (todayDate.getMonth() + 1)).slice(-2)}-${todayDate.getFullYear()} (${DAY[todayDate.getDay()]})`}
                      </span>
                      <div className="grid place-items-center absolute top-2/4 right-2 pt-px w-5 h-5 text-blue-grey-400 rotate-0 -translate-y-2/4 transition-all"></div>
                    </button>
                    <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal transition-all -top-1.5 before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 before:rounded-tl-md before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 after:rounded-tr-md after:pointer-events-none after:transition-all peer-disabled:after:border-transparent text-[11px] peer-disabled:text-transparent before:border-t before:border-l after:border-t after:border-r leading-tight text-blue-grey-400 before:border-blue-grey-200 after:border-blue-grey-200">Select Date</label>
                  </div>
                </div>}
              <div className="w-[80%] pickuptime:w-auto">
                <Select
                  label="Select Pick-Up Time"
                  size="lg"
                  key={refreshKey}
                  onChange={(e) => selectTime(e)}
                >
                  {dateSelected === null ? <Option value={null}>Select the date first</Option> : timeArr.map((slot, index) => <Option key={index} value={slot}>{slot}</Option>)}
                </Select>
              </div>
            </div>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem uuid={2}>
          <AccordionItemHeading>
            <AccordionItemButton className="py-2 flex justify-between bg-primary-dark-color text-light-color  p-2 w-full font-roboto tracking-wide font-bold text-2xl border-radius-5">
              Order Items
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <div className="flex justify-center flex-wrap">
              {state.cartItems.length > 0 && state.cartItems.map((item, index) => <CartItemsCard key={index} item={item} />)} </div>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default CheckoutPageLeftSection;
