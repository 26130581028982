import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as LoginIcon } from "../assets/images/heebeeIcon.svg";
import { Input } from "@material-tailwind/react";
import { Select, Option } from "@material-tailwind/react";
import { useNavigate } from "react-router";
import { useSelector } from 'react-redux';
import axios from "axios";
import { useAlert } from "react-alert";
const { REACT_APP_API_ENDPOINT } = process.env;

const Signup = () => {
  const navigate = useNavigate();
  const alert = useAlert();
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const state = useSelector(state => state);
  const [selectedBranch, selectBranch] = useState();
  useEffect(() => {
    if (state.token !== null) navigate('/');
  }, [state.token]);

  const signUp = () => {
    const userDetails = {
      "first_name": name,
      "email": email,
      "mobile_no": mobile,
      "password": password,
      "branch_id": state.currentBranch ? state.currentBranch.branch_id : state.branches[0].branch_id,
      "branch": state.currentBranch ? state.currentBranch.branch_name : state.branches[0].branch_name,
    }
    //userDetails.branch !== undefined || userDetails.branch_id !== null || 
    if (userDetails.email !== "" || userDetails.first_name !== "" || userDetails.mobile_no.length === 10 || userDetails.password !== "") {
      axios.post(`${REACT_APP_API_ENDPOINT}/customer_signup`, userDetails)
        .then(res => {
          if (res.data.status === "success") {
            navigate('/login');
          }
          else {
            alert.error(res.data.msg);
          }
        });
    }
    else {
      alert.error("Please fill all the details !");
    }
  }

  return (
    <div className="py-6 h-screen flex flex-row justify-center items-center bg-footer">
      <div className="flex bg-product rounded-lg shadow-lg overflow-hidden mx-auto w-[24rem] lg:w-[50rem] scale-90">
        <div className="hidden lg:flex lg:w-1/2 bg-cover bg-primary-color justify-center items-center">
          <LoginIcon className=" fill-color mt-1 ml-1" />
        </div>
        <div className="w-full p-8 lg:w-1/2 flex flex-col justify-center relative">
          <div className="absolute top-3 left-3 cursor-pointer">
            <Link to="/">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
              </svg>
            </Link>
          </div>
          <h1 className="font-bold text-primary-color text-center text-4xl mt-2">
            Hee<span className="text-primary-dark-color ">bee</span>
          </h1>
          <p className="text-xl text-primary-color text-center">
            Hey! Welcome to HeeBee
          </p>
          <div className="mt-4 mb-2 ">
            <Input
              size="lg"
              className="text-dark-color"
              label="Name"
              onChange={(e) => setName(e.target.value)}
              value={name}
            />
          </div>
          <div className="my-2 ">
            <Input
              size="lg"
              className="text-dark-color"
              label="Email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
          </div>
          <div className="my-2 ">
            <Input
              size="lg"
              className="text-dark-color"
              label="Mobile"
              value={mobile}
              type='number'
              onChange={(e) => {
                if (e.target.value.length > 10) return false;
                setMobile(e.target.value)
              }}
            />
          </div>
          <div className="my-2 ">
            <Input
              size="lg"
              className="text-dark-color"
              label="Password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              type="password"
            />
          </div>
          {/* <div className="mt-2 mb-1 pb-2  flex items-end">
            <Select label="Select Branch" size="lg" className='text-dark-color' onChange={(e) => selectBranch(e)} >
              {
                state.branches.map(branch => <Option value={branch.branch_name} key={branch.branch_id}>{branch.branch_name}</Option>)
              }
            </Select>
          </div> */}

          <div className="mt-3">
            <button className="bg-primary-dark-color text-light-color font-bold py-2 px-4 w-full rounded hover:opacity-95" onClick={() => signUp()}>
              Sign Up
            </button>
          </div>
          <div className="h-0.5 bg-grey-400 rounded-sm mt-4 "></div>

          <div className="px-1">
            <button
              className="bg-white outline outline-offset-2 outline-2 outline-[#000000] text-[#002a1b] font-bold mt-5 py-[6px] px-4 w-full rounded hover:opacity-90"
              onClick={() => {
                navigate("/login");
              }}
            >
              Already have an Account? Sign in Now!
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
