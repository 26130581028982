import React from "react";
import HeadTop from "../components/cart/Headtop";
import Footer from "../components/common/Footer";
import Header from "../components/common/Header";
import TopBar from "../components/common/TopBar";
import CartPageLeftSection from "../components/cart/CartPageLeftSection";
import CartPageRightSection from "../components/cart/CartPageRightSection";
import { useSelector } from 'react-redux';

const CartPage = () => {
  const state = useSelector(state => state);
  return (
    <>
      <TopBar />
      <Header />
      <HeadTop />
      <div style={{ overflow: (state.cartItems.length > 0) ? "hidden" : "revert" }} className="flex flex-col justify-center md:flex-row stick3 px-2 pr-4s">
        <CartPageLeftSection />
        {(state.cartItems.length > 0) &&  <CartPageRightSection />}
      </div>
      <Footer />
    </>
  );
};

export default CartPage;
