import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Mail, MapPin, Phone } from 'react-feather';
import axios from 'axios';
import { useAlert } from 'react-alert';
const { REACT_APP_API_ENDPOINT } = process.env;

const Content = () => {
    const alert = useAlert();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [msg, setMsg] = useState("");

    const state = useSelector(state => state);

    const contactUs = () => {
        axios.post(`${REACT_APP_API_ENDPOINT}/contact_us`, {
            name: name,
            email: email,
            message: msg,
            contact: mobile
        }).then(response => {
            if (response.data.status === 'success') {
                setEmail(""); setName(""); setMsg(""); setMobile("");
                alert.success("Thank You for this message. We will contact you soon.");
            }
            else alert.error("Internal Error !");
        }).catch(err => alert.error("Internal Error !"));
    }
    return (
        <div className='md:container md:mx-auto px-6'>
            <div className='grid md:grid-cols-2 grid-cols-1 gap-4 mb-4  bg-primary-light-color border-radius-10  md:p-8 p-4 '>
                <div className='flex justify-start p-2 w-full'>
                    <div className='flex flex-col justify-start items-start animate__animated animate__fadeInLeft'>
                        <h2 className='text-dark-color text-3xl font-bold font-montserrat mb-3'>Get In Touch</h2>
                        {/* <div className='flex justify-center items-center gap-2 mb-3'>
                            <div className='p-3 bg-primary-color text-primary-light-color border-radius-5'>
                                <Phone size={20} />
                            </div>
                            <a href={`tel:${state.currentBranch?.contact}`} className='text-md font-bold font-montserrat text-primary-dark-color'>{state.currentBranch?.contact}</a>
                        </div> */}
                        <div className='flex justify-center items-center gap-2 mb-3'>
                            <div className='p-3 bg-primary-color text-primary-light-color border-radius-5'>
                                <Mail size={20} />
                            </div>
                            <a href={`mailto:${state.currentBranch?.email}`} className='text-md font-bold font-montserrat text-primary-dark-color'>{state.currentBranch?.email}</a>
                        </div>
                        <div className='flex justify-center items-center gap-2'>
                            <div className='p-3 bg-primary-color  text-primary-light-color border-radius-5'>
                                <MapPin size={20} />
                            </div>
                            <p className='text-md font-bold font-montserrat text-primary-dark-color leading-tight'>{state.currentBranch?.address}</p>
                        </div>
                    </div>
                </div>
                <div className='bg-footer text-black animate__animated animate__fadeIn border-radius-5'>
                    <form onSubmit={(e) => e.preventDefault()}>
                        <div className='flex flex-col gap-2 w-full p-4'>
                            <input type="text" className='bg-footer text-primary-dark-color p-2 border-2 border-radius-5 border-primary-dark-color focus:outline-none placeholder-primary-input' placeholder='Enter Your Name' value={name} onChange={e => setName(e.target.value)} required />
                            <input type="email" className='bg-footer text-primary-dark-color p-2 border-2 border-radius-5 border-primary-dark-color focus:outline-none placeholder-primary-input' placeholder='Enter Your Email' value={email} onChange={e => setEmail(e.target.value)} required />
                            <input type="number" className='bg-footer text-primary-dark-color p-2 border-2 border-radius-5 border-primary-dark-color focus:outline-none placeholder-primary-input' placeholder='Enter Your Contact Number' value={mobile} onChange={e => setMobile(e.target.value)} required />
                            <textarea className='bg-footer text-primary-dark-color p-2 border-2 border-radius-5 border-primary-dark-color focus:outline-none placeholder-primary-input' rows="4" placeholder='Enter  Your Comment' value={msg} onChange={e => setMsg(e.target.value)} required ></textarea>
                            <button className='bg-primary-dark-color border-radius-5 text-light-color uppercase font-bold p-2 focus:outline-none w-[100%]' onClick={() => contactUs()}>Submit</button>
                        </div>
                    </form>
                </div>
            </div>
            <div className='grid grid-cols-1 bg-primary-light-color p-2 border-radius-10'>
                <iframe src={state.currentBranch?.branch_name === "Sarabha Nagar" ? 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d109561.64897290755!2d75.74735549444178!3d30.892212645552537!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391a838a04f1e7e5%3A0x4d9b4c568a97ae3b!2sHeeBee%20Coffee!5e0!3m2!1sen!2sin!4v1661332171894!5m2!1sen!2sin' : `https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d27385.197823389397!2d75.8415589831421!3d30.910438730804533!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x4f3952975712fbda!2sHeeBee%20Coffee!5e0!3m2!1sen!2sin!4v1652447632177!5m2!1sen!2sin`} width="100%" height="450" style={{ "border": 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" className='animate__animated animate__fadeIn border-radius-10'></iframe>
            </div>
        </div>
    )
}

export default Content