import React from 'react'
import Footer from '../components/common/Footer'
import Header from '../components/common/Header'
import TopBar from '../components/common/TopBar'
import HeadTop from '../components/home/HeadTop'
import PopularMenu from '../components/home/PopularMenu'
import OurFacility from '../components/home/OurFacility'
import OurGallery from '../components/home/OurGallery'

const HomePage = () => {
  return (
    <div >
      <TopBar/>
      <Header/>
      <HeadTop/>
      <PopularMenu/>
      <OurFacility/>
      <OurGallery/>
      <Footer/>
    </div>
  )
}

export default HomePage