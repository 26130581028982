import React from 'react'

const Content = () => {
    return (
        <div className='text-md text-gray-600 px-6 md:container md:mx-auto mt-[100px]'>
            <p><b>Last Updated On: July 13, 2023</b></p>
            <br />
            <p>
                This Privacy Policy has been compiled to better serve those who are concerned with how their 'Personally identifiable information' (PII) is being used online. PII, as used in information security, is information that can be used on its own or with other information to identify, contact, or locate a single person, or to identify an individual in context. Please read our privacy policy carefully to get a clear understanding of how we collect, use, protect or otherwise handle your Personally Identifiable Information in accordance with our website or mobile app.
                <br />
                This Privacy Policy (“Policy”) describes the policies and procedures on the collection, use, disclosure and protection of your information when you use our website located at heebee.in, or the HeeBee Coffee mobile application (collectively, “HeeBee Platform”) made available by HeeBee Coffee Pvt Ltd., a private company established under the laws of India having its registered office at 48, I Block Rd, I - Block, Sarabha Nagar, Ludhiana, Punjab - 141001
                <br />
                Please read this Policy before using the HeeBee Platform or submitting any personal information to HeeBee. This Policy is a part of and incorporated within, and is to be read along with, the Terms of Use.
            </p>
            <br />
            <p>
                <b>Your Consent :</b> By using the HeeBee Platform and the Services, you agree and consent to the collection, transfer, use, storage, disclosure and sharing of your information as described and collected by us in accordance with this Policy.  If you do not agree with the Policy, please do not use or access the HeeBee Platform.
                <br />
                What personal information do we collect from the people that visit our website or app? When registering on our site or app, creating a booking, or managing profile, as appropriate, you may be asked to enter your name, email address, mailing address, phone number, profile picture, or other details to help you with enhancing your experience, communicate with you on transaction details, providing you with updates and for analytics purposes.
                <br />
                Device Information: In order to improve your app experience and lend stability to our services to you, we may collect information or employ third party plugins that collect information about the devices you use to access our Services, including the hardware models, operating systems and versions, software, file names and versions, preferred languages, unique device identifiers, advertising identifiers, serial numbers, device motion information, mobile network information, and phone state. The information collected thus will be disclosed to or collected directly by these plugins and may be used to improve the content and/or functionality of the services offered to you. Analytics companies may use mobile device IDs to track your usage of the HeeBee Platform;
                <br />
                Personal Information: When a user registers on HeeBee ecosystem or creates a booking, place an order or manages profile, as appropriate, your name, email address, mailing address, phone number, profile picture or other details may be asked to help you with enhancing your experience, communicate with you on transaction details, provide you with updates and for analytics purposes.
            </p>
            <br />
            <p>
                <b>When do we collect information?</b> We collect your information from you when you register on our site or app, book a table at any restaurant, place a pickup order, review your experience, manage your profile, or enter information anywhere on our site or app.
            </p>
            <p>
                <b>How do we use your information?</b> We may use the information we collect from you when you register, respond to a survey or marketing communication, surf the website, or use certain other site features in the following ways:
                <ol className='ml-10 list-disc'>
                    <li>To personalise user's experience and to allow us to deliver the type of content and product offerings in which you are most interested.</li>
                    <li>To carry out our obligations arising from any contracts entered into between you and us and to provide you with the relevant information and services;</li>
                    <li>To improve HeeBee ecosystem (mobile app and website) in order to better serve you.</li>
                    <li>User profiles can enhanced by users uploading their profile image. This is to personalise the app experience</li>
                    <li>To allow us to better service you in responding to your customer service requests.</li>
                    <li>To administer and enhance the security of our HeeBee Platform and for internal operations, including troubleshooting, data analysis, testing, research, statistical and survey purposes;</li>
                    <li>To administer a contest, promotion, survey or other site feature.</li>
                    <li>To send periodic emails or SMS regarding your order, booking, transactions, reviews or other products and services.</li>
                    <li>To understand our users (what they do on our Services, what features they like, how they use them, etc.), improve the content and features of our Services (such as by personalizing content to your interests), process and complete your transactions, make special offers, provide customer support, process and respond to your queries;</li>
                </ol>
            </p>
            <br />
            <p>
                <b>Disclosure and Distribution of your information :</b> We may share your information that we collect for following purposes:
                <ol className='ml-10 list-disc'>
                    <li>With Service providers: We may share your information with our vendors, consultants, marketing partners, research firms and other service providers or business partners, such as Payment processing companies, to support our business. For example, your information may be shared with payment partner to process and collect payments. We also may use vendors for other projects, such as conducting surveys for us.</li>
                    <li>For criminal investigation: As directed by governing laws and enforcement requirements, we may share this information with governmental agencies.</li>
                    <li>For Internal Use: We may share your information with any present or future member of our HeeBee team for analytics, support or service fulfilment purposes.</li>
                    <li>With Advertisers and advertising networks: We may work with third parties such as network advertisers to serve advertisements on the HeeBee Platform and on third-party websites or other media (e.g., social networking platforms). These third parties may use cookies, JavaScript, gifs and other tracking technologies to measure the effectiveness of their ads and to personalize advertising content to you.</li>
                </ol>
            </p>
            <br />
            <p>
                <b>How do we protect visitor information?</b> Our website is scanned on a regular basis for security deficiency and known vulnerabilities in order to make your visit to our site as safe as possible. Your personal information is contained behind secured networks and is only accessible by a limited number of persons who have special access rights to such systems, and are required to keep the information confidential.
                <br />
                We implement a variety of security measures when a customer places an order, submits, or accesses their information to maintain the safety of your personal information. All transactions are processed through a gateway provider and are not stored or processed on our servers. It is important for you to protect against unauthorised access to your password and to your computer. Be sure to sign off when you finish using a shared computer.
            </p>
            <br />
            <p>
                <b>Do we use 'cookies'?</b> Yes. Cookies are small files that a site or its service provider transfers to your computer's hard drive through your Web browser (if you allow) that enables the site's or service provider's systems to recognize your browser and capture and remember certain information. For instance, we use cookies to help us remember and process the items in your shopping cart. They are also used to help us understand your preferences based on previous or current site activity, which enables us to provide you with improved services. We also use cookies to help us compile aggregate data about site traffic and site interaction so that we can offer better site experiences and tools in the future.
                <br />
                <b>We use cookies to:</b>
                <ol className='ml-10 list-disc'>
                    <li>Understand and save user's preferences for future visits.</li>
                    <li>Keep track of advertisements.</li>
                    <li>Compile aggregate data about site traffic and site interactions in order to offer better site experiences and tools in the future. We may also use trusted third party services that track this information on our behalf.</li>
                </ol>
                You can choose to have your computer warn you each time a cookie is being sent, or you can choose to turn off all cookies. You do this through your browser (like Internet Explorer) settings. Each browser is a little different, so look at your browser's Help menu to learn the correct way to modify your cookies. If you disable cookies off, some features will be disabled. It will turn off some of the features that make your site experience more efficient and some of our services will not function properly.
            </p>
            <br />
            <p>
                <b>Third Party Disclosure :</b> We do not sell, trade, or otherwise transfer to outside parties your name, address, city, town, any form or online contact identifier email, name of chat account, or any personally identifiable information without your consent. In case, we get into a partnership, which requires us to share the information with the said third party, same will be communicated to the customer very clearly, at the time of collecting the information.
            </p>
            <br />
            <p>
                <b>Policy Related to Minors :</b> heebee.in does not knowingly collect personal information from minors under the age of 18. Minors are advised not to use the heebee.in website or services, and heebee.in requests that minors under the age of 18 not submit any personal information to the website. Since information regarding minors under the age of 18 is not collected, heebee.in does not knowingly distribute personal information regarding minors under the age of 18.
            </p>
            <br />
            <p><b>How does our site handle do not track signals?</b> We honor do not track signals and do not track, plant cookies, or use advertising when a Do Not Track (DNT) browser mechanism is in place.</p>
            <br />
            <p>
                <b>Does our site allow third party behavioral tracking?</b>  It is also important to note that we allow third party behavioral tracking COPPA (Children Online Privacy Protection Act). When it comes to the collection of personal information from children under 13, the Children's Online Privacy Protection Act (COPPA) puts parents in control. The Federal Trade Commission, the nation's consumer protection agency, enforces the COPPA Rule, which spells out what operators of websites and online services must do to protect children's privacy and safety online. We do not specifically market to children under 13.
            </p>
            <br />
            <p>
                <b>Fair Information Practices :</b> The Fair Information Practices Principles form the backbone of privacy law in the United States and the concepts they include have played a significant role in the development of data protection laws around the globe. Understanding the Fair Information Practice Principles and how they should be implemented is critical to comply with the various privacy laws that protect personal information.
                <br />
                In order to be in line with Fair Information Practices we will take the following responsive action, should a data breach occur:
                <ol className='ml-10 list-disc'>
                    <li>We will notify the users via email within 1 business day.</li>
                    <li> We also agree to the individual redress principle, which requires that individuals have a right to pursue legally enforceable rights against data collectors and processors who fail to adhere to the law. This principle requires not only that individuals have enforceable rights against data users, but also that individuals have recourse to courts or a government agency to investigate and/or prosecute non-compliance by data processors.</li>
                    <li><b>Can Spam Act : </b>The CAN-SPAM Act is a law that sets the rules for commercial email, establishes requirements for commercial messages, gives recipients the right to have emails stopped from being sent to them, and spells out tough penalties for violations.</li>
                </ol>

            </p>
            <br />
            <p>
                <b>We collect your email address in order to: </b>Send information, respond to inquiries, and/or other requests or questions. Market to our mailing list or continue to send emails to our clients after the original transaction has occurred to enhance their dining experience.
                <br />
                To be in accordance with CANSPAM we agree to the following:
                <ol className='ml-10 list-disc'>
                    <li>NOT use false, or misleading subjects or email addresses</li>
                    <li>Identify the message as an advertisement in some reasonable way</li>
                    <li>Include the physical address of our business or site headquarters</li>
                    <li>Monitor third party email marketing services for compliance, if one is used.</li>
                    <li>Honour opt-out/unsubscribe requests quickly</li>
                    <li>Allow users to unsubscribe by using the link at the bottom of each email</li>
                </ol>
                <br />
                If at any time you would like to unsubscribe from receiving future emails, Follow the instructions at the bottom of each email. And we will promptly remove you from ALL correspondence.
            </p>
            <br />
            <p>
                <b>International Data Transfers : </b>When HeeBee receive any user information outside of India, we comply with providing adequate protection for the transfer of personal information. HeeBee acts as a data processor for its users based outside of India by providing data hosting, maintenance, support, and troubleshooting of services, as well as other redundant back office support. With respect to such international data transfers, we implement standard contractual clauses and other mechanisms to protect such data.
                <br />
                Individuals that use products or services offered by HeeBee may wish to raise any privacy questions directly, including filing any requests to access U.S. or European customer data or exercise other privacy rights. Any Updates on HeeBee services is communicated to users only if the individual has opted in to receive information.
            </p>
            <br />
            <p>
                <b>What does my acceptance of the privacy statement mean?</b>
                <ol className='list-disc ml-10'>
                    <li>By using HeeBee services, you signify your acceptance of this Privacy Statement.</li>
                    <li>We reserve the right, to modify this Privacy Statement at any time.</li>
                    <li>We reserve the right to terminate your access or use of service without any prior notice or take any other legal action if anyone violates these terms.</li>
                    <li>Right of admission to the website is reserved.</li>
                </ol>
            </p>
            <br />
            <p>
                <b>Privacy Policy Governance and Compliance :</b> Privacy policy is updated in accordance and users are notified of changes. Users should visit our Privacy Policy page to view any changes or updates pertaining to user information changes or compliance changes incorporated to our Privacy policy.
            </p>
            <br />
            <p>
                <b>Grievance Officer : </b>Any complaints, abuse or concerns with regards to content and or comment or breach of the terms in policy shall be immediately informed to the designated Grievance Officer as mentioned below in writing or through email signed with the electronic signature :
                <br />
                <b>Name:</b> Mrs. Ashima Batra
                <br />
                <b>Email:</b> care@heebee.in
                <br />
                <b>Address:</b> HeeBee Coffee, 48, I Block Rd, I - Block, Sarabha Nagar, Ludhiana, Punjab 141001
                <br />
                <b>Ph:</b> 78144-98149
            </p>
            <br />
            <p>
                <b>Contact Us :</b> If there are any questions regarding this privacy policy you may contact us using the information below:
                <br />
                <a href="https://www.heebee.in">www.heebee.in</a>
                <br />
                HeeBee Coffee, 48, I Block Rd, I - Block, Sarabha Nagar, Ludhiana, Punjab 141001
                <br />
                Or email to care@heebee.in
            </p>
        </div>
    )
}

export default Content