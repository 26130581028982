import React from 'react'
import Footer from '../components/common/Footer'
import Header from '../components/common/Header'
import TopBar from '../components/common/TopBar'
import Content from '../components/contact/Content'
import HeadTop from '../components/contact/HeadTop'

const Contact = () => {
  return (
    <>
      <TopBar/>
      <Header/>
      <HeadTop/>
      <Content/>
      <Footer/>
    </>
  )
}

export default Contact