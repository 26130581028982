import React, { useState, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from 'react-redux';
import DatePicker from 'react-date-picker';
import axios from "axios";
import { useAlert } from "react-alert";
import { setCustomerDetails } from "../../actions";
import { Menu, Dialog, Transition } from "@headlessui/react";
import { IoWalletOutline } from "react-icons/io5";
import makePayment from "../../fntendpaytm";
const { REACT_APP_API_ENDPOINT } = process.env;

const MyAccountRightSection = () => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const state = useSelector(state => state);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [date, setDate] = useState("");
  const [email, setEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [bgColor, setBgColor] = useState("-blue-200");
  const [amountToBeAdded, setAmountToBeAdded] = useState(0);
  useEffect(() => {
    if (state.customerDetails !== null) {
      let cd = state.customerDetails;
      setFirstName(cd.first_name);
      if (cd.last_name !== null) setLastName(cd.last_name);
      setEmail(cd.email);
      if (cd.date_of_birth !== null) {
        let dob = cd.date_of_birth;
        let d = dob.split("-");
        d = new Date(d[0], d[1] - 1, d[2]);
        setDate(d);
      }
      switch (cd.customer_role.customer_type) {
        case "Gold": setBgColor("-yellow-500"); break;
        case "Silver": setBgColor("-grey-500"); break;
        case "Platinum": setBgColor("-grey-300"); break;
        case "General": setBgColor("-blue-500"); break;
        default: setBgColor("-blue-500");
      }
    }
  }, [state.customerDetails])

  const pay = () => {
    if (isNaN(amountToBeAdded) === false && amountToBeAdded > 0) {
      const body = {
        amount: Number(amountToBeAdded),
        branch_id: state.currentBranch.branch_id,
        branch_name: state.currentBranch.branch_name
      };
      const token = state.token;
      makePayment(body, token);
    }
    else {
      alert.error("Invalid Amount !")
    }
  }

  const [isAddMoneyOpen, setIsAddMoneyOpen] = useState(false);
  const [addingMoney, setAddingMoney] = useState(0);
  const [disabled, setDisabled] = useState(true);

  const closeAddMoneyModal = () => {
    setIsAddMoneyOpen(false);
    setAddingMoney(0);
  }

  useEffect(() => {
    if (addingMoney > 0) setDisabled(false);
    else setDisabled(true);
  }, [addingMoney])


  const saveDetails = () => {
    let user = {
      mobile_no: Number(state.customerDetails.mobile_no),
      first_name: firstName,
      last_name: lastName,
      date_of_birth: `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
      email: email
    };

    axios.post(`${REACT_APP_API_ENDPOINT}/edit_profile`, user, {
      headers: {
        token: state.token
      }
    })
      .then(res => {
        if (res.data.status === "success") {
          alert.success("Profile has been updated successfully");
          dispatch(setCustomerDetails(state.token));
        }
        else
          alert.error(res.data.msg)
      })
      .catch(() => alert.error("error occured"));
  }
  const changePassword = () => {
    if (newPassword === "" || confirmPassword === "") alert.error("Passsords can't be empty !");
    else if (newPassword === confirmPassword) {
      let user = {
        mobile_no: Number(state.customerDetails.mobile_no),
        password: newPassword
      };
      axios.post(`${REACT_APP_API_ENDPOINT}/change_password`, user, {
        headers: {
          token: state.token
        }
      })
        .then(res => {
          if (res.data.status === "success") {
            alert.success("Password has been updated successfully");
            setNewPassword("");
            setConfirmPassword("");
            dispatch(setCustomerDetails(state.token));
          }
          else
            alert.error(res.data.msg)
        })
        .catch(() => alert.error("error occured"));
    }
    else {
      alert.error("Passsords don't match !")
    }
  }
  const changeDate = (e) => {
    setDate(e);
  }
  return (
    <>

      <div className="basis-full mt-2 md:mt-0 md:basis-5/6 animate__animated animate__fadeInRight px-2 md:px-2 md:pr-2 right-section">
        <div className="px-4">
          <div className="text-primary-dark-color text-xl font-bold tracking-wide">Membership & Wallet</div>
          <div className="flex justify-around items-stretch my-2 flex-wrap">
            <div className={`bg${bgColor} w-full md:w-[22%] text-black rounded-lg flex flex-col justify-center`}>
              <div className={`font-bold px-3 py-1`}>Account Type : {state.customerDetails !== null && state.customerDetails.customer_type}</div>
              <div className={`font-bold px-3 py-1`}>Days Left : {state.customerDetails !== null && state.customerDetails.memb_days_left}</div>
              {state.customerDetails !== null && state.customerDetails.memb_reduce_amount !== null && <div className={`font-bold px-3 py-1`}>Reduce Amount : ₹{(state.customerDetails.memb_reduce_amount).toFixed(2)}</div>}
            </div>
            <div className="w-full md:w-[21%] bg-primary-dark-color flex justify-center flex-col items-center rounded-lg text-light-color">
              <div className="flex justify-center items-center text-xl font-bold mb-2">Wallet Balance <IoWalletOutline className="w-6 h-6 ml-3" /></div>
              <div className="text-xl font-bold">₹{state.customerDetails?.wallet_balance}</div>
            </div>
            <div className={`bg-primary-dark-color w-full md:w-[55%] text-light-color rounded-lg flex justify-around items-center px-6 py-2`}>
              <div className="w-full flex-shrink mr-4">
                <div className="text-xl font-bold">Add Money to Wallet</div>
                <input type="number" className="outline-none rounded-md p-2 w-full mb-2 mt-1 text-black" value={amountToBeAdded} onChange={(e) => setAmountToBeAdded(e.target.value)} />
                <div className="flex justify-evenly">
                  <div className="bg-black text-white rounded-md px-3 mx-1 cursor-pointer" onClick={() => setAmountToBeAdded(a => a + 50)}>50</div>
                  <div className="bg-black text-white rounded-md px-3 mx-1 cursor-pointer" onClick={() => setAmountToBeAdded(a => a + 100)}>100</div>
                  <div className="bg-black text-white rounded-md px-3 mx-1 cursor-pointer" onClick={() => setAmountToBeAdded(a => a + 500)}>500</div>
                  <div className="bg-black text-white rounded-md px-3 mx-1 cursor-pointer" onClick={() => setAmountToBeAdded(a => a + 1000)}>1000</div>
                  <div className="bg-black text-white rounded-md px-3 mx-1 cursor-pointer" onClick={() => setAmountToBeAdded(a => a + 2000)}>2000</div>
                </div>
              </div>
              <div className="whitespace-nowrap bg-white text-black px-3 py-2 rounded-lg border-2 border-black cursor-pointer" onClick={() => pay()}>Proceed to Pay</div>
            </div>
          </div>
          <div className="text-primary-dark-color text-xl font-bold tracking-wide mt-8">Edit Profile</div>
          <div className="flex flex-wrap justify-between">
            <div className="w-[49%] my-1">
              <label
                htmlFor="first_name"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                First name
              </label>
              <input
                type="text"
                name="first_name"
                id="first_name"
                autoComplete="given-name"
                className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-primary-light-color bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-primary-light-color focus:border-[#824a19] mb-3 focus:outline-none"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            <div className="w-[49%] my-1">
              <label
                htmlFor="last_name"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Last name
              </label>
              <input
                type="text"
                name="last_name"
                id="last_name"
                autoComplete="family-name"
                className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-primary-light-color bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-primary-light-color focus:border-[#824a19] mb-3 focus:outline-none"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            <div className="w-[49%] my-1">
              <label
                htmlFor="email_address"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Email address
              </label>
              <input
                type="text"
                name="email_address"
                id="email_address"
                autoComplete="email"
                className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-primary-light-color bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-primary-light-color focus:border-[#824a19] mb-3 focus:outline-none"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="w-[49%] my-1">
              <div className="datepicker relative form-floating mb-3 ">
                <label
                  htmlFor="floatingInput"
                  className="text-gray-700 block"
                  data-mdb-toggle="datepicker"
                >
                  Date of Birth
                </label>
                <DatePicker className="text-dark-color" yearPlaceholder="YYYY" clearIcon={null} monthPlaceholder="MM" dayPlaceholder="DD" dayAriaLabel="Day" showLeadingZeros={true} maxDate={new Date()} format="dd-MM-yyyy" value={date} onChange={(e) => changeDate(e)} disabled={true} />
              </div>
            </div>
            <div className="w-full flex justify-center">
              <button className="bg-primary-dark-color text-light-color font-bold px-3 py-2 text-lg rounded-md hover:opacity-[90%]" onClick={saveDetails}>Update Profile</button>
            </div>
          </div>
          <div className="text-primary-dark-color text-xl font-bold tracking-wide mt-8">Password</div>
          <div className="flex flex-wrap justify-between">
            <div className="w-[49%] my-1">
              <label
                htmlFor="new_password"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                New Password
              </label>
              <input
                type="password"
                name="new_password"
                className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-primary-light-color bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-primary-light-color focus:border-[#824a19] mb-3 focus:outline-none"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </div>
            <div className="w-[49%] my-1">
              <label
                htmlFor="confirm_password"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Confirm Passsword
              </label>
              <input
                type="password"
                name="email_address"
                autoComplete="email"
                className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-primary-light-color bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-primary-light-color focus:border-[#824a19] mb-3 focus:outline-none"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
            <div className="w-full flex justify-center">
              <button className="bg-primary-dark-color text-light-color font-bold px-3 py-2 text-lg rounded-md hover:opacity-[90%]" onClick={changePassword}>Change Password</button>
            </div>
          </div>
        </div>
        {/* <div className="flex justify-start align-center flex-wrap">
          <div className={`h-[60px] flex px-[10px] items-center text-center m-1 text-sm text-black w-fit bg${bgColor} font-bold border-radius-5`}>Account Type<br />{state.customerDetails !== null && state.customerDetails.customer_type}</div>
          <div className={`h-[60px] flex px-[10px] items-center text-center m-1 text-sm text-dark-color w-fit bg-product font-bold border-radius-5`}>Days Left<br />{state.customerDetails !== null && state.customerDetails.memb_days_left}</div>
          {state.customerDetails !== null && state.customerDetails.memb_reduce_amount !== null && <div className={`h-[60px] flex px-[10px] border-radius-5 items-center text-center m-1 text-sm text-dark-color w-fit bg-product font-bold`}>Reduce Amount<br />₹{state.customerDetails.memb_reduce_amount}</div>}
          {state.customerDetails !== null && <div className={`h-[60px] cursor-pointer flex px-[10px] border-radius-5 items-center text-center m-1 text-sm text-dark-color w-fit bg-product font-bold`} onClick={() => setIsAddMoneyOpen(true)}>Wallet Balance<br />₹{state.customerDetails.wallet_balance}</div>}
      </div> */}


        {/* <div className="flex flex-col md:flex-row w-full mt-5 border-radius-10">
          <div className="mt-10 sm:mt-0 w-full flex flex-col md:flex-row border-radius-10">
            <div className=" basis-1/3">
              <div className="px-4 sm:px-0 md:m-3">
                <h3 className="text-lg font-medium leading-6 text-primary-dark-color">
                  Personal Information
                </h3>
                <p className="mt-1 text-sm text-gray-600">
                  Use a permanent address where you can receive mail.
                </p>
              </div>
            </div>
            <div className="mt-5 md:mt-0 basis-2/3 bg-primary-light-color border-radius-10">
              <div className="shadow overflow-hidden sm:rounded-md">
                <div className="px-4 py-5 bg-primary-light-color sm:p-6">
                  <div className="">
                    <div className="">
                      <label
                        htmlFor="first_name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        First name
                      </label>
                      <input
                        type="text"
                        name="first_name"
                        id="first_name"
                        autoComplete="given-name"
                        className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-primary-light-color bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-primary-light-color focus:border-[#824a19] mb-3 focus:outline-none"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </div>

                    <div className="">
                      <label
                        htmlFor="last_name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Last name
                      </label>
                      <input
                        type="text"
                        name="last_name"
                        id="last_name"
                        autoComplete="family-name"
                        className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-primary-light-color bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-primary-light-color focus:border-[#824a19] mb-3 focus:outline-none"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </div>

                    <div className="flex items-center justify-start ">
                      <div className="datepicker relative form-floating mb-3 ">
                        <label
                          htmlFor="floatingInput"
                          className="text-gray-700 block"
                          data-mdb-toggle="datepicker"
                        >
                          Date of Birth
                        </label>
                        <DatePicker className="text-dark-color" yearPlaceholder="YYYY" clearIcon={null} monthPlaceholder="MM" dayPlaceholder="DD" dayAriaLabel="Day" showLeadingZeros={true} maxDate={new Date()} format="dd-MM-yyyy" value={date} onChange={(e) => changeDate(e)} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="px-4 py-3 bg-primary-light-color text-right sm:px-6">
                  <button
                    type="submit"
                    className="inline-flex justify-center py-2 px-8 border border-transparent shadow-sm text-sm font-medium  text-white hover:bg-[#003a1b] bg-[#003a1b]/40      focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 border-radius-5"
                    onClick={saveDetails}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="border-radius-10">
          <div className="mt-5 border-radius-10">
            <div className=" flex flex-col md:flex-row border-radius-10">
              <div className="basis-1/3">
                <div className="px-4 sm:px-0 md:m-3">
                  <h3 className="text-lg font-medium leading-6 text-primary-dark-color">
                    Email Address
                  </h3>
                  <p className="mt-1 text-sm text-gray-600">
                    Use a permanent address where you can receive mail.
                  </p>
                </div>
              </div>
              <div className="mt-5 md:mt-0 basis-2/3 bg-primary-light-color border-radius-10">
                <div className="shadow overflow-hidden sm:rounded-md">
                  <div className="px-4 py-5 bg-primary-light-color sm:p-6">
                    <div className="">
                      <div className="">
                        <label
                          htmlFor="email_address"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Email address
                        </label>
                        <input
                          type="text"
                          name="email_address"
                          id="email_address"
                          autoComplete="email"
                          className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-primary-light-color bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-primary-light-color focus:border-[#824a19] mb-3 focus:outline-none"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="px-4 py-3 bg-primary-light-color text-right sm:px-6">
                    <button
                      type="submit"
                      className="inline-flex justify-center py-2 px-8 border border-transparent shadow-sm text-sm font-medium  text-white hover:bg-[#003a1b] bg-[#003a1b]/40      focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 border-radius-5"
                      onClick={changeEmail}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-row w-full mt-5 border-radius-10">
          <div className="mt-10 sm:mt-0 w-full border-radius-10">
            <div className=" flex flex-col md:flex-row border-radius-10">
              <div className=" basis-1/3">
                <div className="px-4 sm:px-0 md:m-3">
                  <h3 className="text-lg font-medium leading-6 text-primary-dark-color">
                    Password
                  </h3>
                  <p className="mt-1 text-sm text-gray-600">
                    Use a permanent address where you can receive mail.
                  </p>
                </div>
              </div>
              <div className="mt-5 md:mt-0 basis-2/3 bg-primary-light-color border-radius-10">
                <div className="shadow overflow-hidden sm:rounded-md">
                  <div className="px-4 py-5 bg-primary-light-color sm:p-6">
                    <div className="">
                      <label
                        htmlFor="email_address"
                        className="block text-sm font-medium text-gray-700"
                      >
                        New Password
                      </label>
                      <input
                        type="password"
                        name="email_address"
                        autoComplete="email"
                        className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-primary-light-color bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-primary-light-color focus:border-[#824a19] mb-3 focus:outline-none"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                      />
                    </div>
                    <div className="">
                      <label
                        htmlFor="email_address"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Confirm Password
                      </label>
                      <input
                        type="password"
                        name="email_address"
                        autoComplete="email"
                        className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-primary-light-color bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-primary-light-color focus:border-[#824a19] mb-3 focus:outline-none"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="px-4 py-3 bg-primary-light-color text-right sm:px-6">
                    <button
                      type="submit"
                      className="inline-flex justify-center py-2 px-8 border border-transparent shadow-sm text-sm font-medium  text-white hover:bg-[#003a1b] bg-[#003a1b]/40           focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 border-radius-5"
                      onClick={changePassword}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>

      <Transition appear show={isAddMoneyOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeAddMoneyModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto text-dark-color border-radius-5">
            <div className="flex min-h-full items-center justify-center text-center border-radius-5">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-fit transform overflow-hidden bg-product transition-all border-radius-5 px-10 py-2">
                  <div className="text-2xl uppercase font-bold">Add Credit</div>
                  <div className="my-5">
                    <input type="number" className="text-black p-1 outline-none border-2 border-grey-400 rounded-md text-sm" onChange={(e) => setAddingMoney(e.target.value)} value={addingMoney} />
                  </div>
                  <div className="mb-[10px]">
                    <button className="rounded-sm uppercase mx-2 bg-grey-300 px-2 py-1 text-grey-800 font-semibold text-sm" onClick={closeAddMoneyModal}>Close</button>
                    <button className={`rounded-sm uppercase mx-2 text-sm px-2 py-1 ${disabled ? `bg-honey ${state.theme === 'dark' ? 'text-grey-300' : 'text-grey-700'} cursor-not-allowed` : 'bg-primary-dark-color text-light-color cursor-pointer'}`}>Add Credit</button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default MyAccountRightSection;
