import React from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from 'react-redux';
import ProfileImg from '../assets/images/profile.png'
import { logout } from "../actions";
import MyAccountButton from "./MyAccountButton";
import { useEffect } from "react";
import { useState } from "react";

const LeftBar = ({ orderId }) => {
  const params = window.location.pathname;
  const state = useSelector(state => state);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [image, setImage] = useState(ProfileImg);

  const customerLogout = () => {
    dispatch(logout());
    navigate('/login');
  }
  useEffect(() => {
    if (state.customerDetails?.profile_pic) {
      setImage(state.customerDetails.profile_pic);
    }
  }, [state.customerDetails])
  return (

    <>
      {/* <div className="basis-full md:basis-1/4 rounded-[10px] mx-2 bg-footer sidediv relative py-5 mb-10">
      <div className="flex flex-col justify-center items-center mb-4">
        <div className="border-2 border-dashed border-primary-color rounded-full p-1">
          <img src={ProfileImg} alt="img" className="w-[50px] h-[50px] rounded-full" />
        </div>
        <h4 className="font-bold text-lg text-primary-dark-color">{state.customerDetails !== null && state.customerDetails.first_name} {state.customerDetails !== null && state.customerDetails.last_name}</h4>
        <div className={`${state.customerDetails !== null &&
          (state.customerDetails.customer_type === 'general' ? `bg-red-50` :
            state.customerDetails.customer_type === 'silver' ? `bg-red-500` :
              state.customerDetails.customer_type === 'gold' ? `bg-red-500` :
                state.customerDetails.customer_type === 'Platinum' ? `bg-red-500` :
                  null)} text-xs uppercase font-bold text-white px-3 py-1 rounded-xl`}>{state.customerDetails !== null && state.customerDetails.customer_type}</div>
      </div>
      <div className="flex flex-col w-full justify-start cursor-pointer">
        <button
          className={`text-left font-roboto  font-bold text-xl mx-2 mt-2 py-2 px-3 border-radius-5 disabled:cursor-not-allowed  ${params === "/my-profile" || params === `/my-profile`
            ? "text-light-color bg-primary-dark-color"
            : "text-primary-dark-color "
            }`}
          onClick={() => { navigate("/my-profile"); }}
          disabled={
            params === "/my-profile"
              ? true
              : false
          }
        >
          My Profile
        </button>
        <button
          className={`text-left font-roboto  font-bold text-xl mx-2 mt-2 py-2 px-3 border-radius-5 disabled:cursor-not-allowed  ${params === "/my-orders" || params === `/my-orders/${orderId}`
            ? "text-light-color bg-primary-dark-color"
            : "text-primary-dark-color "
            }`}
          onClick={() => {
            navigate('../my-orders');
          }}
          disabled={
            params === "/my-orders"
              ? true
              : false
          }
        >
          My Orders
        </button>
        <button
          className={`text-left font-roboto  font-bold text-xl mx-2 mt-2 py-2 px-3 border-radius-5 disabled:cursor-not-allowed  ${params === "/edit-profile"
            ? "text-light-color bg-primary-dark-color"
            : "text-primary-dark-color "
            }`}
          onClick={() => { navigate("/edit-profile"); }}
          disabled={params === "/edit-profile" ? true : false}
        >
          Edit Profile
        </button>
        <button
          className={`text-left font-roboto  font-bold text-xl mx-2 mt-2 py-2 px-3 border-radius-5 disabled:cursor-not-allowed  ${params === "/change-password"
            ? "text-light-color bg-primary-dark-color"
            : "text-primary-dark-color "
            }`}
          onClick={() => { navigate("/change-password"); }}
          disabled={params === "/change-password" ? true : false}
        >
          Change Password
        </button>
        <button
          className={` font-roboto  font-bold text-xl mx-2 mt-2 py-2 px-3 border-radius-5 bg-red-400 text-center `}
          onClick={() => { customerLogout(); }}
        >
          Logout
        </button>
      </div>
    </div> */}
      <div className="flex flex-col md:flex-row justify-center items-center gap-4 mb-8">
        <div className="flex flex-col justify-center items-end gap-2">
          <img src={image} alt="profile" className="w-[60px] h-[60px] rounded-full" />
          <h6 className="text-xl font-bold">{state.customerDetails?.customer_type}</h6>
        </div>
        <div className=" flex flex-col justify-center items-start gap-2">
          <h3 className="text-5xl font-bold text-primary-dark-color text-center">Welcome Back {state.customerDetails?.first_name}</h3>
          <MyAccountButton />
        </div>
      </div>
    </>
  );
};

export default LeftBar;
