import React, { useState } from "react";
import SingleProductCard from "./SingleProductCard";
import { useSelector } from 'react-redux';
import { useParams } from "react-router";
import axios from 'axios';
import { Link } from "react-router-dom";
import { HiChevronLeft } from "react-icons/hi";

const { REACT_APP_API_ENDPOINT } = process.env;

const SingleOrderRightSection = () => {
  const { orderId } = useParams();
  const state = useSelector(state => state);
  let [order, setOrder] = useState(null);
  if (order === null && state.token !== null) {
    axios.get(`${REACT_APP_API_ENDPOINT}/fetch_single_order?order=${orderId}`, {
      headers: {
        token: state.token
      }
    })
      .then(res => {
        setOrder(res.data.data);
      });
  }
  return (
    <>
      {order !== null &&
        <div className=" flex flex-col w-full md:max-w-[1000px]  px-4 right-section bg-white/10 p-4 rounded-[10px]">
          <div className="bg-product text-center text-primary-dark-color px-2 py-2 rounded-[10px] font-sans w-full text-2xl font-semibold tracking-wider leading-7">

            <div className="flex justify-between items-center">
              <Link to="/my-orders" className="rounded-[10px] p-2 bg-white/10"><HiChevronLeft className="h-5 w-5" /></Link>
              <span>ORDER ID: {orderId}</span>
            </div>
          </div>
          <div className="bg-product rounded-[10px] my-2 px-4 py-2 font-sans font-semibold text-xl capitalize">
            {order.address}
          </div>
          <div className="flex flex-col md:flex-row gap-2">
            <div className="flex flex-col w-full md:w-1/2 px-4 py-2 font-sans bg-product rounded-[10px]  text-primary-dark-color">
              <p className="font-bold">Payment Method</p>
              <p className="capitalize">{order.payment_method}</p>
            </div>
            <div className="flex flex-col w-full md:w-1/2 px-4 py-2 font-sans bg-product rounded-[10px]  text-primary-dark-color">
              <p className="font-bold">Order Type</p>
              <p className="capitalize">{order.order_type}</p>
            </div>
          </div>
          <div>
            {order.order_items.map((item, index) => <SingleProductCard key={index} item={item} />)}
          </div>
          <div className="flex flex-row justify-between bg-product rounded-[10px] px-4 py-2 font-sans text-xl leading-8">
            <div className="flex flex-col items-start font-bold text-primary-dark-color">
              <div>Sub Total</div>
              <div>Discount</div>
              <div>Membership Discount</div>
              <div>SGST</div>
              <div>CGST</div>
              <div>Grand Total</div>
            </div>
            <div className="flex flex-col items-end">
              <div>₹ {order.sub_total}</div>
              <div>₹ {order.discount}</div>
              <div>₹ {parseFloat(order.membership_discount)}</div>
              <div>₹ {order.sgst}</div>
              <div>₹ {order.cgst}</div>
              <div>₹ {order.paid_price}</div>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default SingleOrderRightSection;
