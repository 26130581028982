import axios from 'axios';
import React from 'react'
import { useAlert } from 'react-alert';
import { useDispatch, useSelector } from 'react-redux';
import { setPromoCode } from '../../actions';

const { REACT_APP_API_ENDPOINT } = process.env;

const PromoCode = ({ item, addingMoney }) => {
    const alert = useAlert();
    const dispatch = useDispatch();

    const state = useSelector(state => state);
    // console.log(state.promoCode.promo_code_id)

    const applyHandler = () => {

        let data = {
            promo_code_id: item.promo_code_id,
            amount: addingMoney
        };
        if(addingMoney === '') { alert.error('Please enter a Amount')} else {
            axios.post(`${REACT_APP_API_ENDPOINT}/apply_promo_code`, data, {
                headers: {
                    token: state.token
                }
            })
                .then(res => {
                    if (res.data.status === "success") {
                        alert.success("Promo Code Applied Successfully");
                        dispatch(setPromoCode(res.data.data));

                    }
                    else
                        alert.error(res.data.msg)
                })
                .catch(() => {
                    alert.error("error occured")
                });
            }
    }

    return (
        <div className="flex justify-between items-center bg-white/50 p-2 rounded-lg">
            <div>
                <h3 className="font-semibold text-md text-left mb-0 leading-tight">{item.title}</h3>
                <h3 className="opacity-50 text-xs text-left">Min. Amount ₹{item.min_amount}</h3>
                <h3 className="opacity-50 text-xs text-left">Extra Amount ₹{item.extra_amount}</h3>
            </div>
            <button className="border-radius-5 text-[14px] font-semibold px-[12px] py-[4px] cursor-pointer text-light-color bg-primary-dark-color" onClick={applyHandler}>Apply</button>
        </div>
    )
}

export default PromoCode