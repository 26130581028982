const SET_THEME = "SET_THEME";
const SET_LOGIN = "SET_LOGIN";
const SET_CUSTOMER_DETAILS = "SET_CUSTOMER_DETAILS";
const LOGOUT = "LOGOUT";
const GET_BRANCHES = "GET_BRANCHES";
const CURRENT_BRANCH = "CURRENT_BRANCH";
const GET_CATEGORIES = "GET_CATEGORIES";
const GET_PRODUCTS = "GET_PRODUCTS";
const BEST_SELLER = "BEST_SELLER";
const MOST_ORDER_ITEMS = "MOST_ORDER_ITEMS";
const SET_QUERY = "SET_QUERY";
const UPDATE_PRODUCTS = "UPDATE_PRODUCTS";
const SET_CART_ITEMS = "SET_CART_ITEMS";
const UPDATE_CART = "UPDATE_CART";
const PICKUP_TIME = "PICKUP_TIME";
const GET_COUPONS = "GET_COUPONS";
const FETCH_ALL_ORDERS = "FETCH_ALL_ORDERS";
const UNCLAIM_GIFTCARD = "UNCLAIM_GIFTCARD";
const CLAIMED_GIFTCARD = "CLAIMED_GIFTCARD";
const SENT_GIFTCARD = "SENT_GIFTCARD";
const ADD_NEW_GIFTCARD = "ADD_NEW_GIFTCARD";
const GET_PROMO_CODE = "GET_PROMO_CODE";
const SET_PROMO_CODE = "SET_PROMO_CODE";
const CUSTOMER_ROLES = 'CUSTOMER_ROLES'
const CURRENT_COUPON = 'CURRENT_COUPON'

export { SET_THEME, SET_LOGIN, SET_CUSTOMER_DETAILS, LOGOUT, GET_BRANCHES, CURRENT_BRANCH, GET_CATEGORIES, GET_PRODUCTS, BEST_SELLER, SET_QUERY, UPDATE_PRODUCTS, SET_CART_ITEMS, UPDATE_CART, PICKUP_TIME, GET_COUPONS, FETCH_ALL_ORDERS, MOST_ORDER_ITEMS, UNCLAIM_GIFTCARD, CLAIMED_GIFTCARD, SENT_GIFTCARD, ADD_NEW_GIFTCARD, GET_PROMO_CODE, SET_PROMO_CODE, CUSTOMER_ROLES, CURRENT_COUPON };