import React, { useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import { useAlert } from "react-alert";
import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import { useDispatch, useSelector } from 'react-redux';
import { setCartItems } from '../../../actions';
import { ExclamationIcon } from "@heroicons/react/outline";


const Product = ({ product, coupon }) => {
    const state = useSelector(state => state);
    const theme = state.theme;
    const alert = useAlert();
    const dispatch = useDispatch();
    let [isOpen, setIsOpen] = useState(false);
    const [alertAddOn, setAlertAddOn] = useState([]);
    const [requiredAddOns, setRequiredAddOns] = useState([]);

    // ! filter Add Ons
    const [filteredAddOns, setFilteredAddOns] = useState([]);

    useEffect(() => {
        if(Array.isArray(product.product_list.per_product_add_ons)){
            let addOns = product.product_list.per_product_add_ons;
            setFilteredAddOns(addOns.sort((a, b) => b.order - a.order))
        }else
            setFilteredAddOns(product.product_list.per_product_add_ons)
    }, [product])

    useEffect(() => {
        let add = product?.product_list?.per_product_add_ons;
        let raos = [];
        add.forEach(aos => {
            if (aos.required) raos.push(aos.add_on.title);
        })
        setRequiredAddOns(raos);
    }, [product]);
    function closeModal() {
        setAddons([]);
        setAddOnPrice(0);
        setQuantity(1);
        setNote('');
        setIsOpen(false);
        setAlertAddOn([]);
    }

    function openModal() {
        setIsOpen(true)
    }

    const [addons, setAddons] = useState([]);
    const [addOnPrice, setAddOnPrice] = useState(0);
    const [note, setNote] = useState('');
    const [quantity, setQuantity] = useState(1);

    const makeAddOn = (title, value, type, option) => {
        // console.log(title);
        // console.log(value);
        // console.log(type);
        // console.log(option);
        let aos = [...addons];
        let index_title = -1;
        let index_value = -1;

        // addons = [
        //     {title, value, price, sku}
        // ]

        // addons = {
        //     title : [
        //         {
        //             value : 
        //             price :
        //             sku :
        //         }
        //     ]
        // }

        aos.forEach((ao, i) => {
            if (ao.title === title) {
                index_title = i;
                if (ao.value === value) {
                    index_value = i;
                }
            }
        })
        // console.log(index_value);
        if (index_title > -1) {
            if (type === "radio") {
                aos.splice(index_title, 1);
                aos.push({ title: title, value: value, price: option.price, sku: option.sku });
            }
            else {
                if (index_value > -1) {
                    aos.splice(index_value, 1);
                }
                else {
                    aos.push({ title: title, value: value, price: option.price, sku: option.sku });
                }
            }
        }
        else {
            aos.push({ title: title, value: value, price: option.price, sku: option.sku });
        }
        setAddons(aos);
        // let aos = addons, alreadyIn = false;;
        // aos.forEach(ao => {
        //     if (ao.title === title) {
        //         let p = extraAmount - Number(ao.option.price) + Number(option.price);
        //         setExtraAmount(p);
        //         alreadyIn = true;
        //         ao.option = option;
        //     }
        // })
        // if (alreadyIn === false) {
        //     aos = [...aos, {
        //         title: title,
        //         option: option
        //     }]
        //     let p = extraAmount + Number(option.price);
        //     setExtraAmount(p);
        // }
        // setAddons(aos);
    }

    useEffect(() => {
        let p = 0;
        addons.forEach((ao) => p += ao.price);
        setAddOnPrice(p);
    }, [addons])

    let confirmOrder = (order) => {
        const prevCart = state.cartItems;
        let returnValue = false;
        prevCart.forEach(cartItem => {
            if (returnValue !== true && (cartItem.sku === order.sku)) {
                returnValue = true;
            }
        })
        return returnValue;
    }
    const check = (adds) => {
        let selectedaddOns = Object.keys(adds);
        let extra = [];
        product.product_list.per_product_add_ons.forEach(addon => extra.push(addon.add_on.title));
        let return_value = true;
        for (let i = 0; i < extra.length; i++) {
            if (requiredAddOns.includes(extra[i])) {
                if (selectedaddOns.includes(extra[i]) === false) {
                    if (alertAddOn.includes(extra[i]) === false) setAlertAddOn(x => [...x, extra[i]]);
                    alert.error(`Please pick a choice from ${extra[i]}`);
                    return_value = false;
                }
                else {
                    let x = alertAddOn;
                    let index = x.indexOf(extra[i]);
                    if (index > -1) {
                        x.splice(index, 1);
                    }
                    setAlertAddOn(x);
                }
            }
        }
        return return_value;
    }
    function dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    const addToCart = (product) => {
        let sku = product.product_list.sku;
        let aos = [...addons];
        aos.sort(dynamicSort("sku"));
        aos.forEach((ao) => {
            sku += "-" + ao.sku;
        });
        let xaddons = arrayToObj(aos);
        let checkval = check(xaddons);
        if (checkval) {
            let order = {
                product_name: product.product_list.product_name,
                quantity: quantity,
                note: note,
                amount: (product.product_list.price + product.price + addOnPrice) * quantity,
                product: product,
                addOns: xaddons,
                img: product.product_list.card_img,
                index: state.cartItems.length,
                sku: sku,
                coupon: (coupon.usedCoupon ? coupon.coupon : null)
            };
            let couponUsed = false;
            if (coupon.usedCoupon) {
                if (state.currentCoupon !== null) couponUsed = true;
                else couponUsed = false;
            }
            if (couponUsed) alert.error("Coupon is already used !");
            else {
                let amount = 0;
                state.cartItems.forEach((i) => {
                    if (i.coupon === null) amount += i.amount;
                });
                if (coupon.usedCoupon && amount < coupon.coupon.min_cart_value) alert.error('Cart Value is less than required !');
                else {
                    let confirmed = confirmOrder(order);
                    if (confirmed) alert.error("Product is already in cart");
                    else {
                        let cart = JSON.parse(window.localStorage.getItem('heebee-cart-items'));
                        let x = cart;
                        x = [...x, order];
                        window.localStorage.setItem('heebee-cart-items', JSON.stringify(x));
                        closeModal();
                        dispatch(setCartItems(x));
                    }
                }
            }
        }
        // let extra = [];
        // addons.forEach(ao => {
        //     extra = [...extra, {
        //         title: ao.title,
        //         desc: ao.option.title,
        //         price: ao.option.price
        //     }];
        // })
        // extra.sort(dynamicSort("title"));
        // if (confirmed !== true) {
        //     let cart = JSON.parse(window.localStorage.getItem('heebee-cart-items'));
        //     let x = cart;
        //     x = [...x, order];
        //     window.localStorage.setItem('heebee-cart-items', JSON.stringify(x));
        //     closeModal();
        //     dispatch(setCartItems(x));
        // }
        // else {
        //     alert.error("Product is already in cart");
        // }
    }
    const newObject = (obj) => {
        return [obj.title, { value: obj.value, price: obj.price, sku: obj.sku }];
    }
    const arrayToObj = (arr) => {
        let xAddOn = {};
        arr.forEach(el => {
            let object = newObject(el);
            if (xAddOn.hasOwnProperty(object[0]) === false) {
                xAddOn[object[0]] = [object[1]];
            }
            else {
                let x = xAddOn[object[0]];
                x.push(object[1]);
            }
        })
        return xAddOn;
    }

    return (
        <>
            <div className='product-card p-4 animate__animated animate__fadeIn w-[100%] bg-product border-radius-10 cursor-pointer mt-[70px]'  onClick={openModal}>
                <div className='w-[100%] pt-[100%] mt-[-70px] relative'>
                    <div className='absolute top-0 left-0 right-0 bottom-0 w-full h-full'>
                        <div className={`product-card-img w-[100%] aspect-square overflow-hidden mb-1 border-primary-light-color rounded-xl`}>
                            <div className='h-full w-full flex justify-center items-center overflow-hidden'><img src={product.product_list.card_img} alt='product' className="h-[100%] max-w-none" /></div>
                            <div className="product-card-img-overlay flex justify-center items-center">
                                <button className='bg-primary-light-color text-primary-dark-color px-3 py-1.5 uppercase font-bold font-roboto border-radius-5'>Customize</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='font-bold text-md leading-tight line-clamp-2 mt-2'>
                    <h6 className='font-montserrat mt-1'>{product.product_list.product_name}</h6>
                    <p className={`text-[10px] font-thin my-[2px] ${state.theme === 'dark' ? 'text-grey-400' : 'text-grey-700'}`}>{product.product_list.description.length > 50 ? product.product_list.description.substr(0, 50) + '...' : product.product_list.description}</p>
                </div>
                <div className='flex justify-between px-1 items-center mt-2'>
                    <div className='text-base font-medium'> &#8377; {product.product_list.price + product.price} /-</div>
                    <div className='w-[14px] h-[14px] rounded-full overflow-hidden mr-3'><div className={`w-[200%] h-[200%] ${product.product_list.food_type === "Veg" ? 'bg-green-500' : 'bg-red-500'}`}></div></div>
                </div>
            </div>

            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto text-dark-color border-radius-5">
                        <div className="flex min-h-full items-center justify-center text-center border-radius-5">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-fit transform overflow-hidden bg-product transition-all border-radius-10 select-none">
                                    <div className="flex w-fit max-w-fit md:max-w-full flex-wrap justify-center overflow-hidden p-[10px]">

                                        {/* <!-- Image and Product Title --> */}
                                        <div className="w-[250px] flex flex-row md:flex-col mb-[10px] md:mb-0 md:mr-[10px]">
                                            <div className='w-[120px] h-[80px] md:w-full md:h-[250px] mr-[10px]  md:mb-[5px] flex justify-center items-center overflow-hidden border-radius-5'>
                                                <img src={product.product_list.card_img} className="h-full max-w-none border-primary-light-color rounded-tl-sm .border-radius-5" alt="" />
                                            </div>
                                            <div className="text-primary-dark-color text-left font-extrabold w-full flex justify-between items-center">
                                                <div>
                                                    <div className='capitalize' >
                                                        {product.product_list.product_name}
                                                    </div>
                                                    {/* <div className='text-[8px] italic md:text-[10px] my-[5px]' >
                                                    {product.product_list.description}
                                                </div> */}
                                                    <div className='flex justify-between items-center' >
                                                        <div className='text-grey-500 text-[10px] font-semibold'>Items Available : {product.items_available}</div>
                                                        {/* <div className='text-right w-fit whitespace-nowrap'>&#8377; {(product.product_list.price + product.price + addOnPrice) * quantity}</div> */}
                                                    </div>
                                                </div>

                                                <div className='text-right text-primary-dark-color w-fit whitespace-nowrap text-[18px]'>&#8377; {(product.product_list.price + product.price + addOnPrice) * quantity}</div>
                                            </div>
                                            {/* <div className='md:w-full text-[8px] italic md:text-[10px] text-left mb-2 md:mb-0 md:mr-[2.5px]'>{product.product_list.description}</div> */}
                                            {/* <div className='text-right w-fit whitespace-nowrap'>&#8377; {(product.product_list.price + product.price + addOnPrice) * quantity}</div> */}

                                        </div>

                                        {/* <!-- Accordion, Text Area and Buttons --> */}
                                        <div className="w-[250px] flex flex-col justify-between">
                                            <div className='min-h-[250px] max-h-[250px] overflow-scroll hidden-scroll-bar mb-[10px] md:mb-[5px]'>
                                                {(filteredAddOns && filteredAddOns.length !== 0) &&
                                                    <div className='mt-[-5px]'>
                                                        <Accordion allowZeroExpanded>
                                                            {filteredAddOns.slice(0).reverse().map(addOn =>
                                                                <FilteredAddOnAccordion key={addOn.per_product_add_ons_id} addOn={addOn} alertAddOn={alertAddOn} makeAddOn={makeAddOn} requiredAddOns={requiredAddOns} theme={theme} />
                                                            )}
                                                        </Accordion>
                                                    </div>
                                                }
                                                <div className="w-full h-[106px] border-radius-5">
                                                    <textarea name="" id="" className="w-full h-full border-primary-dark-color border-[2px] bg-footer text-dark-color  p-2 text-[14px] focus:outline-none border-radius-5" placeholder="Write a note here" value={note} onChange={(e => setNote(e.target.value))}></textarea>
                                                </div>
                                            </div>
                                            <div className='h-full flex-shrink flex justify-between items-center'>
                                                <div className="flex items-center">
                                                    <div className="w-[20px] h-[25px] bg-primary-dark-color rounded-sm flex justify-center items-center cursor-pointer text-light-color" onClick={() => {
                                                        (quantity !== 1) && setQuantity(q => q - 1);
                                                    }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M20 12H4" />
                                                        </svg>
                                                    </div>
                                                    <div className="w-[23px] h-[30px] text-[14px] bg-primary-light-color rounded-sm flex justify-center items-center cursor-pointer">
                                                        {quantity}</div>
                                                    <div className="w-[20px] h-[25px] bg-primary-dark-color rounded-sm flex justify-center items-center cursor-pointer text-light-color" onClick={() => {
                                                        (quantity < product.items_available) ? setQuantity(q => q + 1) : alert.error("Product Not Available !");
                                                    }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 4v16m8-8H4" />
                                                        </svg>
                                                    </div>
                                                </div>



                                                <div className="uppercase font-bold flex justify-center items-center px-[15px] py-[5px] bg-primary-dark-color button-primary rounded-md cursor-pointer text-light-color" onClick={() => (quantity < product.items_available) ? addToCart(product) : alert.error("Product Not Available !")}>
                                                    Add to Cart</div>
                                            </div>
                                            {/* <div className="mb-[5px] overflow-hidden border-radius-5">
                                                <div className='h-[260px] overflow-y-auto'>
                                                    {(product.product_list.per_product_add_ons !== undefined && product.product_list.per_product_add_ons.length !== 0) &&
                                                        <div>
                                                            <Accordion allowZeroExpanded>
                                                                {product.product_list.per_product_add_ons.slice(0).reverse().map(addOn =>
                                                                    <AccordionItem key={addOn.per_product_add_ons_id} className='my-[5px]  text-light-color relative border-radius-5'>
                                                                        <AccordionItemHeading className={`text-left p-2 text-sm rounded-none font-semibold h-[53px] border-radius-5 ${alertAddOn.includes(addOn.add_on.title) ? 'bg-red-700 text-white hover:bg-red-800' : 'bg-primary-dark-color'}`}>
                                                                            <AccordionItemButton className="w-full h-full flex items-center justify-between capitalize border-radius-5">
                                                                                {addOn.add_on !== null && addOn.add_on.title}
                                                                                {alertAddOn.includes(addOn.add_on.title) && <div className="w-fit">
                                                                                    <ExclamationIcon className="h-[20px] fill-[yellow] stroke-[black]" />
                                                                                </div>}
                                                                            </AccordionItemButton>
                                                                        </AccordionItemHeading>
                                                                        <AccordionItemPanel className='bg-primary-light-color w-full bg-primary-light-color text-left p-2 left-0 z-50 overflow-y-scroll border-2 border-primary-dark-color border-radius-5 mt-[-10px]'>
                                                                            {
                                                                                addOn.add_on !== null && addOn.add_on.add_on_options.map(option =>
                                                                                    <div key={option.add_on_option_id} className="my-2">
                                                                                        <input type={addOn.add_on.add_on_type} id={(option.title + option.price).replaceAll(' ', '')} name={addOn.add_on.title} value={option.title} className="hidden peer" required onChange={() => makeAddOn(addOn.add_on.title, option.title, addOn.add_on.add_on_type, option)} />
                                                                                        <label htmlFor={(option.title + option.price).replaceAll(' ', '')} className={`inline-flex justify-between items-center p-2 w-full font-semibold bg-primary-color text-center text-[14px] cursor-pointer border-radius-5 ${theme === "dark" ? "peer-checked:bg-[#ffd490] peer-checked:text-black" : "peer-checked:bg-[#003a1b] peer-checked:text-white"}`}>
                                                                                            <div>{option.title}</div>
                                                                                            <div>&#8377; {option.price}</div>
                                                                                        </label>
                                                                                    </div>
                                                                                )
                                                                            }


                                                                        </AccordionItemPanel>
                                                                    </AccordionItem>
                                                                )}
                                                            </Accordion>
                                                        </div>
                                                    }
                                                    <div className="w-full h-[106px] border-radius-5">
                                                        <textarea name="" id="" className="w-full h-full border-primary-dark-color border-2 bg-text-area text-dark-color  p-2 text-[14px] focus:outline-none border-radius-5" placeholder="Write a note here" value={note} onChange={(e => setNote(e.target.value))}></textarea>
                                                    </div>
                                                </div>
                                            </div> */}

                                            {/* <!-- Buttons --> */}
                                            {/* <div className="w-full ml-0 flex justify-between items-center">

                                                <div className="flex items-center">
                                                    <div className="w-[24px] h-[30px] bg-red-800 text-white rounded-sm flex justify-center items-center cursor-pointer" onClick={() => {
                                                        (quantity !== 1) && setQuantity(q => q - 1);
                                                    }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M20 12H4" />
                                                        </svg>
                                                    </div>
                                                    <div className="w-[30px] h-[30px] bg-gray-100 mr-[2px] rounded-sm flex justify-center items-center cursor-pointer">
                                                        {quantity}</div>
                                                    <div className="w-[24px] h-[30px] bg-green-800 text-white rounded-sm flex justify-center items-center cursor-pointer" onClick={() => {
                                                        (quantity < product.items_available) ? setQuantity(q => q + 1) : alert.error("Product Not Available !");
                                                    }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 4v16m8-8H4" />
                                                        </svg>
                                                    </div>
                                                </div>

                                                <div className="h-[40px] flex justify-center items-center px-[20px] bg-primary-dark-color rounded-sm cursor-pointer text-light-color" onClick={() => (quantity < product.items_available) ? addToCart(product) : alert.error("Product Not Available !")}>
                                                    Add to Cart</div>
                                            </div> */}
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>


        </>
    )
}

const FilteredAddOnAccordion = ({ addOn, requiredAddOns, alertAddOn, makeAddOn, theme }) => {
    const [filteredAddOnOptions, setFilteredAddOnOptions] = useState([]);

    useEffect(() => {
        if(addOn.add_on){
            const addOnOptions = addOn.add_on.add_on_options;
            if(Array.isArray(addOnOptions)){
                setFilteredAddOnOptions(addOnOptions.sort((a, b) => a.order - b.order))
            }
            else
                setFilteredAddOnOptions(addOnOptions)
        }
    }, [addOn])

    return (
        <AccordionItem key={addOn.per_product_add_ons_id} className='my-[8px]  text-light-color relative border-radius-10'>
            <AccordionItemHeading className={`sticky top-0  text-left p-[10px] text-sm rounded-none font-semibold border-radius-5 ${alertAddOn.includes(addOn.add_on.title) ? 'bg-red-700 text-white hover:bg-red-800' : 'bg-primary-dark-color'}`}>
                <AccordionItemButton className="w-full h-full flex items-center justify-between capitalize border-radius-5">
                    {addOn.add_on !== null && addOn.add_on.title}
                    
                    <small>{requiredAddOns.includes(addOn.add_on.title) ? '' : '(optional)'}</small>
                    {alertAddOn.includes(addOn.add_on.title) && <div className="w-fit">
                        <ExclamationIcon className="h-[20px] fill-[yellow] stroke-[black]" />
                    </div>}
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className='bg-primary-light-color w-full text-left px-[7.5px] pt-[7.5px] left-0 z-50 overflow-y-scroll  border-radius-5 mt-[-7.5px]'>
                {
                    filteredAddOnOptions && filteredAddOnOptions.length !== 0 && filteredAddOnOptions.map(option =>
                        <div key={option.add_on_option_id} className="my-[7.5px]">
                            <input type={addOn.add_on.add_on_type} id={(option.title + option.price).replaceAll(' ', '')} name={addOn.add_on.title} value={option.title} className="hidden peer" required onChange={() => makeAddOn(addOn.add_on.title, option.title, addOn.add_on.add_on_type, option)} />
                            <label htmlFor={(option.title + option.price).replaceAll(' ', '')} className={`inline-flex justify-between items-center p-[7.5px] w-full font-semibold bg-primary-color text-center text-[14px] cursor-pointer border-radius-5 ${theme === "dark" ? "peer-checked:bg-[#ffd490] peer-checked:text-black" : "peer-checked:bg-[#003a1b] peer-checked:text-white"}`}>
                                <div>{option.title} </div>
                                <div>&#8377; {option.price}</div>
                            </label>
                        </div>
                    )
                }


            </AccordionItemPanel>
        </AccordionItem>
    )
}

export default Product