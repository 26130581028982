import React from 'react'
import Logo from '../../assets/images/logo.png'
import { Facebook, Instagram, Mail, MapPin, Phone, Twitter, Youtube } from 'react-feather';
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux';

const Footer = () => {

  const state = useSelector(state => state);
  let today = new Date();
  let weekday = today.getDay();

  return (
    <div className='relative mt-[30px]'>
      {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="var(--primaryLightColor)" fillOpacity="1" d="M0,128L34.3,106.7C68.6,85,137,43,206,69.3C274.3,96,343,192,411,192C480,192,549,96,617,101.3C685.7,107,754,213,823,218.7C891.4,224,960,128,1029,122.7C1097.1,117,1166,203,1234,240C1302.9,277,1371,267,1406,261.3L1440,256L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"></path></svg> */}
      <svg id="wave" viewBox="0 0 1440 160" version="1.1" xmlns="http://www.w3.org/2000/svg"><path fill="var(--bgFooter)" d="M0,32L48,40C96,48,192,64,288,82.7C384,101,480,123,576,117.3C672,112,768,80,864,56C960,32,1056,16,1152,21.3C1248,27,1344,53,1440,56C1536,59,1632,37,1728,29.3C1824,21,1920,27,2016,34.7C2112,43,2208,53,2304,72C2400,91,2496,117,2592,122.7C2688,128,2784,112,2880,96C2976,80,3072,64,3168,61.3C3264,59,3360,69,3456,61.3C3552,53,3648,27,3744,24C3840,21,3936,43,4032,48C4128,53,4224,43,4320,32C4416,21,4512,11,4608,18.7C4704,27,4800,53,4896,61.3C4992,69,5088,59,5184,53.3C5280,48,5376,48,5472,45.3C5568,43,5664,37,5760,45.3C5856,53,5952,75,6048,74.7C6144,75,6240,53,6336,53.3C6432,53,6528,75,6624,74.7C6720,75,6816,53,6864,42.7L6912,32L6912,160L6864,160C6816,160,6720,160,6624,160C6528,160,6432,160,6336,160C6240,160,6144,160,6048,160C5952,160,5856,160,5760,160C5664,160,5568,160,5472,160C5376,160,5280,160,5184,160C5088,160,4992,160,4896,160C4800,160,4704,160,4608,160C4512,160,4416,160,4320,160C4224,160,4128,160,4032,160C3936,160,3840,160,3744,160C3648,160,3552,160,3456,160C3360,160,3264,160,3168,160C3072,160,2976,160,2880,160C2784,160,2688,160,2592,160C2496,160,2400,160,2304,160C2208,160,2112,160,2016,160C1920,160,1824,160,1728,160C1632,160,1536,160,1440,160C1344,160,1248,160,1152,160C1056,160,960,160,864,160C768,160,672,160,576,160C480,160,384,160,288,160C192,160,96,160,48,160L0,160Z"></path></svg>

      <div className='w-full bg-footer '>
        <div className='lg:container lg:mx-auto grid lg:grid-cols-3 grid-cols-1 gap-6 px-5 pb-5'>
          <div className='flex flex-col justify-start items-start'>
            <img src={Logo} alt="HeeBee" className='w-[60px] h-[60px]  bg-color border-4 rounded-full' style={{ borderColor: "var(--bgColor)" }} />
            <h4 className='text-primary-dark-color font-semibold font-montserrat mb-4 text-md leading-tight '>HeeBee Coffee</h4>
            <div className='social flex gap-4'>
              <a href='https://www.facebook.com/heebeecoffee/' className='p-3 social-link text-primary-light-color border-radius-5 transition-all transition-duration-1000 hover:text-primary-color' target="blank">
                <Facebook size={20} />
              </a>
              <a href='https://twitter.com/heebeecoffee' className='p-3 social-link text-primary-light-color border-radius-5 transition-all transition-duration-1000  hover:text-primary-color' target="blank">
                <Twitter size={20} />
              </a>
              <a href='https://www.instagram.com/heebeecoffee/' className='p-3 social-link text-primary-light-color border-radius-5 transition-all transition-duration-1000  hover:text-primary-color' target="blank">
                <Instagram size={20} />
              </a>
              <a href='https://www.youtube.com/channel/UCDrqk_pwv94tLIe3xpJUOZg' className='p-3 social-link text-primary-light-color border-radius-5 transition-all transition-duration-1000 hover:text-primary-color' target="blank">
                <Youtube size={20} />
              </a>

            </div>
            <div className='mt-5 justify-self-end'><h6 className='text-xs text-primary-color'>Develop by <a href="https://quadbtech.com" target="_blank" rel="nofollow" className="text-primary-dark-color">QuadBTech</a></h6></div>
          </div>
          <div className='flex flex-col justify-start items-start'>
            <h2 className='text-dark-color text-3xl font-bold font-montserrat mb-3'>Contact Us</h2>
            {/* <div className='flex justify-center items-center gap-2 mb-3'>
              <div className='p-3 bg-primary-color text-primary-light-color border-radius-5'>
                <Phone size={20} />
              </div>
              <a href={`tel:${state.currentBranch?.contact}`} className='text-md font-bold font-montserrat text-primary-dark-color'>{state.currentBranch?.contact}</a>
            </div> */}
            <div className='flex justify-center items-center gap-2 mb-3'>
              <div className='p-3 bg-primary-color text-primary-light-color border-radius-5'>
                <Mail size={20} />
              </div>
              <a href={`mailto:${state.currentBranch?.email}`} className='text-md font-bold font-montserrat text-primary-dark-color'>{state.currentBranch?.email}</a>
            </div>
            <div className='flex justify-center items-center gap-2'>
              <div className='p-3 bg-primary-color text-primary-light-color border-radius-5'>
                <MapPin size={20} />
              </div>
              <p className='text-md font-bold font-montserrat text-primary-dark-color leading-tight'>{state.currentBranch?.address}</p>
            </div>
          </div>
          <div className='flex flex-col justify-start items-start'>
            <h2 className='text-dark-color text-3xl font-bold font-montserrat mb-3'>Opening Hours</h2>
            <table>
              <tbody>
                <tr>
                  <td className={`${weekday === 1 ? 'text-primary-color ' : 'text-primary-dark-color'} text-md font-bold font-montserrat pr-5`}>Monday</td>
                  <td className={`${weekday === 1 ? 'text-primary-color ' : 'text-primary-dark-color'} text-md font-bold font-montserrat pr-5`}>08:00 AM - 11:00 PM</td>
                </tr>
                <tr>
                  <td className={`${weekday === 2 ? 'text-primary-color ' : 'text-primary-dark-color'} text-md font-bold font-montserrat pr-5`}>Tuesday</td>
                  <td className={`${weekday === 2 ? 'text-primary-color ' : 'text-primary-dark-color'} text-md font-bold font-montserrat pr-5`}>08:00 AM - 11:00 PM</td>
                </tr>
                <tr>
                  <td className={`${weekday === 3 ? 'text-primary-color ' : 'text-primary-dark-color'} text-md font-bold font-montserrat pr-5`}>Wednesday</td>
                  <td className={`${weekday === 3 ? 'text-primary-color ' : 'text-primary-dark-color'} text-md font-bold font-montserrat pr-5`}>08:00 AM - 11:00 PM</td>
                </tr>
                <tr>
                  <td className={`${weekday === 4 ? 'text-primary-color ' : 'text-primary-dark-color'} text-md font-bold font-montserrat pr-5`}>Thursday</td>
                  <td className={`${weekday === 4 ? 'text-primary-color ' : 'text-primary-dark-color'} text-md font-bold font-montserrat pr-5`}>08:00 AM - 11:00 PM</td>
                </tr>
                <tr>
                  <td className={`${weekday === 5 ? 'text-primary-color ' : 'text-primary-dark-color'} text-md font-bold font-montserrat pr-5`}>Friday</td>
                  <td className={`${weekday === 5 ? 'text-primary-color ' : 'text-primary-dark-color'} text-md font-bold font-montserrat pr-5`}>08:00 AM - 11:00 PM</td>
                </tr>
                <tr>
                  <td className={`${weekday === 6 ? 'text-primary-color ' : 'text-primary-dark-color'} text-md font-bold font-montserrat pr-5`}>Saturday</td>
                  <td className={`${weekday === 6 ? 'text-primary-color ' : 'text-primary-dark-color'} text-md font-bold font-montserrat pr-5`}>08:00 AM - 11:00 PM</td>
                </tr>
                <tr>
                  <td className={`${weekday === 0 ? 'text-primary-color ' : 'text-primary-dark-color'} text-md font-bold font-montserrat pr-5`}>Sunday</td>
                  {/* <td className='text-md font-bold font-montserrat text-red-500'>Closing Day</td> */}
                  <td className={`${weekday === 0 ? 'text-primary-color ' : 'text-primary-dark-color'} text-md font-bold font-montserrat pr-5`}>08:00 AM - 11:00 PM</td>
                </tr>
              </tbody>
            </table>

          </div>
        </div>
      </div>
      <div className='w-full bg-footer2 px-5 py-4 '>
        <div className='flex flex-wrap sm:justify-between justify-center items-center md:container md:mx-auto'>
          <div>
            <h6 className='font-bold text-primary-dark-color font-roboto'>Heebee coffee private limited &copy; 2022 | All Rights Reserved</h6>
          </div>
          <div>
            <Link to="/terms" className='text-md font-bold font-roboto text-primary-dark-color text-link mr-2'>Terms & Conditions</Link>|<Link to="/privacy" className='text-md font-bold font-roboto text-primary-dark-color text-link mx-2'>Privacy Policy</Link>|<Link to="/refund" className='text-md font-bold font-roboto text-primary-dark-color text-link ml-2'>Refund & Cancellation Policy</Link>
          </div>
        </div>
      </div>
      <h1 className='text-white/40 text-6xl font-bold font-font-montserrat rotate-90 absolute sm:top-[190px] top-[90px] right-[-50px]'>HEEBEE</h1>
    </div>

  )
}

export default Footer