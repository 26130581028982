import React, { useState, useEffect } from "react";
import { PlusIcon, TrashIcon } from "@heroicons/react/outline";
import { MinusIcon } from "@heroicons/react/outline";
import { useDispatch, useSelector } from 'react-redux';
import { updateCartItems, deleteCartItem, currentCoupon } from "../../actions";

const CartItemsCard = ({ item }) => {
  const state = useSelector(state => state);
  const dispatch = useDispatch();
  const [count, setCount] = useState(item.quantity);
  const params = window.location.pathname;
  const display = params === "/cart" ? "" : "hidden";

  useEffect(() => {
    state.cartItems.forEach(cartItem => {
      if (cartItem.index === item.index)
        dispatch(updateCartItems(cartItem.index, count));
    })
  }, [count])

  const deleteItem = (item, index) => {
    if (item.coupon !== null) {
      dispatch(currentCoupon(null));
    }
    dispatch(deleteCartItem(index));
  }

  const objectToArray = (obj) => {
    const keys = Object.keys(obj);
    const values = Object.values(obj);
    let arr = [];
    keys.forEach((key, i) => {
      let val = values[i];
      let desc = "";
      val.forEach((obj, index) => {
        if (index === val.length - 1) desc += obj.value;
        else desc += (obj.value + ", ");
      })
      let obj = {
        title: key,
        desc: desc
      };
      arr.push(obj);
    })
    return arr;
  }
  let adds = objectToArray(item.addOns);


  return (
    <>
      <div className={`w-[300px] sm:w-full h-auto my-2 flex flex-row p-2 sm:items-center cart-item-card space-x-4 border-radius-10`}>
        <div className="w-[60px] min-w-[60px] mbtb:w-[80px] mbtb:min-w-[80px] h-[60px] mbtb:h-[80px] rounded-sm">
          <img src={item.img} className="w-full h-full object-cover border-radius-5 " alt="" />
        </div>
        <div className="space-y-2 sm:space-y-0 block sm:flex sm:justify-between w-full">
          <div className="w-fit flex justify-center flex-wrap flex-col sm:pr-4">
            <div className="text-base mbtb:text-lg font-bold capitalize">{item.product_name}</div>
            {<div className="text-[10px] mbtb:text-xs text-grey-500 capitalize">
              {/* {item.extra.map((addon, index) => `${addon.title} : ${addon.desc}${(index === (item.extra.length - 1)) ? '' : ', '}`)} */}
              {adds.map((addon, i) => <span className="mr-1" key={i}>{addon.desc}{i !== adds.length - 1 && ', '}</span>)}
            </div>}
            {(item.note.length > 0) && <div className="text-[10px] mbtb:text-xs text-grey-500 capitalize">
              Note : {item.note}
            </div>}
          </div>
          <div className="w-full sm:w-fit flex justify-start">
            <div className={`flex items-center`}>
              <div className={`cart-item-counter hover:bg-[#003a1b]/5  ${display}`} >
                <PlusIcon
                  className="w-3 h-3 cursor-pointer"
                  onClick={() => {
                    setCount(count + 1);
                  }}
                />
              </div>
              <div className={`${params === "/cart" ? 'mx-1' : 'mr-8'}`}>
                {params === "/cart" ? count : `Quantity : ${count}`}
              </div>
              <div className={`cart-item-counter hover:bg-[#003a1b]/5  ${display}`}>
                <MinusIcon
                  className="w-3 h-3 cursor-pointer"
                  onClick={() => {
                    count > 1 && setCount(count - 1);
                  }}
                />
              </div>
            </div>
            <div className={`flex items-center sm:text-lg text-sm font-bold ${display==='hidden' ? 'mx-0' : 'mx-[25px]'}`}>₹ {item.amount}</div>
            <div className={`flex items-center ${display}`}>
              <TrashIcon
                className={`sm:w-6 sm:h-6 w-4 h-4  hover:text-red-600 cursor-pointer`}
                onClick={() => deleteItem(item, item.index)}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CartItemsCard;
