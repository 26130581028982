import React from 'react'
import Footer from '../components/common/Footer'
import Header from '../components/common/Header'
import { useSelector } from 'react-redux';
import NotFoundDark from '../media/page-not-found-dark.png'
import NotFoundLight from '../media/page-not-found-light.png'
const Error = () => {
  const state = useSelector(state => state);
  return (
    <>
      <Header/>
      <div className="w-full p-16 flex justify-center items-center flex-col">
        <img src={state.theme === "dark" ? NotFoundDark : NotFoundLight} width="300px" alt="" />
            <div className="text-primary-dark-color mt-2 text-3xl md:text-5xl tracking-wider">Page Not Found</div>
            <div className='text-dark-color mt-4 text-sm max-w-[350px] text-center'>The page you are looking for might have been removed, had its name changed or is temporarily unavailable.</div>
      </div>
      <Footer/>
    </>
  )
}

export default Error