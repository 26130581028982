import React from 'react'

const Content = () => {
    return (
        <div className='text-md text-gray-600 px-6 md:container md:mx-auto mt-[100px]'>
            <p><b>Last Updated On: July 13, 2023</b></p>
            <br />
            <p>In using this website you are deemed to have read, understood, and agreed to the following Terms and Conditions set forth by HeeBee:</p>
            <br />
            <p>
                <b>User Agreement : </b>
                <ol className='ml-10 list-disc'>
                    <li>This website is published and maintained by HeeBee Private Limited.</li>
                    <li>When you access, browse, or use any services provided by HeeBee, you accept the terms and conditions set forth below, without limitation.</li>
                    <li>These Terms and Conditions constitute the entire agreement between HeeBee and the user with respect to the use of the website, mobile application, or any other services offered solely by HeeBee.</li>
                </ol>
            </p>
            <br />
            <p>
                <b>Terms of Use : </b>
                <ol className='ml-10 list-disc'>
                    <li>By using the services of HeeBee, be it the website, mobile application, or concierge service, you acknowledge that you have read and understood the terms of use and agree to be bound by them.</li>
                    <li>While people under the age of 18 can use the services of HeeBee, they must do so under the guidance of their parents and/ or legal guardians.</li>
                </ol>
            </p>
            <br />
            <p>
                <b>What will we do with your personal information?</b>
                <ol className='ml-10 list-disc'>
                    <li>We at HeeBee are committed to protecting and ensuring your privacy. Only authorized employees within the company have access to the user’s details and information & are prohibited from using this information for any personal or commercial use.</li>
                    <li>Client records are regarded as confidential and will not be divulged to any third party, other than to appropriate authorities, only if legally required. We will not sell, share, or rent your personal information to any third party.</li>
                    <li>Any emails sent by this Company will only be in connection with the provision of agreed services and products.</li>
                </ol>
            </p>
            <br />
            <p>
                <b>User Communication Guidelines</b>
                <ol className='ml-10 list-disc'>
                    <li>We send our users (registered with us) regular communications about our products and offers.</li>
                    <li>Users can send us email to remove them from marketing communications, however, they will always get messages related to orders, etc.</li>
                </ol>
            </p>
            <br />
            <p>
                <b>What are the copyright policies for HeeBee?</b>
                <ol className="ml-10 list-disc">

                    <li>The website is for personal use only. Any distribution or use of the website content, including text, images, audio and video, for any business, commercial or public purpose without prior permission is subject to legal action.</li>
                    <li>It is restricted to display, host, upload, modify, publish, transmit, update or share any information without prior permission.</li>
                    <li>HeeBee’s logo is a registered trademark of this Company in India and other countries. The brand names and specific services of this Company featured on this web site are trademarked.</li>
                    <li>All materials on the website, including but not limited to audio, images, software, text, icons and the like are protected by copyright under international conventions and copyright laws.</li>
                    <li>Any unauthorised use of website content may violate copyright laws, trademark laws, the laws of privacy and publicity, and civil and criminal statute.</li>
                    <li>Users may download the content to be used only for personal use. If you download any content, you shall not remove any copyright or trademark notices or other notices that go with it.</li>
                    <li>As long as you comply with the terms, HeeBee grants you the right to enter, view and use the website and mobile application. You agree not to interrupt or attempt to interrupt the operation of HeeBee in any way.</li>
                    <li>Access to certain areas of the website may only be available to registered members.</li>
                    <li>To become a registered member, you may be required to answer certain questions. Answers to such questions may be mandatory and/or optional.</li>
                    <li>The company accepts all your personal information in good faith as being truthful and accurate. Legal action may be initiated for any false or inaccurate information and you agree to indemnify HeeBee</li>
                </ol>
            </p>
            <br />
            <p>
                <b>What are Log Files and what do you do with them?</b>
                <ol className='ml-10 list-disc'>
                    <li>We use IP addresses to analyse trends, administer the site, track user’s movement, and gather broad demographic information for aggregate use. IP addresses are not linked to personally identifiable information.</li>
                    <li>Additionally, for systems administration, detecting usage patterns and troubleshooting purposes, our web servers automatically log standard access information including browser type, access times/open mail, URL </li>requested, and referral URL.
                    <li>This information is not shared with third parties and is used only within this Company on a need-to-know basis. Any individually identifiable information related to this data will never be used in any way different to that stated above without your prior permission.</li>
                </ol>
            </p>
            <br />
            <p>
                <b>What are cookies?</b>
                <ol className="ml-10 list-disc">
                    <li>Like most interactive websites, this Company’s website [or ISP] uses cookies to enable us to retrieve user details for each visit.</li>
                    <li>Cookies are used in some areas of our site to enable the functionality of this area and ease of use for those people visiting. Some of our affiliate partners may also use cookies.</li>
                </ol>
            </p>
            <br />
            <p>
                <b>What are links to the website?</b>
                <ol className="ml-10 list-disc">
                    <li>You may not create a link to any page of this website without our prior written consent.</li>
                    <li>If you do create a link to a page of this website you do so at your own risk and the exclusions and limitations set out above will apply to your use of this website by linking to it.</li>
                </ol>
            </p>
            <br />
            <p>
                <b>How can I communicate with HeeBee?</b>
                <ol className="ml-10 list-disc">
                    <li>You can call our 24 hour HeeBee at 78144-98149 for reservations or any additional queries.</li>
                    <li>You may also write to us at query@heebee.in or go to the contact us page to submit your query and we will respond to you as soon as possible.</li>
                    <li>This company is registered in Ludhiana, Punjab, India.</li>
                </ol>
            </p>
            <br />
            <p>
                <b>Waiver : </b>Failure of either Party to insist upon strict performance of any provision of this or any Agreement or the failure of either Party to exercise any right or remedy to which it, he or they are entitled hereunder shall not constitute a waiver thereof and shall not cause a diminution of the obligations under this or any Agreement. No waiver of any of the provisions of this or any Agreement shall be effective unless it is expressly stated to be such and signed by both Parties.
            </p>
            <br />
            <p>
                <b>How often does HeeBee change their policies and will we be notified?</b>
                <ol className="ml-10 list-disc">
                    <li>HeeBee reserves the right to change these conditions from time to time as it sees fit and your continued use of the site will signify your acceptance of any adjustment to these terms.</li>
                    <li>If there are any changes to our privacy policy or terms of use, we will announce that these changes have been made on our home page and on other key pages on our site.</li>
                    <li>If there are any changes in how we use our users’ personally identifiable information, notification by e-mail or postal mail will be made to those affected by this change.</li>
                    <li>Any changes to our privacy policy will be posted on our web site 7 days prior to these changes taking place. You are therefore advised to re-read this statement on a regular basis.</li>
                </ol>
            </p>
            <br />
            <p>
                <b>Is this agreement law binding?</b>
                <ol className="ml-10 list-disc">
                    <li>This agreement is governed by the Laws of India.</li>
                    <li>The courts of law in Ludhiana shall have exclusive jurisdiction over any disputes arising under this agreement.</li>
                </ol>
            </p>
            <br />
            <p>
                <b>How can I raise a claim against HeeBee? </b>Any cause of action arising out of or related to the HeeBee websites or mobile apps must commence within 15 days after the cause of action accrues otherwise it will be permanently barred.
            </p>
            <br />
            <p>
                <b>What does my acceptance of the privacy statement mean?</b>
                <ol className="ml-10 list-disc">
                    <li>By using HeeBee services, you signify your acceptance of this Privacy Statement.</li>
                    <li>We reserve the right, to modify this Privacy Statement at any time.</li>
                    <li>We reserve the right to terminate your access or use of service without any prior notice or take any other legal action if anyone violates these terms.</li>
                    <li>Right of admission to the website is reserved.</li>
                </ol>
            </p>
            <br />
            <p>
                <b>Contact Us :</b> If you have any questions or concerns regarding these Terms, please contact us at: <br />
                <p className='ml-5'>
                    <b>HeeBee Coffee</b> <br />
                    Address: HeeBee Coffee, 48, I Block Rd, I - Block, Sarabha Nagar, Ludhiana, Punjab 141001<br />
                    Email: query@heebee.in<br />
                    Phone: 78144-98149
                </p>
            </p>
            <br />

        </div>
    )
}

export default Content