import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { currentCoupon, fetchAllOrders, setCartItems, setCustomerDetails } from '../actions';
import logo from '../assets/images/logo2.png'
import { BsCheckCircle } from "react-icons/bs";

const OrderPlaced = () => {
    const navigate = useNavigate();
    const state = useSelector(state => state);
    const dispatch = useDispatch();
    const [seconds, setSeconds] = useState(5);
    useEffect(() => {
        if (seconds > 0)
            setTimeout(() => {
                setSeconds(seconds - 1);
            }, [1000]);
        else {
            window.localStorage.setItem('heebee-cart-items', JSON.stringify([]));
            dispatch(setCartItems([]));
            dispatch(currentCoupon(null));
            dispatch(setCustomerDetails(state.token));
            dispatch(fetchAllOrders(state.token, state.customerDetails.mobile_no));
            navigate('/my-orders');
        }
    }, [seconds]);
    return (
        <div className='w-full h-screen bg-primary-light-color flex justify-center items-center p-3'>
            <div className='bg-primary-color border-radius-10 sm:p-8 p-2'>
                <div className='flex justify-center items-center'>
                    <img src={logo} alt="logo" className=' w-[60px] mr-4' />
                </div>
                <div className='w-full h-full flex-shrink mt-3'>
                    <div className='flex justify-center items-center mt-6'>
                        <div className='text-2xl sm:text-3xl md:text-4xl font-bold mr-3 text-primary-light-color'>Payment Successful</div>
                        <BsCheckCircle className='text-green-900 text-2xl sm:text-3xl md:text-4xl' />
                    </div>
                    <div className='mt-6 text-primary-light-color text-[16px] sm:text-[18px] md:text-xl text-center'>
                        Your order has been placed.<br /> <b>Have a nice Coffee Day.</b>
                    </div>
                    <div className='text-center my-4 text-xl sm:text-2xl md:text-3xl text-primary-light-color'>Redirecting in {seconds} seconds...</div>
                    <div className="flex justify-center">
                        <div className="button-primary px-2 sm:px-8 py-2 uppercase text-lg font-roboto border-radius-5 cursor-pointer w-1/2 flex justify-center" onClick={() => setSeconds(0)}>Go to Home</div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default OrderPlaced;
