import React from 'react'
import Footer from '../components/common/Footer'
import Header from '../components/common/Header'
import TopBar from '../components/common/TopBar'
import HeadTop from '../components/singleoffer/Headtop'
import OfferSection from '../components/singleoffer/OfferSection'

export default function SingleOffer() {
    return (
        <>
            <TopBar />
            <Header />
            <HeadTop />
            <div className='md:container mx-auto px-6 mt-[30px]'>
                <div className='flex flex-wrap md:flex-nowrap justify-start gap-6 h-[100%]'>
                    <OfferSection />
                </div>
            </div>
            <Footer />
        </>
    )
}
