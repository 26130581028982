import React, { useEffect } from 'react'
import Product from './sub_comp/Product'
import { useSelector, useDispatch } from 'react-redux';
import { mostOrderItems } from '../../actions';
import NoProductImgDark from '../../media/empty-card-dark.png'
import NoProductImgLight from '../../media/empty-cart-light.png'

const MostOrderItems = () => {
    const dispatch = useDispatch();
    const state = useSelector(state => state);

    //const customer_id = state.customerDetails.customer_id;
    //const branch_id = state.customerDetails.branch_id;
    useEffect(() => {
        let customer_id = state.customerDetails && state.customerDetails.customer_id;
        let branch_id = state.customerDetails && state.customerDetails.branch_id;
        dispatch(mostOrderItems(customer_id, branch_id));
    }, [dispatch, state.customerDetails])

    return (
        <div className='mb-10'>
            <div className='md:container md:mx-auto'><div className='flex justify-between items-center mb-6 heading-one'>
                <h4 className="text-3xl font-semibold text-primary-dark-color mb-1">Most Ordered Items</h4>
            </div>
                {(state.mostOrderItems.length > 0) ?
                    <div className='grid lg:grid-cols-5 md:grid-cols-3 sm:grid-cols-2 xs:grid-cols-1 gap-6 px-4'>
                        {state.mostOrderItems.map((product, index) => <Product key={index} product={product} coupon={{ usedCoupon: false, coupon: null }} />)}
                    </div> :
                    <h1 className='text-primary-dark-color text-xl mbtb:text-2xl flex items-center flex-wrap space-x-1 space-y-2 justify-center mbtb:justify-start px-0 mbtb:px-2'>
                        <div className='sm:text-3xl sm:mr-[40px]'>No Products Found !</div>
                        <img src={state.theme === "dark" ? NoProductImgDark : NoProductImgLight} className="w-[125px] sm:w-[200px]" alt="" />
                    </h1>
                }

            </div>
        </div>
    )
}

export default MostOrderItems