import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { Input } from "@material-tailwind/react";
import { ReactComponent as LoginIcon } from "../assets/images/heebeeIcon.svg";
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { useAlert } from "react-alert";
const { REACT_APP_API_ENDPOINT } = process.env;

const ForgetPassword = () => {
  const navigate = useNavigate();
  const alert = useAlert();
  const state = useSelector(state => state);
  useEffect(() => {
    if (state.token !== null) navigate('/');
  }, [state.token, navigate]);

  const [mobile, setMobile] = useState("");
  const [OTP, setOTP] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [OTPSent, setOTPSent] = useState(false);
  const [OTPverify, setOTPVerify] = useState(false);
  const sendOTP = () => {
    if (mobile.length === 10) {
      let url = `${REACT_APP_API_ENDPOINT}/send_otp`;
      let user = {
        "mobile_no": mobile
      };
      axios.post(url, user)
        .then(res => {
          if (res.data.status === "success") {
            setOTPSent(true);
            alert.success('OTP Sent Successfully !')
          }
          else alert.error(res.data.msg);
        });
    }
    else {
      alert.error("Wrong Phone NO.");
    }
  }
  const verifyOTP = () => {
    if (OTP.length === 4) {
      let url = `${REACT_APP_API_ENDPOINT}/verify_otp`;
      let user = {
        "mobile_no": mobile,
        "OTP": OTP
      };
      axios.post(url, user)
        .then(res => {
          if (res.data.status === "success") {
            setOTPVerify(true);
            alert.success('OTP Verified..')
          }
          else alert.error(res.data.msg);
        });
    }
    else {
      alert.error("Wrong OTP");
    }
  }
  const changePassword = () => {
    let pass = "";
    if (newPassword === confirmPassword) {
      let user = {
        mobile_no: mobile,
        password: newPassword
      };
      setConfirmPassword("");
      setNewPassword("");
      axios.post(`${REACT_APP_API_ENDPOINT}/change_password`, user)
        .then(res => {
          if (res.data.status === "success") {
            alert.success("Password Changed !");
            setTimeout(() => navigate('/login'), 5000);
          }
          else alert.error(res.data.msg);
        })
    }
    else {
      alert.error("Passwords do not match !");
    }
  }
  return (
    <div className="py-6 w-screen h-screen flex flex-row justify-center items-center bg-footer">
      <div className="flex bg-product rounded-lg shadow-lg overflow-hidden mx-auto w-[24rem] lg:w-[50rem]">
        <div className="hidden lg:flex lg:w-1/2 bg-cover bg-primary-color justify-center items-center">
          <LoginIcon className=" fill-color mt-1 ml-1" />
        </div>
        <div className={`w-full p-8 lg:w-1/2 flex flex-col justify-center relative`}>
          <div className="absolute top-3 left-3 cursor-pointer">
            <Link to="/">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
              </svg>
            </Link>
          </div>
          <h1 className="font-bold text-primary-color text-center text-4xl mt-2">
            Hee<span className="text-primary-dark-color ">bee</span>
          </h1>
          <p className="text-xl text-primary-color text-left font-bold mt-2 text-primary-dark-color">
            Reset Password
          </p>
          {(OTPSent === false) ?
            <div>
              <p className="text-base text-primary-color text-left text-primary-dark-color">
                Please Enter the Mobile associated with your account
              </p>
              <div className="my-4 ">
                <Input
                  size="lg"
                  className="text-dark-color"
                  label="Mobile"
                  type="number"
                  onChange={(e) => {
                    if (e.target.value.length > 10) return false;
                    setMobile(e.target.value)
                  }}
                  value={mobile}
                />
              </div>
              <div className="mt-4">
                <button className="bg-primary-dark-color text-light-color font-bold py-2 px-4 w-full rounded hover:opacity-90" onClick={() => sendOTP()}>
                  Send OTP
                </button>
              </div>
            </div> :
            (OTPverify === false) ?
              <div>
                <p className="text-base text-primary-color text-left text-primary-dark-color">
                  Please Enter the OTP sent to your mobile.
                </p>
                <div className="my-4 ">
                  <Input
                    size="lg"
                    className="text-dark-color"
                    label="OTP"
                    type="number"
                    onChange={(e) => {
                      if (e.target.value.length > 4) return false;
                      setOTP(e.target.value)
                    }}
                    value={OTP}
                  />
                </div>
                <div className="mt-4">
                  <button className="bg-primary-dark-color text-light-color font-bold py-2 px-4 w-full rounded hover:opacity-90" onClick={() => verifyOTP()}>
                    Reset Password
                  </button>
                </div>
              </div> :
              <div>
                <p className="text-base text-primary-color text-left text-primary-dark-color">
                  Please Enter New Password.
                </p>
                <div className="my-4 ">
                  <Input
                    size="lg"
                    className="text-dark-color"
                    label="New Password"
                    type="password"
                    onChange={(e) => {
                      setNewPassword(e.target.value)
                    }}
                    value={newPassword}
                  />
                </div>
                <div className="my-4 ">
                  <Input
                    size="lg"
                    className="text-dark-color"
                    label="Confirm Password"
                    type="password"
                    onChange={(e) => {
                      setConfirmPassword(e.target.value)
                    }}
                    value={confirmPassword}
                  />
                </div>
                <div className="mt-4">
                  <button className="bg-primary-dark-color text-light-color font-bold py-2 px-4 w-full rounded hover:opacity-90" onClick={() => changePassword()}>
                    Reset Password
                  </button>
                </div>
              </div>
          }
          <div className="w-full my-2 flex justify-end">
            {OTPSent === true && <div className="text-[12px] text-primary-dark-color cursor-pointer" onClick={() => {
              setMobile("");
              setOTP("");
              setNewPassword("");
              setConfirmPassword("");
              setOTPSent(false);
              setOTPVerify(false);
            }}>Change Mobile Number</div>}
          </div>
          <div className="h-0.5 bg-grey-400 rounded-sm "></div>

          <div className="px-1">
            <button
              className="bg-white outline outline-offset-2 outline-2 outline-[#000000] text-[#002a1b] font-bold mt-5 py-[6px] px-4 w-full rounded hover:opacity-90"
              onClick={() => {
                navigate("/login");
              }}
            >
              Don't want to reset ? Sign In
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
