import React from 'react'

const HeadTop = () => {
    return (
        <>
            <div className='head-top-2 relative'>
                <div className='w-full h-full overflow-hidden absolute bg-gradient-class'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
                        <pattern patternUnits="userSpaceOnUse" width="52" height="90" id="p" className='rotate-90'>
                            <path fill="none" stroke="var(--honeyComb)" strokeWidth="3" d="m0,15 26-15 26,15v30l-26,15-26-15zM26,60v30" /></pattern>
                        <rect width="100%" height="100%" fill="url(#p)" />
                    </svg>
                </div>
                <div className='flex flex-col justify-center items-center relative py-[50px]'>
                    {/* <h1 className='absolute md:text-7xl text-4xl uppercase font-bold text-white/50 animate__animated animate__fadeInUp'>Privacy Policy</h1> */}
                    <h1 className='md:mt-3 md:text-5xl text-2xl font-bold text-primary-dark-color font-montserrat uppercase animate__animated animate__fadeInLeft'>Cancellation & Refund Policy</h1>
                </div>
                <div className='w-full rotate-180'>
                    <svg id="wave" className='absolute bottom-[-5px] left-0' viewBox="0 0 1440 170" version="1.1" xmlns="http://www.w3.org/2000/svg"><defs><linearGradient id="sw-gradient-0" x1="0" x2="0" y1="1" y2="0"><stop stopColor="var(--primaryLightColor)" offset="0%"></stop></linearGradient></defs><path fill="url(#sw-gradient-0)" d="M0,85L720,136L1440,85L2160,119L2880,85L3600,102L4320,51L5040,119L5760,136L6480,0L7200,0L7920,34L8640,119L9360,136L10080,68L10800,85L11520,102L12240,17L12960,102L13680,68L14400,17L15120,85L15840,68L16560,119L17280,119L17280,170L16560,170L15840,170L15120,170L14400,170L13680,170L12960,170L12240,170L11520,170L10800,170L10080,170L9360,170L8640,170L7920,170L7200,170L6480,170L5760,170L5040,170L4320,170L3600,170L2880,170L2160,170L1440,170L720,170L0,170Z"></path></svg>
                </div>
            </div>
        </>
    )
}

export default HeadTop