import React from 'react'
import Logo from '../../assets/images/logo.png'
import {
    XIcon
} from "@heroicons/react/outline";
import { NavLink } from "react-router-dom";

const CartSidebar = ({ isOpenCartSidebar, setIsOpenCartSidebar }) => {
    const ToggleCartSidebar = () => {
        isOpenCartSidebar === true
            ? setIsOpenCartSidebar(false)
            : setIsOpenCartSidebar(true);
    };
    return (
        <>
            <div
                className={`sidebar-right p-2 ${isOpenCartSidebar === true ? "active overflow-hidden" : ""}`}
            >
                <div className='flex justify-end items-center'>
                <button className='p-2 cursor-pointer' style={{ color: "var(--primaryDarkColor)", background: "var(--primaryLightColor)", clipPath: "polygon(0 0, 80% 0, 100% 20%, 100% 100%, 20% 100%, 0 80%)" }} onClick={ToggleCartSidebar}>
                            <XIcon className='w-6 h-6' />
                        </button>
                </div>
                <div className="sidebar-right-body p-1 flex flex-col justify-center items-center h-[90vh]">
                    

                    
                </div>

            </div>
            <div
                className={`sidebar-right-overlay ${isOpenCartSidebar === true ? "active" : ""
                    } w-100%`}
                onClick={ToggleCartSidebar}
            ></div>
        </>
    )
}

export default CartSidebar