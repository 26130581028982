import React, { useEffect, useState } from 'react'
import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import { useSelector, useDispatch } from 'react-redux';
import { searchProduct } from '../../actions';


const LeftSection = () => {
    const state = useSelector(state => state);
    const dispatch = useDispatch();

    const [search, setSearch] = useState("");
    
    const setCategory = (e, category) => {
        document.querySelectorAll(".food-type-button").forEach(button => button?.classList.remove('selected-category-button'));
        document.querySelectorAll(".category-button").forEach(button => button?.classList.remove('selected-category-button'));
        e?.classList.add('selected-category-button');
        setSearch("");
        dispatch(searchProduct({ branch: state.currentBranch, type: "category", category: category }));
    }
    const setFoodType = (e, type) => {
        document.querySelectorAll(".category-button").forEach(button => button?.classList.remove('selected-category-button'));
        document.querySelectorAll(".food-type-button").forEach(button => button?.classList.remove('selected-category-button'));
        e?.classList.add('selected-category-button');
        setSearch("");
        dispatch(searchProduct({ branch: state.currentBranch, type: "food-type", foodType: type }));
    }
    useEffect(() => {
        if (state.currentBranch !== null)
            dispatch(searchProduct({ branch: state.currentBranch, type: "search", search: search }));
    }, [search, state.currentBranch, dispatch]);

    return (
        <div className='md:w-1/4 w-full bg-footer h-full md:sticky md:top-[100px] '>
            {/* <div className='p-2 relative border-radius-5'>
                <input type="search" className='w-full bg-white border-radius-5  text-black p-2 font-roboto focus:outline-none pr-6' placeholder='Search Product' onChange={(e) => setSearch(e.target.value)} value={search} />
            </div> */}
            <Accordion allowZeroExpanded={true} preExpanded={['category']}>
                <AccordionItem className='mb-2' uuid='category'>
                    <AccordionItemHeading className='bg-primary-dark-color p-2 uppercase font-bold text-light-color font-roboto border-radius-5'>
                        <AccordionItemButton>
                            Category
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className='bg-footer'>
                        <div className='p-2 flex flex-col justify-center items-center gap-2'>
                            <button className='bg-primary-color w-full text-light-color p-2 font-bold font-roboto category-button selected-category-button border-radius-5' onClick={(e) => { setCategory(e.target, "") }} >All Products</button>
                            {(state.categories.length > 0) &&
                                state.categories.map(category =>
                                    <button className='bg-primary-color w-full text-light-color p-2 font-bold font-roboto category-button border-radius-5' key={category.category_id} onClick={(e) => { setCategory(e.target, category) }} >{category.category_list.category_name}</button>
                                )}
                        </div>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem className='mb-2'>
                    <AccordionItemHeading className='bg-primary-dark-color p-2 uppercase font-bold text-light-color font-roboto border-radius-5'>
                        <AccordionItemButton>
                            Food Type
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className='bg-footer'>
                        <div className='p-2 flex flex-col justify-center items-center gap-2'>
                            <button className='bg-primary-color w-full text-light-color p-2 font-bold font-roboto food-type-button border-radius-5' onClick={(e) => { setFoodType(e.target, 'Veg') }} >Veg</button>
                            <button className='bg-primary-color w-full text-light-color p-2 font-bold font-roboto food-type-button border-radius-5' onClick={(e) => { setFoodType(e.target, 'Non-Veg') }} >Non Veg</button>
                        </div>
                    </AccordionItemPanel>
                </AccordionItem>
                {/* <AccordionItem>
                    <AccordionItemHeading className='bg-primary-dark-color p-2 uppercase font-bold text-light-color font-roboto border-radius-5'>
                        <AccordionItemButton>
                            Search
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className='bg-footer'>
                        <div className='p-2 relative border-radius-5'>
                            <input type="search" className='w-full bg-white border-radius-5  text-black p-2 font-roboto focus:outline-none pr-6' placeholder='Search Product' onChange={(e) => setSearch(e.target.value)} value={search} />
                        </div>
                    </AccordionItemPanel>
                </AccordionItem> */}
            </Accordion>
        </div>
    )
}

export default LeftSection