import React from "react";
import { useState } from "react";
import { ReactComponent as LoginIcon } from "../assets/images/heebeeIcon.svg";
import { Link } from "react-router-dom";
import { Input } from "@material-tailwind/react";
import { useNavigate } from "react-router";
const NewPassword = () => {
  const navigate = useNavigate();

  const [currentpassword, setCurrentPassword] = useState("");
  const [newpassword, setNewPassword] = useState("");
  const [renewpassword, setRenewPassword] = useState("");
  return (
    <div className="py-6 w-screen h-screen flex flex-row justify-center items-center bg-footer">
      <div className="flex bg-modal rounded-lg shadow-lg overflow-hidden mx-auto w-[24rem] lg:w-[50rem]">
        <div className="hidden lg:flex lg:w-1/2 bg-cover bg-primary-color justify-center items-center">
          <LoginIcon className=" fill-color mt-1 ml-1" />
        </div>
        <div
          className={`relative w-full p-8 lg:w-1/2 flex flex-col justify-center animate__animated animate__fadeInRight`}
        >
          <div className="absolute top-3 left-3 cursor-pointer">
            <Link to="/">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
              </svg>
            </Link>
          </div>
          <h1 className="font-bold text-primary-color text-center text-4xl mt-2">
            Hee<span className="text-primary-dark-color ">bee</span>
          </h1>
          <p className="text-xl text-primary-color text-center font-bold mt-2 text-primary-dark-color">
            Create New Password
          </p>
          <div className="my-4 px-2">
            <Input
              size="lg"
              className="text-dark-color"
              label="Enter Current Password"
              onChange={(e) => setCurrentPassword(e.target.value)}
              value={currentpassword}
              type="password"
            />
          </div>
          <div className="my-4 px-2">
            <Input
              size="lg"
              className="text-dark-color"
              label="Enter New Password"
              onChange={(e) => setNewPassword(e.target.value)}
              value={newpassword}
              type="password"
            />
          </div>
          <div className="my-4 px-2">
            <Input
              size="lg"
              className="text-dark-color"
              label="Re-Enter New Password"
              onChange={(e) => setRenewPassword(e.target.value)}
              value={renewpassword}
              type="password"
            />
          </div>
          <div className="mt-4">
            <button
              className="bg-primary-dark-color text-light-color font-bold py-2 px-4 w-full rounded hover:opacity-90"
              onClick={() => {
                navigate("/login");
              }}
            >
              Reset Password
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewPassword;
