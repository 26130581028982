import React from 'react'
import Coffee4 from '../../assets/images/coffee (4).jpg'

const Coffee1 = 'https://storage.googleapis.com/heebee_website_images/about_page/coffee_1.jpg'
const Coffee2 = 'https://storage.googleapis.com/heebee_website_images/about_page/coffee_2.jpg'
const Coffee3 = 'https://storage.googleapis.com/heebee_website_images/about_page/coffee_3.jpg'

const gavish = 'https://storage.googleapis.com/heebee_website_images/about_page/gavish.jpg'
const nitigya = 'https://storage.googleapis.com/heebee_website_images/about_page/nitigya.jpg'

const Content = () => {
    return (
        <div className='md:container md:mx-auto px-6'>
            <h6 className='font-bold text-lg text-gray-600 font-montserrat p-6 text-center'>“Brewing the perfect cup of coffee is not a task, but a craft...”<br/><br/>Two friends, small savings, big dreams and an enthusiastic passion for Coffee. What began as a humble one shack in a quiet neighbourhood in 2018, has today become a brand serving 2600+ heterogeneous coffees and fusion food.<br/><br/>Dreams, they say, have wings. Well, guess what? OURS HAVE BEANS!</h6>
            <div className='grid md:grid-cols-2 grid-cols-1 mb-4  border-radius-20'>
                <div className='flex justify-start p-6 w-full animate__animated animate__fadeInLeft  border-radius-20'>
                    <img src={Coffee1} alt="About" className='clip-path-sm object-fit  bg-primary-light-color p-2 hover:grayscale-[50%] transition duration-700 ease-in-out border-radius-20' />
                </div>
                <div className='flex flex-col justify-center items-start animate__animated animate__fadeInUp'>
                    <h4 className='font-bold text-3xl text-primary-dark-color font-montserrat'>WHO ARE WE?</h4>
                    <h6 className='font-bold text-lg text-gray-600 font-montserrat'><br/>Heebee is not a café, but an emotion for people who have been a part of our voyage for almost half a decade, to have served more than 300000+ patrons from all parts of the region has given us a sense of contentment for which we started upon our journey.<br/><br/>It is the favourite destination for connoisseurs of rich delectable taste for coffees, from latte to cappuccino to americano to fapio, Heebee caters to all preferences of our coffee enthusiasts.</h6>
                </div>
            </div>
            <div className='grid md:grid-cols-2 grid-cols-1 mb-4 border-radius-20'>
                <div className='flex flex-col justify-center items-start order-1 animate__animated animate__fadeInUp'>
                    <h4 className='font-bold text-3xl text-primary-dark-color font-montserrat'>EVERYTHING WE BREW, WE BREW IT FOR YOU!</h4>
                    <h6 className='font-bold text-lg text-gray-600 font-montserrat'><br/>Coffee is an intricate mixture of more than thousands of essence, and we dedicatedly handcraft our very best just for you. We're committed to delivering you a world-class coffee with every cup you sip.<br/><br/>Our coffee beans are organically grown and roasted and sourced from the foliage of South India by farmers who smile with each cup of coffee you taste. We focus on promoting a sustainable farming and a better outcome for all.<br/><br/>Our love for creation and experimentation with different flavours has led to the creation of more than 2600 combinations of coffees that we currently serve, and yes we are continuing to innovate each day. All the recipes we serve are hand-curated keeping it's nutritional value in mind.</h6>
                </div>
                <div className='flex justify-start p-6 w-full md:order-2 animate__animated animate__fadeInLeft border-radius-20'>
                    <img src={Coffee2} alt="About" className='clip-path-sm object-fit  bg-primary-light-color p-2 hover:grayscale-[50%] transition duration-700 ease-in-out border-radius-20' />
                </div>
            </div>
            <div className='grid md:grid-cols-2 grid-cols-1 mb-4 border-radius-20'>
                <div className='flex justify-start p-6 w-full animate__animated animate__fadeInLeft border-radius-20'>
                    <img src={Coffee3} alt="About" className='clip-path-sm object-fit  bg-primary-light-color p-2 hover:grayscale-[50%] transition duration-700 ease-in-out border-radius-20' />
                </div>
                <div className='flex flex-col justify-center items-start animate__animated animate__fadeInUp'>
                    <h4 className='font-bold text-3xl text-primary-dark-color font-montserrat'>ABOUT THE BEE</h4>
                    <h6 className='font-bold text-lg text-gray-600 font-montserrat'><br/>Heebee's modern interiors are designed to give our patrons a warm and inviting space to relax and spend time with good company with the assurance of lip-smacking taste and a vibrant atmosphere. Celebrating the coffee culture through our extravagant interior and aesthetics bringing one-of-a-kind taste and palette was our motto from the beginning.. our loyalty towards coffee, quality and consistency are what stand us apart from others...<br/><br/>From artsy fusions to tempting bakery products we have it all and are still working every day on expanding our menu to reach out to a wider audience.</h6>
                </div>
            </div>
            {/* <div className='grid md:grid-cols-2 grid-cols-1 mb-4 border-radius-20'>
                <div className='flex flex-col justify-center items-start order-1 animate__animated animate__fadeInUp'>
                    <h4 className='font-bold text-3xl text-primary-dark-color font-montserrat'>WHY ‘HEEBEE'?</h4>
                    <h6 className='font-bold text-lg text-gray-600 font-montserrat'>HeeBee is a result of the past two years of research, experimenting, and tasting. The owners admit that coming up with a name was a difficult and easy process at the same time. They had been thinking of a name that is striking and matched their moto of café. One day after pondering over a name for a month, they were sitting in a park, when they observed a beehive. They both started discussing over how bees were solely dedicated to one thing all their life- producing honey and how the cells of a beehive were all intact and connected without a single space in between them. It was then it struck both of them to include bee in their name as it would go with the moto of consistency, quality, and loyalty. So Bee was included and Hee just tagged along.<br /><br />In owners' words ‘We are loyal like a bee but towards coffee. We are intact as a beehive because we take our customers, employees, and everyone connected to us along because we are who we are only because of them.</h6>
                </div>
                <div className='flex justify-start p-6 w-full md:order-2 animate__animated animate__fadeInLeft border-radius-20'>
                    <img src={Coffee4} alt="About" className='clip-path-sm object-fit  bg-primary-light-color p-2 hover:grayscale-[50%] transition duration-700 ease-in-out border-radius-20' />
                </div>
            </div> */}
            <h4 className='font-bold text-3xl text-primary-dark-color font-montserrat text-center p-6 mt-6'>THE BEE TEAM!</h4>

            <div className='grid md:grid-cols-2 grid-cols-1 mb-4 border-radius-20 p-6'>
                <div className='flex justify-center w-full animate__animated animate__fadeInLeft'>
                    <div>
                        <img src={gavish} alt="About" className='clip-path-sm object-fit  bg-primary-light-color p-2 hover:grayscale-[50%] transition duration-700 ease-in-out border-radius-20 h-[200px] m-auto' style={{borderRadius: '50%'}} />
                        <h4 className='font-bold text-3xl text-primary-dark-color font-montserrat text-center pt-6'>Gavish Batra</h4>
                        <h6 className='font-bold text-lg text-gray-600 font-montserrat text-center'><br/>Co-Founder</h6>
                    </div>
                    
                </div>
                <div className='flex justify-center items-start animate__animated animate__fadeInUp'>
                    <div>
                        <img src={nitigya} alt="About" className='clip-path-sm object-fit  bg-primary-light-color p-2 hover:grayscale-[50%] transition duration-700 ease-in-out border-radius-20 h-[200px] m-auto' style={{borderRadius: '50%'}} />
                        <h4 className='font-bold text-3xl text-primary-dark-color font-montserrat text-center pt-6'>Nitigya Soni</h4>
                        <h6 className='font-bold text-lg text-gray-600 font-montserrat text-center'><br/>Co-Founder</h6>
                    </div>
                </div>
            </div>

            <h6 className='font-bold text-lg text-gray-600 font-montserrat p-6 text-center'>The passion for coffee has always been the linking dot for our co-founders, <span className='text-primary-dark-color'>Gavish Batra</span> and <span className='text-primary-dark-color'>Nitigya Soni</span>, which has always been the driving wheel of our association. Their passion involves not only serving great coffee but also educating people about the benefits and process of how coffee is brewed.<br/><br/>Our values reflect who we are and how we approach everything we do.. these are not values that change from time to time or situation to situation but rather they are the underpinning of our company culture. By maintaining these core values, regardless of how large a company we become, we can preserve what has always been special about our vital organisation. Our partnership is based on integrity and trust and we're committed to providing nothing but excellence and continuous innovation.<br/><br/>We are also a dynamic team of skilled and talented professionals who are passionate about Heebee Coffee's growth and are delivering a unique experience to as many coffee fans in the area as we can reach.</h6>
        </div>
    )
}

export default Content