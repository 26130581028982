import React, { useState, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from 'react-redux';
import DatePicker from 'react-date-picker';
import axios from "axios";
import { useAlert } from "react-alert";
import { setCustomerDetails } from "../../actions";
import { Menu, Dialog, Transition } from "@headlessui/react";
import { IoWalletOutline } from "react-icons/io5";
import makePayment from "../../fntendpaytm";
const { REACT_APP_API_ENDPOINT } = process.env;

const ChangePasswordRightSection = () => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const state = useSelector(state => state);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [date, setDate] = useState("");
  const [email, setEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [bgColor, setBgColor] = useState("-blue-200");
  const [amountToBeAdded, setAmountToBeAdded] = useState(0);
  useEffect(() => {
    if (state.customerDetails !== null) {
      let cd = state.customerDetails;
      setFirstName(cd.first_name);
      if (cd.last_name !== null) setLastName(cd.last_name);
      setEmail(cd.email);
      if (cd.date_of_birth !== null) {
        let dob = cd.date_of_birth;
        let d = dob.split("-");
        d = new Date(d[0], d[1] - 1, d[2]);
        setDate(d);
      }
      switch (cd.customer_role.customer_type) {
        case "Gold": setBgColor("-yellow-500"); break;
        case "Silver": setBgColor("-grey-500"); break;
        case "Platinum": setBgColor("-grey-300"); break;
        case "General": setBgColor("-blue-500"); break;
        default: setBgColor("-blue-500");
      }
    }
  }, [state.customerDetails])

  const pay = () => {
    if (isNaN(amountToBeAdded) === false && amountToBeAdded > 0) {
      const body = {
        amount: Number(amountToBeAdded),
        branch_id: state.currentBranch.branch_id,
        branch_name: state.currentBranch.branch_name
      };
      const token = state.token;
      makePayment(body, token);
    }
    else {
      alert.error("Invalid Amount !")
    }
  }

  const [isAddMoneyOpen, setIsAddMoneyOpen] = useState(false);
  const [addingMoney, setAddingMoney] = useState(0);
  const [disabled, setDisabled] = useState(true);

  const closeAddMoneyModal = () => {
    setIsAddMoneyOpen(false);
    setAddingMoney(0);
  }

  useEffect(() => {
    if (addingMoney > 0) setDisabled(false);
    else setDisabled(true);
  }, [addingMoney])


  const saveDetails = () => {
    let user = {
      mobile_no: Number(state.customerDetails.mobile_no),
      first_name: firstName,
      last_name: lastName,
      date_of_birth: `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
      email: email
    };

    axios.post(`${REACT_APP_API_ENDPOINT}/edit_profile`, user, {
      headers: {
        token: state.token
      }
    })
      .then(res => {
        if (res.data.status === "success") {
          alert.success("Profile has been updated successfully");
          dispatch(setCustomerDetails(state.token));
        }
        else
          alert.error(res.data.msg)
      })
      .catch(() => alert.error("error occured"));
  }
  const changePassword = () => {
    if (newPassword === "" || confirmPassword === "") alert.error("Passsords can't be empty !");
    else if (newPassword === confirmPassword) {
      let user = {
        mobile_no: Number(state.customerDetails.mobile_no),
        password: newPassword
      };
      axios.post(`${REACT_APP_API_ENDPOINT}/change_password`, user, {
        headers: {
          token: state.token
        }
      })
        .then(res => {
          if (res.data.status === "success") {
            alert.success("Password has been updated successfully");
            setNewPassword("");
            setConfirmPassword("");
            dispatch(setCustomerDetails(state.token));
          }
          else
            alert.error(res.data.msg)
        })
        .catch(() => alert.error("error occured"));
    }
    else {
      alert.error("Passsords don't match !")
    }
  }
  const changeDate = (e) => {
    setDate(e);
  }
  return (
    <>
      <div className="w-full md:max-w-[1000px] mt-2 md:mt-0 px-2 md:px-2 md:pr-2 right-section bg-white/10 p-4 rounded-[10px]">
        <div className="px-4">
          <div className="text-primary-dark-color text-2xl font-bold tracking-wide mb-8 text-center uppercase">Change Password</div>
          <div className="flex flex-wrap justify-between">
            <div className="w-full md:w-[49%] my-1">
              <label
                htmlFor="new_password"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                New Password
              </label>
              <input
                type="password"
                name="new_password"
                className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-primary-light-color bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-primary-light-color focus:border-[#824a19] mb-3 focus:outline-none"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </div>
            <div className="w-full md:w-[49%] my-1">
              <label
                htmlFor="confirm_password"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Confirm Passsword
              </label>
              <input
                type="password"
                name="email_address"
                autoComplete="email"
                className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-primary-light-color bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-primary-light-color focus:border-[#824a19] mb-3 focus:outline-none"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
            <div className="w-full flex justify-center">
              <button className="bg-primary-dark-color text-light-color font-bold px-3 py-2 text-lg rounded-md hover:opacity-[90%]" onClick={changePassword}>Change Password</button>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default ChangePasswordRightSection;
