import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useSelector } from 'react-redux';


const CartPageRightSection = () => {
  const state = useSelector(state => state);
  const navigate = useNavigate();
  const [amount, setAmount] = useState(0);
  const [CGST, setCGST] = useState(0);
  const [SGST, setSGST] = useState(0);
  const [member_discount, setMD] = useState(0);
  useEffect(() => {
    let sum = 0;
    state.cartItems.forEach(item => sum = sum + item.amount);
    setAmount(sum.toFixed(2));
  }, [state.cartItems]);
  useEffect(() => {
    if (state.customerDetails !== null) {
      if (state.currentCoupon) {
        if (state.currentCoupon.memb_apply) {
          let mdis = 0;
          // setMD((state.customerDetails.customer_role.disc_percent * 0.01 * amount).toFixed(2));
          const mem_b_discount = state.customerDetails?.customer_role?.barista_disc_percent || 0;
          const mem_k_discount = state.customerDetails?.customer_role?.kitchen_disc_percent || 0;
          state.cartItems.forEach(i => {
            if (i.product.product_list.product_type === "Barista") {
              mdis += (mem_b_discount * 0.01 * i.amount);
            }
            else {
              mdis += (mem_k_discount * 0.01 * i.amount);
            }
          });
          setMD(mdis);
        }
        else {
          setMD(0);
        }
      }
      else {
        // setMD((state.customerDetails.customer_role.disc_percent * 0.01 * amount).toFixed(2));
        let mdis = 0;
        // setMD((state.customerDetails.customer_role.disc_percent * 0.01 * amount).toFixed(2));
        const mem_b_discount = state.customerDetails?.customer_role?.barista_disc_percent || 0;
        const mem_k_discount = state.customerDetails?.customer_role?.kitchen_disc_percent || 0;
        state.cartItems.forEach(i => {
          if (i.product.product_list.product_type === "Barista") {
            mdis += (mem_b_discount * 0.01 * i.amount);
          }
          else {
            mdis += (mem_k_discount * 0.01 * i.amount);
          }
        });
        setMD(mdis);
      }
    }
  }, [state.customerDetails, amount])
  useEffect(() => {
    let tax = ((amount - member_discount) * 0.05);
    let x = (tax / 2).toFixed(2);
    setCGST(x);
    setSGST(x);
  }, [amount, member_discount])

  return (
    <div className=" flex flex-col basis-1/3 bg-primary-light-color px-6 py-4 m-auto my-8 border-radius-5 h-fit animate__animated animate__fadeInRight sidediv w-[95%] max-w-[350px] sm:w-[400px] sm:max-w-full">
      <div className="flex flex-row w-full justify-between text-primary-dark-color text-lg font-roboto">
        <div className="flex flex-col font-bold tracking-wide">
          <div>Sub Total</div>
          <div>Member Discount</div>
          <div>SGST</div>
          <div>CGST</div>
          <div>Grand Total</div>
        </div>
        <div className="flex flex-col">
          <div>₹ {amount}</div>
          <div>₹ {member_discount}</div>
          <div>₹ {SGST}</div>
          <div>₹ {CGST}</div>
          <div>₹ {(Number(amount) + Number(CGST) + Number(SGST) - Number(member_discount)).toFixed(2)}</div>
        </div>
      </div>
      <div
        className="w-full py-2 px-6 cursor-pointer bg-primary-dark-color hover:opacity-[0.9] text-center text-light-color text-xl font-bold tracking-wider font-roboto mt-4 border-radius-5"
        onClick={() => {
          navigate("/checkout");
        }}
      >
        Checkout
      </div>
    </div>
  );
};

export default CartPageRightSection;
