import React from 'react';
import logo from '../assets/images/logo2.png'
import { BsExclamationCircle } from "react-icons/bs";
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const PaymentFail = () => {
    const navigate = useNavigate()
    const [seconds, setSeconds] = useState(5);
    useEffect(() => {
        if (seconds > 0)
            setTimeout(() => {
                setSeconds(seconds - 1);
            }, [1000]);
        else
            navigate('/');
    }, [seconds]);
    return (
        <div className='w-full h-screen bg-primary-light-color flex justify-center items-center p-3'>
            <div className='bg-primary-color border-radius-10 sm:p-8 p-2'>
                <div className='flex justify-center items-center'>
                    <img src={logo} alt="logo" className=' w-[60px] mr-4' />
                </div>
                <div className='w-full h-full flex-shrink mt-3'>
                    <div className='flex justify-center items-center mt-6'>
                        <div className='text-2xl sm:text-3xl md:text-4xl font-bold mr-3 text-primary-light-color'>Payment Failed</div>
                        <BsExclamationCircle className='text-red-900 text-2xl sm:text-3xl md:text-4xl' />
                    </div>
                    <div className='mt-6 text-primary-light-color sm:text-xl text-sm'>
                        <div>We were unable to process your last payment.<br /> This could have happened for a number of reasons:</div>
                        <ul className='list-disc sm:ml-10 ml-4 mt-2'>
                            <li>Your card has expired;</li>
                            <li>Lack of funds or funds on hold;</li>
                            <li>Your bank declined the transaction for a reason unknown to us.</li>
                        </ul>
                    </div>
                    <div className='text-center my-4 text-xl sm:text-2xl md:text-3xl text-primary-light-color'>Redirecting in {seconds} seconds...</div>
                    <div className="flex justify-center">
                        <div className="button-primary px-2 sm:px-8 py-2 uppercase text-lg font-roboto border-radius-5 cursor-pointer w-1/2 flex justify-center" onClick={() => setSeconds(0)}>Go to Home</div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default PaymentFail;
