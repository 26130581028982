import React, { useState } from "react";
import OrderCard from "./OrderCard";
import { useSelector, useDispatch } from 'react-redux';
import { updateOrders } from "../../actions";
import InfiniteScroll from 'react-infinite-scroll-component';
import axios from 'axios';
const { REACT_APP_API_ENDPOINT } = process.env;

const MyOrdersRightSection = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state);

  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const fetchData = () => {
    axios.get(`${REACT_APP_API_ENDPOINT}/fetch_all_orders/${page + 1}?customer_no=${state.customerDetails.mobile_no}`, {
      headers: {
        token: state.token
      }
    })
      .then(res => {
        if (res.data.status !== "failure") {
          if (res.data.data.length < 10) setHasMore(false);
          dispatch(updateOrders(res.data.data));
        }
        else {
          setHasMore(false);
        }
      })
  }
  return (
    <div className="w-full md:max-w-[1000px] px-4 right-section  p-4 rounded-[10px]">
      {(state.orders.length > 0) ? <div id="scrollableDiv" className='w-full'>
        <div className='flex flex-col'>
          {state.orders.map((order, index) => <OrderCard order={order} key={index} />)}</div>
      </div> : <div className='text-primary-dark-color text-3xl'>No Orders Found</div>}
    </div>
  );
};

export default MyOrdersRightSection;
