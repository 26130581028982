import { combineReducers } from "redux";
import loginCustomer from "./loginCustomer";
import customerDetails from "./setCustomerDetails";
import getBranches from './getBranches';
import currentBranch from './currentBranch';
import getCategories from "./getCategories";
import getProducts from "./getProducts";
import bestSeller from "./bestSeller";
import setCartItems from "./setCartItems";
import pickUpTime from './pickUpTime';
import getCoupons from './getCoupons';
import fetchAllOrders from "./fetchAllOrders";
import setQuery from "./setQuery";
import setTheme from "./setTheme";
import mostOrderItems from "./mostOrderItems";
import unclaimGiftcardItems from "./unclaimGiftcard";
import claimedGiftcardItems from "./claimedGiftcard";
import sentGiftcardItems from "./sentGiftcard";
import addNewGiftcard from "./AddNewGiftcard";
import getPromoCode from "./getPromoCode";
import setPromoCode from "./setPromoCode";
import customerRoles from './customerRoles'
import currentCoupon from "./currentCoupon";

const rootReducer = combineReducers({
    theme: setTheme, // Change Theme
    token: loginCustomer, // Login and Logout
    customerDetails: customerDetails, // Setting Customer details on login or logout
    branches: getBranches, // Branches
    currentBranch: currentBranch, // Current Selected Branch
    categories: getCategories, // Categroies
    products: getProducts, // All Products
    query: setQuery, // Query for Pagination URL
    bestSelling: bestSeller, // Top Products on Home Page
    cartItems: setCartItems, // Products in Cart
    pickUpTime: pickUpTime, // Pickup timing on Checkout Page
    coupons: getCoupons, // Coupons on checkout page
    orders: fetchAllOrders, // All orders of customer
    mostOrderItems: mostOrderItems,//most order items
    unclaimGiftcardItems: unclaimGiftcardItems, //unclaim Giftcard Items
    claimedGiftcardItems: claimedGiftcardItems, //claimed Giftcard Items
    sentGiftcardItems: sentGiftcardItems, //sent Giftcard Items
    addNewGiftcard: addNewGiftcard, //add New Giftcard
    promoCode: getPromoCode, //
    setPromoCode: setPromoCode, //
    customerRoles: customerRoles,
    currentCoupon: currentCoupon
});

export default rootReducer;