import { GET_PRODUCTS, UPDATE_PRODUCTS } from "../constants";
const getProducts = (products = [], action) => {
    if (action.type === GET_PRODUCTS) {
        if (action.payload !== undefined)
            return action.payload;
    } else if (action.type === UPDATE_PRODUCTS) {
        return action.payload;
    }
    return products;
}

export default getProducts;