import React from 'react'

const FacilityDiv = ({ item }) => {
  return (
    <div className='p-2 text-center facility-item animate__animated animate__fadeIn border-radius-5'>
      <div className='p-12 md:p-8'>
        <img src={item.image} alt="no found " className='object-cover' />
      </div>
      <h5 className='font-bold text-lg font-montserrat leading-tight line-clamp-2 pb-5'>{item.title}</h5>
      <p className='text-sm text-gray-500 line-clamp-5'>{item.description}</p>
    </div>
  )
}

export default FacilityDiv