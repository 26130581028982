import React from 'react'

const Content = () => {
    return (
        <div className='text-md text-gray-600 px-6 md:container md:mx-auto mt-[100px]'>
            <p>Last updated: April 1, 2023</p>
            <p>Thank you for shopping at HeeBee .</p>
            <br />
            <p className='font-bold'>Cancellation Policy:</p>
            <p>If you wish to cancel your order, please get in touch within 24 hours of placing the order or before the order is roasted(in case of coffee beans roasting order) &nbsp;or in case of (coffee as a beverage ) before the order/coffee is brewed/, whichever comes first.</p>
            <br />
            <p className='font-bold'>Return and Refund Policy:</p>
            <p>We are passionate about the quality of the coffee we roast /brew and sell and we can&apos;t wait for you to love it just as much! If for any reason you&apos;re not happy with the coffee beans you purchased from our website, please reach out to us within 2 days of your invoice date at care@heebee.in for a full / partial replacement or refund of the purchase price/ or kind in form of credits/voucher/gift card to your account,(depending upon the given situation or the best possible solution )Please make sure you include:</p>
            <p>1. Your name and date of purchase.&nbsp;</p>
            <p>2. The items you&apos;d like a refund/replace for.&nbsp;</p>
            <p>3. The reason for the return.&nbsp;</p>
            <p>4. A copy of the invoice/confirmation screenshot you received after payment.</p>
            <br />
            <p className='font-bold'>Please Note:</p>
            <p>We regret to inform you that we can only issue refunds for brewing equipment that is returned to us in new, unused condition. For coffee beverage purchases, refunds will be initiated only after verifying the authenticity of problem occurred in your drink/coffee beverage. Once you have notified us of your request for a refund/replacement, we will try our best to execute the problem you faced and initiate the refund / replace process within 7 days. The amount of time it takes for the money to be credited back to your account will depend on the clearing cycle of your bank. Your refund will be processed through the same payment method that you selected at the time of the transaction. You should expect to see the refund in your account within 8-9 working days.</p>
        </div>
    )
}

export default Content