import React from "react";
import { useState, useEffect } from "react";
import CheckoutPageLeftSection from "../components/checkout/CheckoutPageLeftSection";
import CheckoutPageRightSection from "../components/checkout/CheckoutPageRightSection";
import HeadTop from "../components/checkout/Headtop";
import Footer from "../components/common/Footer";
import Header from "../components/common/Header";
import TopBar from "../components/common/TopBar";
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router";
import Loader from "./Loader";

const CheckoutPage = () => {
  const state = useSelector(state => state);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (state.token === null) navigate('/login');
  }, [state.token, navigate]);

  return (
    loading ? <Loader /> :
    <>
      <TopBar />
      <Header />
      <HeadTop />
      <div className="flex flex-col md:flex-row w-full stick4 px-2 overflow-x-hidden">
        <CheckoutPageLeftSection />
          <CheckoutPageRightSection setLoading={setLoading} />
      </div>
      <Footer />
    </>
  );
};

export default CheckoutPage;
