import React, { useState } from "react";
import { useEffect } from "react";
import Footer from "../components/common/Footer";
import Header from "../components/common/Header";
import TopBar from "../components/common/TopBar";
import LeftBar from "../components/LeftBar";
import { Breadcrumbs } from "@material-tailwind/react";
import HeadTop from "../components/myorder/Headtop";
import MyOrdersRightSection from "../components/myorder/MyOrdersRightSection";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";

const MyOrdersPage = () => {
  const state = useSelector(state => state);
  const navigate = useNavigate();
  const [dist, setDist] = useState(undefined);
  useEffect(() => {
    const sidebar = document.querySelector(".stick2");
    const { top } = sidebar.getBoundingClientRect();
    setDist(top);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!dist) return;
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dist]);
  const isSticky = (e) => {
    const sidebarEl = document.querySelector(".sidediv");
    const scrollTop = window.scrollY;
    if (scrollTop >= 510) {
      sidebarEl?.classList.add("is-sticky");
    } else {
      sidebarEl?.classList.remove("is-sticky");
    }
  };
  return (
    <>
      <TopBar />
      <Header />
      <HeadTop />
      <div className="breadcrumbfullsize  mt-[100px]">
        <Breadcrumbs className="bg-primary-light-color mb-4">
          <div className={`text-dark-color mx-1 mbtb:mx-3 ${state.theme === "dark" ? 'hover:text-grey-400' : 'hover:text-grey-700'} flex justify-center items-center`} onClick={() => navigate('/')}>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
              <path strokeLinecap="round" strokeLinejoin="round" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
            </svg>
            <div className="hidden mbtb:block">Home</div>
          </div>
          <div className={`${state.theme === "dark" ? 'text-grey-400' : 'text-grey-600'} mx-1 mbtb:mx-3 flex justify-center items-center`}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 mr-2">
              <path strokeLinecap="round" strokeLinejoin="round" d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z" />
            </svg>
            <div className="hidden mbtb:block">My Orders</div>
          </div>
        </Breadcrumbs>
      </div>
      <div className="flex flex-col md:flex-row stick2 overflow-hidden">
        {/* <LeftBar />
        <MyOrdersRightSection /> */}
      </div>
      <div className="flex flex-col justify-center items-center md:container md:mx-auto p-4">
        <LeftBar />
        <MyOrdersRightSection />
      </div>
      <Footer />
    </>
  );
};

export default MyOrdersPage;
