import React, { useState, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from 'react-redux';
import DatePicker from 'react-date-picker';
import axios from "axios";
import { useAlert } from "react-alert";
import GiftcardImg0 from '../../assets/images/giftcard/0.png'
import GiftcardImg1 from '../../assets/images/giftcard/1.png'
import GiftcardImg2 from '../../assets/images/giftcard/2.png'
import GiftcardImg3 from '../../assets/images/giftcard/3.png'
import GiftcardImg4 from '../../assets/images/giftcard/4.png'
import { HiOutlineRefresh } from "react-icons/hi";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { unclaimGiftcardItems, addNewGiftcard, claimedGiftcardItems, sentGiftcardItems, setCustomerDetails } from "../../actions";
import Giftcard from "./Giftcard";
const { REACT_APP_API_ENDPOINT } = process.env;

const MyAccountRightSection = () => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const [openAdd, setOpenAdd] = useState(false);
  const [openOtp, setOpenOtp] = useState(false);
  const [giftcardButton, setGiftcardButton] = useState('unclaim');

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [amount, setAmount] = useState(0);
  const [senderNo, setSenderNo] = useState('');
  const [card, setCard] = useState('');
  const [otp, setOtp] = useState('');

  const state = useSelector(state => state);

  /*  const customer_id = state.customerDetails.customer_id;
   const branch_id = state.currentBranch.branch_id;
   useEffect(() => {
     dispatch(unclaimGiftcardItems(state.token, customer_id));
   }, [customer_id, dispatch]) */

  //console.log(state.unclaimGiftcardItems)

  const toggleAdd = () => {
    setOpenAdd(!openAdd);
  };

  const resetFormHandler = () => {
    setTitle('');
    setDescription('');
    setAmount(0);
    setSenderNo('');
    setCard('');
    document.querySelector('input[name="card"]:checked').checked = false;

  }

  const addNewGiftcardHandler = () => {
    if (title === '' || description === '' || amount === '' || senderNo === '' || card === '') {
      alert.error('All Fields are required')
    } else if (amount < 1) {
      alert.error('Amount must be a number')
    } else if (senderNo.length !== 10) {
      alert.error('Invalid Sender Mobile Number')
    } else {
      //alert.success('success')
      let giftcard = {
        title: title,
        description: description,
        customer_id: state.customerDetails.customer_id,
        amount: amount,
        mobile_no: senderNo
      };

      axios.post(`${REACT_APP_API_ENDPOINT}/add_gift_card`, giftcard, {
        headers: {
          token: state.token
        }
      })
        .then(res => {
          if (res.data.status === "success") {
            alert.success("Please Verify OTP");
            dispatch(addNewGiftcard(res.data));
            setOpenOtp(true);
            setTitle('');
            setDescription('');
            setAmount(0);
            setSenderNo('');
            setCard('');
            document.querySelector('input[name="card"]:checked').checked = false;
          }
          else
            alert.error(res.data.msg)
          setTitle('');
          setDescription('');
          setAmount(0);
          setSenderNo('');
          setCard('');
          document.querySelector('input[name="card"]:checked').checked = false;
        })
        .catch(() => {
          // alert.error("error occured")
        });
    }
  }

  const verifyGiftcardHandler = () => {
    if (otp === '') {
      alert.error('All Fields are required')
    } else if (otp.length !== 4) {
      alert.error('OTP must be 4 characters')
    } else {
      //alert.success('success')
      let otpData = {
        OTP: otp,
        gift_card_id: state.addNewGiftcard.data,
        customer_id: state.customerDetails.customer_id,
      };

      axios.post(`${REACT_APP_API_ENDPOINT}/verify_gift_card`, otpData, {
        headers: {
          token: state.token
        }
      })
        .then(res => {
          if (res.data.status === "success") {
            alert.success("Giftcard Sent Successfully");
            dispatch(addNewGiftcard(res.data));
            setOtp('');
            setOpenOtp(false);
          }
          else
            alert.error(res.data.msg)
          setOtp('');
        })
        .catch(() => {
          //alert.error("error occured")     
        });
    }
  }



  const unclaimGiftcardHandler = () => {
    const customer_id = state.customerDetails && state.customerDetails.customer_id;
    dispatch(unclaimGiftcardItems(state.token, customer_id));
  }

  const claimedGiftcardHandler = () => {
    const customer_id = state.customerDetails && state.customerDetails.customer_id;
    dispatch(claimedGiftcardItems(state.token, customer_id));
  }

  const sentGiftcardHandler = () => {
    const customer_id = state.customerDetails && state.customerDetails.customer_id;
    dispatch(sentGiftcardItems(state.token, customer_id));
  }

  useEffect(() => {
    unclaimGiftcardHandler();
  }, [])

 /*  useEffect(() => {
    claimedGiftcardHandler();
  }, [])

  useEffect(() => {
    sentGiftcardHandler();
  }, [])

  useEffect(() => {
    dispatch(setCustomerDetails(state.token));
    window.localStorage.setItem("heebee-cafe-login-token", state.token); // Setting current token in local storage
  }, [state.token, dispatch]); */

  return (
    <>
      <div className="rounded-2xl w-full md:max-w-[1000px] mb-4">
        <div className="flex justify-between items-center gap-3 mb-4">
          <div className="flex gap-3">
            <button className="bg-primary-color text-primary-light-color rounded-[5px] font-semibold p-2" onClick={() => { unclaimGiftcardHandler(); setGiftcardButton('unclaim'); }}>Unclaim Giftcard</button>
            <button className="bg-primary-color text-primary-light-color rounded-[5px] font-semibold p-2" onClick={() => { claimedGiftcardHandler(); setGiftcardButton('claimed'); }}>Claimed Giftcard</button>
            <button className="bg-primary-color text-primary-light-color rounded-[5px] font-semibold p-2" onClick={() => { sentGiftcardHandler(); setGiftcardButton('sent'); }}>Sent Giftcard</button>
          </div>
          <button className="bg-primary-dark-color text-primary-light-color rounded-[5px] font-semibold p-2" onClick={toggleAdd}>Add New Giftcard</button>
        </div>
        {openAdd && (
          <div className="bg-footer rounded-[10px] animate__animated animate__fadeIn p-4 mb-4">
            <h4 className="text-2xl uppercase font-semibold mb-3">Add New Giftcard</h4>
            <div className="flex flex-col md:flex-row gap-4">
              <div className="w-full md:w-1/2">
                <div className='flex flex-col gap-2 w-full'>
                  {/* <select className="bg-footer text-primary-dark-color p-2 border-2 border-radius-5 border-primary-dark-color focus:outline-none placeholder-primary-input" value={card} onChange={e => setCard(e.target.value)}>
                    <option value=''>Select Design</option>
                    <option value='heebee'>HeeBee Special</option>
                    <option value='friendship'>Friendship</option>
                    <option value='newyear'>Happy New Year</option>
                    <option value='christmas'>Merry Christmas</option>
                    <option value='diwali'>Happy Dewali</option>
                  </select> */}
                  <div className="flex flex-wrap justify-start items-center gap-2 mb-2">
                    <div className="flex flex-col justify-center items-center gap-2">
                      <img src={GiftcardImg0} alt="" className="h-[60px] p-1 bg-primary-dark-color rounded-[10px]" />
                      <input type="radio" className="w-4 h-4" name="card" value='heebee' onChange={e => setCard(e.target.value)} />
                    </div>
                    {/* <div className="flex flex-col justify-center items-center gap-2">
                      <img src={GiftcardImg1} alt="" className="h-[60px] p-1 bg-primary-dark-color rounded-[10px]" />
                      <input type="radio" className="w-4 h-4" name="card" value='friendship' onChange={e => setCard(e.target.value)} />
                    </div>
                    <div className="flex flex-col justify-center items-center gap-2">
                      <img src={GiftcardImg2} alt="" className="h-[60px] p-1 bg-primary-dark-color rounded-[10px]" />
                      <input type="radio" className="w-4 h-4" name="card" value='christmas' onChange={e => setCard(e.target.value)} />
                    </div>
                    <div className="flex flex-col justify-center items-center gap-2">
                      <img src={GiftcardImg3} alt="" className="h-[60px] p-1 bg-primary-dark-color rounded-[10px]" />
                      <input type="radio" className="w-4 h-4" name="card" value='diwali' onChange={e => setCard(e.target.value)} />
                    </div>
                    <div className="flex flex-col justify-center items-center gap-2">
                      <img src={GiftcardImg4} alt="" className="h-[60px] p-1 bg-primary-dark-color rounded-[10px]" />
                      <input type="radio" className="w-4 h-4" name="card" value='newyear' onChange={e => setCard(e.target.value)} />
                    </div> */}
                  </div>
                  <input type="text" className='bg-footer text-primary-dark-color p-2 border-2 border-radius-5 border-primary-dark-color focus:outline-none placeholder-primary-input' placeholder='Enter Title' value={title} onChange={e => setTitle(e.target.value)} required />
                  <textarea className='bg-footer text-primary-dark-color p-2 border-2 border-radius-5 border-primary-dark-color focus:outline-none placeholder-primary-input' rows="4" placeholder='Enter  Description' value={description} onChange={e => setDescription(e.target.value)} required ></textarea>
                  <input type="number" className='bg-footer text-primary-dark-color p-2 border-2 border-radius-5 border-primary-dark-color focus:outline-none placeholder-primary-input' placeholder='Enter Amount' value={amount} onChange={e => setAmount(e.target.value)} required />
                  <input type="number" className='bg-footer text-primary-dark-color p-2 border-2 border-radius-5 border-primary-dark-color focus:outline-none placeholder-primary-input' placeholder='Enter Sender Number' value={senderNo} onChange={e => setSenderNo(e.target.value)} required />
                  <div className="flex gap-2 mt-2">
                    {/* <button className='bg-primary-dark-color border-radius-5 text-light-color uppercase font-bold px-4 py-2 focus:outline-none'>Verify OTP</button> */}
                    <button className={`bg-primary-dark-color border-radius-5 text-light-color uppercase font-bold px-4 py-2 focus:outline-none ${openOtp && ' cursor-none  opacity-50'}`} onClick={addNewGiftcardHandler}>Submit</button>
                    <button className='bg-primary-dark-color border-radius-5 text-light-color uppercase font-bold px-4 py-2 focus:outline-none' onClick={resetFormHandler}><HiOutlineRefresh /></button>
                  </div>
                  {openOtp && <div className="flex gap-2 mt-2">
                    <input type="number" className='w-3/4 bg-footer text-primary-dark-color p-2 border-2 border-radius-5 border-primary-dark-color focus:outline-none placeholder-primary-input' placeholder='Enter OTP' value={otp} onChange={e => setOtp(e.target.value)} required />
                    <button className='w-1/4 bg-primary-dark-color border-radius-5 text-light-color uppercase font-bold px-4 py-2 focus:outline-none' onClick={verifyGiftcardHandler}>Verify</button>
                  </div>}
                </div>
              </div>
              <div className="w-full md:w-1/2 p-1">
                {card &&
                  <div className="flex justify-center items-center relative border-2 border-primary-dark-color rounded-3xl p-1 bg-white/10">
                    <img src={card === 'heebee' ? GiftcardImg0 : card === 'friendship' ? GiftcardImg1 : card === 'newyear' ? GiftcardImg4 : card === 'christmas' ? GiftcardImg2 : card === 'diwali' ? GiftcardImg3 : ''} alt="" className="w-full rounded-2xl" />
                    <div className="absolute top-8 left-4">
                      <h2 className="text-2xl font-semibold  text-primary-dark-color mb-4">{title}</h2>
                      <h2 className="text-5xl  text-primary-dark-color">{(amount >= 0) && '₹'}{amount}</h2>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        )}
        <div className="mb-4">
          {giftcardButton === 'sent' &&
            state.sentGiftcardItems && state.sentGiftcardItems.length > 0 ?
            <Carousel
              additionalTransfrom={0}
              arrows
              autoPlaySpeed={3000}
              centerMode={false}
              className=""
              containerClass="container"
              dotListClass=""
              draggable
              focusOnSelect={false}
              infinite={false}
              itemClass=""
              keyBoardControl
              minimumTouchDrag={80}
              pauseOnHover
              renderArrowsWhenDisabled={false}
              renderButtonGroupOutside={false}
              renderDotsOutside={false}
              responsive={{
                desktop: {
                  breakpoint: {
                    max: 3000,
                    min: 1024
                  },
                  items: 3,
                  partialVisibilityGutter: 40
                },
                mobile: {
                  breakpoint: {
                    max: 464,
                    min: 0
                  },
                  items: 1,
                  partialVisibilityGutter: 30
                },
                tablet: {
                  breakpoint: {
                    max: 1024,
                    min: 464
                  },
                  items: 2,
                  partialVisibilityGutter: 30
                }
              }}
              rewind={false}
              rewindWithAnimation={false}
              rtl={false}
              shouldResetAutoplay
              showDots={false}
              sliderClass=""
              slidesToSlide={1}
              swipeable
            >
              {
                state.sentGiftcardItems && state.sentGiftcardItems.map((item, index) => <Giftcard item={item} key={index} giftcardButton={giftcardButton} />)
              }
            </Carousel>
            : giftcardButton === 'sent' && 'No Coupon Found'
          }
          {giftcardButton === 'claimed' &&
            state.claimedGiftcardItems && state.claimedGiftcardItems.length > 0 ?
            <Carousel
              additionalTransfrom={0}
              arrows
              autoPlaySpeed={3000}
              centerMode={false}
              className=""
              containerClass="container"
              dotListClass=""
              draggable
              focusOnSelect={false}
              infinite={false}
              itemClass=""
              keyBoardControl
              minimumTouchDrag={80}
              pauseOnHover
              renderArrowsWhenDisabled={false}
              renderButtonGroupOutside={false}
              renderDotsOutside={false}
              responsive={{
                desktop: {
                  breakpoint: {
                    max: 3000,
                    min: 1024
                  },
                  items: 3,
                  partialVisibilityGutter: 40
                },
                mobile: {
                  breakpoint: {
                    max: 464,
                    min: 0
                  },
                  items: 1,
                  partialVisibilityGutter: 30
                },
                tablet: {
                  breakpoint: {
                    max: 1024,
                    min: 464
                  },
                  items: 2,
                  partialVisibilityGutter: 30
                }
              }}
              rewind={false}
              rewindWithAnimation={false}
              rtl={false}
              shouldResetAutoplay
              showDots={false}
              sliderClass=""
              slidesToSlide={1}
              swipeable
            >

              {state.claimedGiftcardItems && state.claimedGiftcardItems.map((item, index) => <Giftcard item={item} key={index} giftcardButton={giftcardButton} />)}


            </Carousel>
            : giftcardButton === 'claimed' && 'No Coupon Found'
          }
          {giftcardButton === 'unclaim' &&
            state.unclaimGiftcardItems && state.unclaimGiftcardItems.length > 0 ?
            <Carousel
              additionalTransfrom={0}
              arrows
              autoPlaySpeed={3000}
              centerMode={false}
              className=""
              containerClass="container"
              dotListClass=""
              draggable
              focusOnSelect={false}
              infinite={false}
              itemClass=""
              keyBoardControl
              minimumTouchDrag={80}
              pauseOnHover
              renderArrowsWhenDisabled={false}
              renderButtonGroupOutside={false}
              renderDotsOutside={false}
              responsive={{
                desktop: {
                  breakpoint: {
                    max: 3000,
                    min: 1024
                  },
                  items: 3,
                  partialVisibilityGutter: 40
                },
                mobile: {
                  breakpoint: {
                    max: 464,
                    min: 0
                  },
                  items: 1,
                  partialVisibilityGutter: 30
                },
                tablet: {
                  breakpoint: {
                    max: 1024,
                    min: 464
                  },
                  items: 2,
                  partialVisibilityGutter: 30
                }
              }}
              rewind={false}
              rewindWithAnimation={false}
              rtl={false}
              shouldResetAutoplay
              showDots={false}
              sliderClass=""
              slidesToSlide={1}
              swipeable
            >

              {giftcardButton === 'unclaim' && state.unclaimGiftcardItems && state.unclaimGiftcardItems.map((item, index) => <Giftcard item={item} key={index} giftcardButton={giftcardButton} />)}

            </Carousel>
            : giftcardButton === 'unclaim' && 'No Coupon Found'
          }


        </div>

      </div>
    </>
  );
};

export default MyAccountRightSection;
