import React from 'react'
import Footer from '../components/common/Footer'
import Header from '../components/common/Header'
import TopBar from '../components/common/TopBar'
import HeadTop from '../components/refund/Headtop'
import Content from '../components/refund/Content'


const MyOrdersPage = () => {
  return (
    <>
      <TopBar />
      <Header />
      <HeadTop />
      <Content />
      <Footer />
    </>
  )
}

export default MyOrdersPage