import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import Product from '../home/sub_comp/Product'
import InfiniteScroll from 'react-infinite-scroll-component';
import axios from 'axios';
import { updateProducts } from '../../actions';
const { REACT_APP_API_ENDPOINT } = process.env;

const RightSection = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const msg = "No Products Found !!";
  const fetchData = () => {
    let query = state.query, url;
    if (query.type === undefined) {
      url = `${REACT_APP_API_ENDPOINT}/get_products/${page + 1}?branch=${state.currentBranch.branch_id}`;
    }
    else if (query.type === "category") {
      url = `${REACT_APP_API_ENDPOINT}/get_products/${page + 1}?branch=${query.branch.branch_id}&category=${query.category.category_id}`;
    }
    else if (query.type === "food-type") {
      url = `${REACT_APP_API_ENDPOINT}/get_products/${page + 1}?branch=${query.branch.branch_id}&food_type=${query.foodType}`;
    }
    else if (query.type === "search") {
      url = `${REACT_APP_API_ENDPOINT}/get_products/${page + 1}?branch=${query.branch.branch_id}&search=${query.search}`;
    }
    axios.get(url)
      .then(res => {
        if (res.data.status === 'failure') setHasMore(false);
        else {
          if (res.data.data.length < 10) setHasMore(false);
          dispatch(updateProducts(res.data.data));
        }
      })
  }
  return (
    (state.products.length > 0) ? <div id="scrollableDiv" className='md:w-3/4 w-full'>
      <InfiniteScroll
        dataLength={state.products.length}
        next={() => { setPage(i => i + 1); fetchData(); }}
        hasMore={hasMore}
        height={800}
        scrollableTarget="scrollableDiv"
      >
        <div className='grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 xs:grid-cols-1 gap-3 pr-2'>
          {state.products.map((product, index) => <Product key={index} product={product} coupon={{ usedCoupon: false, coupon: null }} />)}
        </div>
      </InfiniteScroll>
    </div> : <div className='text-primary-dark-color text-3xl'>{msg}</div>
  )
}

export default RightSection