import React, { useEffect, useState, Fragment } from "react";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from 'react-redux';
import CartItemsCard from "./CartItemsCard";
import { clearCart, currentCoupon } from "../../actions";
import { Dialog, Transition } from '@headlessui/react'
import { Breadcrumbs } from "@material-tailwind/react";
import EmptyCartDark from '../../media/no-items-dark.png';
import EmptyCartLight from '../../media/no-items-light.png';
import addToCart from '../../media/delete.svg';

const CartPageLeftSection = () => {
  const state = useSelector(state => state);
  let [isClearCartOpen, setClearCartOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const closeClearCartModal = () => {
    setClearCartOpen(false);
  }
  const openClearCartModal = () => {
    setClearCartOpen(true);
  }

  useEffect(() => {
    window.localStorage.setItem('heebee-cart-items', JSON.stringify(state.cartItems));
  }, [state.cartItems]);

  const clear = () => {
    state.cartItems.forEach(item => {
      if (item.coupon !== null) dispatch(currentCoupon(null));
    })
    closeClearCartModal();
    dispatch(clearCart());
  }

  return (
    <>
      <div className="md:w-auto md:basis-2/3 mx-2 px-4 py-2 animate__animated animate__fadeInLeft ">
        {state.cartItems.length > 0 && <div className="my-6 flex flex-row justify-between items-center md:px-4">
          <Breadcrumbs className="bg-primary-light-color border-radius-5">
            <div className="text-dark-color mx-1 mbtb:mx-3 hover:text-grey-400 flex justify-center items-center" onClick={() => navigate('/')}>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
              </svg>
              <div className="hidden mbtb:block">Home</div>
            </div>
            <div className="text-dark-color mx-1 mbtb:mx-3 hover:text-grey-400 flex justify-center items-center" onClick={() => navigate('/menu')}>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M19 20H5a2 2 0 01-2-2V6a2 2 0 012-2h10a2 2 0 012 2v1m2 13a2 2 0 01-2-2V7m2 13a2 2 0 002-2V9a2 2 0 00-2-2h-2m-4-3H9M7 16h6M7 8h6v4H7V8z" />
              </svg>
              <div className="hidden mbtb:block">Menu</div>
            </div>
          </Breadcrumbs>
          <div className="text-[18px] bg-primary-dark-color text-light-color px-3 py-1 cursor-pointer border-radius-5" onClick={() => openClearCartModal()}>Clear All</div>
        </div>}
        <div className="md:px-4 w-full flex flex-wrap justify-center">
          {state.cartItems.length > 0 ?
            state.cartItems.map((item, index) => <CartItemsCard key={index} item={item} />)
            : <div className="text-center text-primary-dark-color ">
              <img src={state.theme === "dark" ? EmptyCartDark : EmptyCartLight} className="m-auto mb-[30px]" width="300px" alt="" />
              <div className="text-3xl">Your shopping cart is empty !</div>
              <div className="text-[12px] mt-[10px] text-dark-color">Once you have added items in your shopping cart, you can check out from here.</div>
              <button className="bg-primary-light-color text-primary-dark-color border-radius-5 px-3 py-1 uppercase font-semibold mt-5" onClick={() => navigate('/menu')}>Order Now</ button>
            </div>
          }
        </div>
      </div>
      <Transition appear show={isClearCartOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10 border-radius-5" onClose={closeClearCartModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25 border-radius-5" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto text-dark-color border-radius-5">
            <div className="flex min-h-full items-center justify-center text-center border-radius-5">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-1/2 md:w-1/3  font-semibold p-4 rounded-sm text-lg transform overflow-hidden bg-primary-light-color  text-black transition-all border-radius-5">
                  <div>Are you sure to clear the cart ?</div>
                  <div><img src={addToCart} width={'175px'} className="m-auto my-4" alt="" /></div>
                  <div className="flex justify-center space-x-4 w-fit m-auto mt-8 text-sm">
                    <button className="bg-primary-color text-grey-900 font-bold leading-3 uppercase border-radius-5 p-2" onClick={clear}>Confirm</button>
                    <button className="bg-grey-300 text-grey-900 font-bold leading-3 uppercase border-radius-5 p-2" onClick={closeClearCartModal}>Cancel</button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default CartPageLeftSection;
