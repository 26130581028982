import React from 'react'
import FacilityDiv from './sub_comp/FacilityDiv';
import { useSelector } from 'react-redux';


const OurFacility = () => {
    const state = useSelector(state => state);

    let modifier = state.theme === "light" ? "" : "D";

    const FacilityData = [
        {
            id: "1",
            title: "Customer Experience",
            description: "",
            image: `coffee-pack/016${modifier}.png`
        },
        {
            id: "2",
            title: "Quality Product",
            description: "",
            image: `coffee-pack/007${modifier}.png`
        },
        {
            id: "3",
            title: "Coffee Awareness",
            description: "",
            image: `coffee-pack/011${modifier}.png`
        },
        {
            id: "4",
            title: "Attention to Detail",
            description: "",
            image: `coffee-pack/012${modifier}.png`
        }
    ];


    return (
        <div className='px-4 py-8 bg-primary-light-color mb-10'>
            <div className='md:container md:mx-auto'><div className='flex justify-between items-center mb-6 heading-one '>
                <h1 className='text-dark-color text-2xl md:text-3xl font-bold font-montserrat capitalize'>Our Facility</h1>
                {/* <Link to='/products' className='text-primary-color text-md font-bold font-montserrats lowercase text-link'>View All</Link> */}
            </div>
                <div className='grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-2 xs:grid-cols-1 gap-6 px-6'>
                    {FacilityData && FacilityData.map((item) => {
                        return (
                            <FacilityDiv key={item.id} item={item} />
                        )
                    })}
                </div></div>

        </div>
    )
}

export default OurFacility