import React from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from 'react-redux';
import ProfileImg from '../assets/images/profile.png'
import { logout } from "../actions";

const MyAccountButton = ({ orderId }) => {
  const params = window.location.pathname;
  const state = useSelector(state => state);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const customerLogout = () => {
    dispatch(logout());
    navigate('/login');
  }
  return (
    <div className="flex flex-wrap gap-3 w-full justify-center md:justify-start cursor-pointer">
      <button
        className={`text-center font-roboto  font-bold text-md py-0 px-2 rounded-2xl disabled:cursor-not-allowed  ${params === "/my-profile" || params === `/my-profile`
          ? "text-light-color bg-primary-dark-color"
          : "text-primary-dark-color "
          }`}
        onClick={() => { navigate("/my-profile"); }}
        disabled={
          params === "/my-profile"
            ? true
            : false
        }
      >
        My Profile
      </button>
      <button
        className={`text-center font-roboto  font-bold text-md py-0 px-2 rounded-2xl disabled:cursor-not-allowed  ${params === "/my-giftcard" || params === `/my-giftcard`
          ? "text-light-color bg-primary-dark-color"
          : "text-primary-dark-color "
          }`}
        onClick={() => { navigate("/my-giftcard"); }}
        disabled={
          params === "/my-giftcard"
            ? true
            : false
        }
      >
        My Giftcard
      </button>
      <button
        className={`text-center font-roboto  font-bold text-md py-0 px-2 rounded-2xl disabled:cursor-not-allowed  ${params === "/my-orders" || params === `/my-orders/${orderId}`
          ? "text-light-color bg-primary-dark-color"
          : "text-primary-dark-color "
          }`}
        onClick={() => {
          navigate('../my-orders');
        }}
        disabled={
          params === "/my-orders"
            ? true
            : false
        }
      >
        My Orders
      </button>
      <button
        className={`text-center font-roboto  font-bold text-md py-0 px-2 rounded-2xl disabled:cursor-not-allowed  ${params === "/edit-profile"
          ? "text-light-color bg-primary-dark-color"
          : "text-primary-dark-color "
          }`}
        onClick={() => { navigate("/edit-profile"); }}
        disabled={params === "/edit-profile" ? true : false}
      >
        Edit Profile
      </button>
      <button
        className={`text-center font-roboto  font-bold text-md py-0 px-2 rounded-2xl disabled:cursor-not-allowed  ${params === "/change-password"
          ? "text-light-color bg-primary-dark-color"
          : "text-primary-dark-color "
          }`}
        onClick={() => { navigate("/change-password"); }}
        disabled={params === "/change-password" ? true : false}
      >
        Change Password
      </button>
      <button
        className={`text-center font-roboto  font-bold text-md py-0 px-2 rounded-2xl bg-red-400 text-white`}
        onClick={() => { customerLogout(); }}
      >
        Logout
      </button>
    </div>
  );
};

export default MyAccountButton;
