import React from 'react'
//import DztImageGalleryComponent from "reactjs-image-gallery";
import InstagramFeed from 'react-ig-feed'
import 'react-ig-feed/dist/index.css'
const { REACT_APP_INSTAGRAM_TOKEN } = process.env;
/* var data = [
  {
    url:
      "https://b.zmtcdn.com/data/reviews_photos/684/7ce1fbfe52788e247efcd943a2c93684_1555864810.jpg?fit=around%7C200%3A200&crop=200%3A200%3B%2A%2C%2A",
    title: "Kayak",
    thumbUrl:
      "https://b.zmtcdn.com/data/reviews_photos/684/7ce1fbfe52788e247efcd943a2c93684_1555864810.jpg?fit=around%7C200%3A200&crop=200%3A200%3B%2A%2C%2A"
  },
  {
    url:
      "https://b.zmtcdn.com/data/reviews_photos/661/0d6d4c5b1c0ae01acfa980bb01746661_1539840956.jpg?fit=around%7C200%3A200&crop=200%3A200%3B%2A%2C%2A",
    title: "Cyclist competition",
    thumbUrl:
      "https://b.zmtcdn.com/data/reviews_photos/661/0d6d4c5b1c0ae01acfa980bb01746661_1539840956.jpg?fit=around%7C200%3A200&crop=200%3A200%3B%2A%2C%2A"
  },
  {
    url:
      "https://b.zmtcdn.com/data/reviews_photos/6da/e9cdcb6bb8fa96585809b0453edaa6da_1554631333.jpg?fit=around%7C200%3A200&crop=200%3A200%3B%2A%2C%2A",
    title: "Surfer in action",
    thumbUrl:
      "https://b.zmtcdn.com/data/reviews_photos/6da/e9cdcb6bb8fa96585809b0453edaa6da_1554631333.jpg?fit=around%7C200%3A200&crop=200%3A200%3B%2A%2C%2A"
  },
  {
    url:
      "https://b.zmtcdn.com/data/reviews_photos/894/2699b99d4e3e9a68e046e44ba037c894_1554100762.jpg?fit=around%7C200%3A200&crop=200%3A200%3B%2A%2C%2A",
    title: "Drops",
    thumbUrl:
      "https://b.zmtcdn.com/data/reviews_photos/894/2699b99d4e3e9a68e046e44ba037c894_1554100762.jpg?fit=around%7C200%3A200&crop=200%3A200%3B%2A%2C%2A"
  },

  {
    url:
      "https://b.zmtcdn.com/data/reviews_photos/e9c/9722eaa6c3c50c0c5c6d10a73d9d8e9c_1538409180.jpg?fit=around%7C200%3A200&crop=200%3A200%3B%2A%2C%2A",
    title: "Bird",
    thumbUrl:
      "https://b.zmtcdn.com/data/reviews_photos/e9c/9722eaa6c3c50c0c5c6d10a73d9d8e9c_1538409180.jpg?fit=around%7C200%3A200&crop=200%3A200%3B%2A%2C%2A"
  },
  {
    url:
      "https://b.zmtcdn.com/data/reviews_photos/684/7ce1fbfe52788e247efcd943a2c93684_1555864810.jpg?fit=around%7C200%3A200&crop=200%3A200%3B%2A%2C%2A",
    title: "Kayak",
    thumbUrl:
      "https://b.zmtcdn.com/data/reviews_photos/684/7ce1fbfe52788e247efcd943a2c93684_1555864810.jpg?fit=around%7C200%3A200&crop=200%3A200%3B%2A%2C%2A"
  },
  {
    url:
      "https://b.zmtcdn.com/data/reviews_photos/661/0d6d4c5b1c0ae01acfa980bb01746661_1539840956.jpg?fit=around%7C200%3A200&crop=200%3A200%3B%2A%2C%2A",
    title: "Cyclist competition",
    thumbUrl:
      "https://b.zmtcdn.com/data/reviews_photos/661/0d6d4c5b1c0ae01acfa980bb01746661_1539840956.jpg?fit=around%7C200%3A200&crop=200%3A200%3B%2A%2C%2A"
  },
  {
    url:
      "https://b.zmtcdn.com/data/reviews_photos/6da/e9cdcb6bb8fa96585809b0453edaa6da_1554631333.jpg?fit=around%7C200%3A200&crop=200%3A200%3B%2A%2C%2A",
    title: "Surfer in action",
    thumbUrl:
      "https://b.zmtcdn.com/data/reviews_photos/6da/e9cdcb6bb8fa96585809b0453edaa6da_1554631333.jpg?fit=around%7C200%3A200&crop=200%3A200%3B%2A%2C%2A"
  },
  {
    url:
      "https://b.zmtcdn.com/data/reviews_photos/894/2699b99d4e3e9a68e046e44ba037c894_1554100762.jpg?fit=around%7C200%3A200&crop=200%3A200%3B%2A%2C%2A",
    title: "Drops",
    thumbUrl:
      "https://b.zmtcdn.com/data/reviews_photos/894/2699b99d4e3e9a68e046e44ba037c894_1554100762.jpg?fit=around%7C200%3A200&crop=200%3A200%3B%2A%2C%2A"
  },

  {
    url:
      "https://b.zmtcdn.com/data/reviews_photos/e9c/9722eaa6c3c50c0c5c6d10a73d9d8e9c_1538409180.jpg?fit=around%7C200%3A200&crop=200%3A200%3B%2A%2C%2A",
    title: "Bird",
    thumbUrl:
      "https://b.zmtcdn.com/data/reviews_photos/e9c/9722eaa6c3c50c0c5c6d10a73d9d8e9c_1538409180.jpg?fit=around%7C200%3A200&crop=200%3A200%3B%2A%2C%2A"
  },
  {
    url:
      "https://b.zmtcdn.com/data/reviews_photos/894/2699b99d4e3e9a68e046e44ba037c894_1554100762.jpg?fit=around%7C200%3A200&crop=200%3A200%3B%2A%2C%2A",
    title: "Drops",
    thumbUrl:
      "https://b.zmtcdn.com/data/reviews_photos/894/2699b99d4e3e9a68e046e44ba037c894_1554100762.jpg?fit=around%7C200%3A200&crop=200%3A200%3B%2A%2C%2A"
  },

  {
    url:
      "https://b.zmtcdn.com/data/reviews_photos/e9c/9722eaa6c3c50c0c5c6d10a73d9d8e9c_1538409180.jpg?fit=around%7C200%3A200&crop=200%3A200%3B%2A%2C%2A",
    title: "Bird",
    thumbUrl:
      "https://b.zmtcdn.com/data/reviews_photos/e9c/9722eaa6c3c50c0c5c6d10a73d9d8e9c_1538409180.jpg?fit=around%7C200%3A200&crop=200%3A200%3B%2A%2C%2A"
  }
]; */

const OurGallery = () => {
  const token = REACT_APP_INSTAGRAM_TOKEN;
  return (
    <div className='px-4 mb-10'>
      <div className='md:container md:mx-auto'>
        <div className='flex justify-between items-center mb-6 heading-one'>
          <h1 className='text-dark-color text-2xl md:text-3xl font-bold font-montserrat capitalize'>Our Gallery</h1>
          {/* <Link to='/products' className='text-primary-color text-md font-bold font-montserrats lowercase text-link'>View All</Link> */}
        </div>
        {/* <div className='grid lg:grid-cols-6 md:grid-cols-3 sm:grid-cols-2 xs:grid-cols-1 gap-6'>
              <div>
                  <img className='object-cover clip-path-sm' src={data[0].image} alt="no found " />
              </div>
              <div>
                  <img className='object-cover clip-path-sm' src={data[1].image} alt="no found " />
              </div>
              <div>
                  <img className='object-cover clip-path-sm' src={data[2].image} alt="no found " />
              </div>
              <div>
                  <img className='object-cover clip-path-sm' src={data[0].image} alt="no found " />
              </div>
              <div>
                  <img className='object-cover clip-path-sm' src={data[0].image} alt="no found " />
              </div>
              <div>
                  <img className='object-cover clip-path-sm' src={data[0].image} alt="no found " />
              </div>
              <div>
                  <img className='object-cover clip-path-sm' src={data[0].image} alt="no found " />
              </div>
          </div> */}
        {/* <DztImageGalleryComponent images={data} hideDownload={true} /> */}
        {/* <InstagramFeed token={REACT_APP_INSTAGRAM_TOKEN} counter="12" /> */}
      </div>

    </div>
  )
}

export default OurGallery