import React from 'react'
import Logo from '../../assets/images/logo.png'
import {
    XIcon
} from "@heroicons/react/outline";
import { NavLink } from "react-router-dom";

const NavSidebar = ({ isOpenMainSidebar, setIsOpenMainSidebar }) => {
    const ToggleSidebar = () => {
        isOpenMainSidebar === true
            ? setIsOpenMainSidebar(false)
            : setIsOpenMainSidebar(true);
    };
    return (
        <>
            <div
                className={`sidebar p-2 ${isOpenMainSidebar === true ? "active" : ""}`}
            >
                <div className="sidebar-body p-1 flex flex-col justify-center items-center h-[90vh]">
                    <ul className='flex flex-col justify-center items-center gap-3 uppercase font-roboto mb-6'>
                        <li>
                            <NavLink to="/" className={({ isActive }) => isActive ? "navlink navlink-active" : "navlink"}>Home</NavLink>
                        </li>
                        <li>
                            <NavLink to="/menu" className={({ isActive }) => isActive ? "navlink navlink-active" : "navlink"}>Menu</NavLink>
                        </li>
                        <li>
                            <NavLink to="/about" className={({ isActive }) => isActive ? "navlink navlink-active" : "navlink"}>About</NavLink>
                        </li>
                        <li>
                            <NavLink to="/contact" className={({ isActive }) => isActive ? "navlink navlink-active" : "navlink"}>Contact</NavLink>
                        </li>
                        <li>
                            <NavLink to="/career" className={({ isActive }) => isActive ? "navlink navlink-active" : "navlink"}>Career</NavLink>
                        </li>
                    </ul>

                    <button className='p-2 cursor-pointer' style={{ color: "var(--primaryDarkColor)", background: "var(--primaryLightColor)", clipPath: "polygon(0 0, 80% 0, 100% 20%, 100% 100%, 20% 100%, 0 80%)" }} onClick={ToggleSidebar}>
                        <XIcon className='w-6 h-6' />
                    </button>
                </div>

            </div>
            <div
                className={`sidebar-overlay ${isOpenMainSidebar === true ? "active" : ""
                    } w-100%`}
                onClick={ToggleSidebar}
            ></div>
        </>
    )
}

export default NavSidebar