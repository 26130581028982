import React from 'react'
import Footer from '../components/common/Footer'
import Header from '../components/common/Header'
import TopBar from '../components/common/TopBar'
import HeadTop from '../components/offers/Headtop'
import OffersSection from '../components/offers/OffersSection'

export default function Offers() {
    return (
        <>
            <TopBar />
            <Header />
            <HeadTop />
            <div className='md:container mx-auto px-6 mt-[30px]'>
                <div className='flex flex-wrap md:flex-nowrap justify-start gap-6 h-[100%]'>
                    <OffersSection />
                </div>
            </div>
            <Footer />
        </>
    )
}
