import React, { useState, useEffect, useRef } from "react";
//import loginimage from ".././assets/images/Loginimage.png";
import { Link } from "react-router-dom";
import { ReactComponent as LoginIcon } from "../assets/images/heebeeIcon.svg";
import { Input } from "@material-tailwind/react";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { setLogin } from "../actions";
import { useAlert } from "react-alert";
const { REACT_APP_API_ENDPOINT } = process.env;


const Login = () => {
  const state = useSelector(state => state);
  const alert = useAlert();
  const dispatch = useDispatch();
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [loginMode, setLoginMode] = useState("password");
  const [OTPSend, setOTPSend] = useState(false);
  const mobRef = useRef(null);
  const navigate = useNavigate();
  useEffect(() => {
    if (state.token !== null) navigate('/my-profile');
  }, [state.token, navigate]);

  const sendOtp = () => {
    if (mobile.length !== 10) alert.error("Please enter correct mobile number !");
    else {
      mobRef.current.children[0].disabled = true;
      mobRef.current.children[0].style.color = "black";
      axios.post(`${REACT_APP_API_ENDPOINT}/send_otp`, {
        "mobile_no": mobile
      })
        .then(res => {
          if (res.data.status === "failure") alert.error(res.data.msg);
          else {
            setOTPSend(true);
            alert.success("OTP SENT !")
          }
        });
    }
  }

  const resendOTP = () => {
    if (mobile.length !== 10) alert.error("Please enter correct mobile number !");
    else {
      axios.post(`${REACT_APP_API_ENDPOINT}/send_otp`, {
        "mobile_no": mobile
      })
        .then(res => {
          if (res.data.status === "failure") alert.error(res.data.msg);
          else {
            setOTPSend(true);
            alert.success("OTP SENT !")
          }
        });
    }
  }

  const login = () => {
    if (loginMode === "password") {
      if (mobile.length !== 10 || password.length === 0) alert.error("Please enter the deatils correctly !")
      else {
        axios.post(`${REACT_APP_API_ENDPOINT}/customer_login`, {
          "mobile_no": mobile,
          "password": password
        })
          .then(res => {
            if (res.data.status === "failure") {
              alert.error(res.data.msg);
            }
            else {
              dispatch(setLogin(res.data.token));
            }
          });
      }
    }
    else {
      if (mobile.length !== 10 || password.length !== 4) alert.error("Please enter the deatils correctly !")
      else {
        axios.post(`${REACT_APP_API_ENDPOINT}/customer_login`, {
          "mobile_no": mobile,
          "OTP": password
        })
          .then(res => {
            if (res.data.status === "failure") {
              alert.error(res.data.msg);
            }
            else {
              dispatch(setLogin(res.data.token));
            }
          });
      }
    }
  }
  return (
    <div className="py-6 w-screen h-screen flex flex-row justify-center items-center bg-footer">
      <div className="flex bg-product rounded-lg shadow-lg overflow-hidden mx-auto w-[24rem] lg:w-[50rem]">
        <div className="hidden lg:flex lg:w-1/2 bg-cover bg-primary-color justify-center items-center">
          <LoginIcon className=" fill-color mt-1 ml-1" />
        </div>
        <div className="w-full p-8 lg:w-1/2 flex flex-col justify-center relative">
          <div className="absolute top-3 left-3 cursor-pointer">
            <Link to="/">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
              </svg>
            </Link>
          </div>
          <h1 className="font-bold text-primary-color text-center text-4xl mt-2">
            Hee<span className="text-primary-dark-color ">bee</span>
          </h1>
          <p className="text-xl text-primary-color text-center">
            Welcome back!
          </p>
          <div className="my-4">
            <Input
              size="lg"
              label="Mobile"
              type='number'
              value={mobile}
              ref={mobRef}
              className="text-dark-color"
              onChange={(e) => {
                if (e.target.value.length > 10) return false;
                setMobile(e.target.value)
              }}
            />
          </div>
          {(loginMode === "OTP" && OTPSend === false) && <div className="mb-3">
            <button className="bg-primary-dark-color text-light-color font-bold py-2 px-4 w-full rounded hover:opacity-90" onClick={() => sendOtp()} >
              Send OTP
            </button>
          </div>}

          {((loginMode === "OTP" && OTPSend === true) || loginMode === "password") && <div className='mb-2'>
            <Input
              size="lg"
              className="text-dark-color"
              label={loginMode === "password" ? "Password" : "OTP"}
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              type="password"
              maxLength={loginMode === "password" ? "524288" : "6"}
            />
          </div>}
          <div className="flex justify-between">
            <div
              className="text-left text-primary-dark-color text-xs font-bold  mt-1 hover:underline cursor-pointer"
              onClick={() => {
                setPassword("");
                setOTPSend(false);
                mobRef.current.children[0].disabled = false;
                mobRef.current.children[0].style.color = "";
                setLoginMode(loginMode => loginMode === "password" ? "OTP" : "password");
              }}
            >
              Login with {loginMode === "password" ? "OTP" : "Password"}
            </div>
            {OTPSend === true && <div
              className="text-right text-primary-dark-color text-xs font-bold  mt-1 hover:underline cursor-pointer"
              onClick={() => {
                setPassword("");
                resendOTP();
              }}
            >
              Resend OTP
            </div>}
            {loginMode === "password" && <div
              className="text-right text-primary-dark-color text-xs font-bold  mt-1 hover:underline cursor-pointer"
              onClick={() => {
                navigate("/forget-password");
              }}
            >
              Forget Password?
            </div>}
          </div>
          {((loginMode === "OTP" && OTPSend === true) || loginMode === "password") && <div className="mt-4">
            <button className="bg-primary-dark-color text-light-color font-bold py-2 px-4 w-full rounded hover:opacity-90" onClick={() => login()} >
              Sign In
            </button>
          </div>}
          <div className="h-0.5 bg-grey-400 rounded-sm mt-4 "></div>
          <div className="px-1">
            <button
              className="bg-white outline outline-offset-2 outline-2 outline-[#001309] text-[#002a1b] font-bold mt-5 py-[6px] px-4 w-full rounded hover:opacity-90"
              onClick={() => {
                navigate("/signup");
              }}
            >
              Don't have an Account? Register Now!
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
