import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "../pages/HomePage";
import CartPage from "../pages/CartPage";
import CheckoutPage from "../pages/CheckoutPage";
import MyAccountPage from "../pages/MyAccountPage";
import ChangePasswordPage from "../pages/ChangePasswordPage";
import MyProfilePage from "../pages/MyProfilePage";
import EditProfilePage from "../pages/EditProfilePage";
import MyOrdersPage from "../pages/MyOrdersPage";
import SuccessPage from "../pages/SuccessPage";
import Login from "../pages/Login";
import Signup from "../pages/Signup";
import ForgetPassword from "../pages/ForgetPassword";
import Error from "../pages/Error";
import Menu from "../pages/Menu";
import About from "../pages/About";
import Contact from "../pages/Contact";
import Terms from "../pages/Terms";
import Privacy from "../pages/Privacy";
import Refund from "../pages/Refund";
import Loader from "../pages/Loader";
import SingleOrderPage from "../pages/SingleOrderPage";
import NewPassword from "../pages/NewPassword";
import PaymentFail from "../pages/PaymentFail";
import PaymentSuccessful from "../pages/PaymentSuccesasful";
import OrderPlaced from "../pages/OrderPlaced";
import OrderFailed from "../pages/OrderFailed";
import Career from "../pages/CareerPage";
import MyGiftcardPage from "../pages/MyGiftcardPage";
import Offers from "../pages/Offers";
import SingleOffer from "../pages/SingleOffer";

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/forget-password" element={<ForgetPassword />} />
        <Route path="/new-password" element={<NewPassword />} />
        <Route path="/menu" element={<Menu />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/career" element={<Career />} />
        <Route path="/cart" element={<CartPage />} />
        <Route path="/checkout" element={<CheckoutPage />} />
        <Route path="/my-account" element={<MyAccountPage />} />
        <Route path="/my-profile" element={<MyProfilePage />} />
        <Route path="/my-giftcard" element={<MyGiftcardPage />} />
        <Route path="/edit-profile" element={<EditProfilePage />} />
        <Route path="/change-password" element={<ChangePasswordPage />} />
        <Route path="/my-orders" element={<MyOrdersPage />} />
        <Route path="/my-orders/:orderId" element={<SingleOrderPage />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/refund" element={<Refund />} />
        <Route path="/success" element={<SuccessPage />} />
        <Route path="/loader" element={<Loader />} />
        <Route path="/payment-failed" element={<PaymentFail />} />
        <Route path="/payment-successful" element={<PaymentSuccessful />} />
        <Route path="/order-placed" element={<OrderPlaced />} />
        <Route path="/order-failed" element={<OrderFailed />} />
        <Route path="/offers" element={<Offers />} />
        <Route path="/offer-details" element={<SingleOffer />} />
        <Route path="*" element={<Error />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
